import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import authServices from "../../services/auth.services";
import { Formik, Form, Field } from "formik";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import LoadingData from "../../components/common/LoadingData";
import UserAvatar from "../../assets/avatar.png";
import { PencilIcon } from "@heroicons/react/24/outline";
import PreviewImage from "../../components/ImagePreview";
import FailedAlert from "../../components/common/FailedAlert";
import SuccessAlert from "../../components/common/SuccessAlert";
export default function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();
  // let navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    authServices
      .getInfo()
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => { });
  }, []);

  const update = (inputs) => {
    authServices
      .updateInfo({ ...inputs, id: data?.user?.id })
      .then((response) => {
        console.log(response);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        setError(true);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    midName: Yup.string().required(t("Required")),
    phoneNumber: Yup.string().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
  });

  const handleCloseError = () => {
    setError(false);
  };

  return (
    <>
      <FailedAlert
        show={error}
        message={t("EmailOrPhoneAlreadyTakenOrSomethingWentWroing")}
        close={handleCloseError}
      />
      <SuccessAlert show={success} message={t("DataUpdated")} />
      <Layout>
        {isLoading && <LoadingData />}
        {!isLoading && (
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <Formik
                initialValues={{
                  firstName: data?.user?.firstName ?? "",
                  midName: data?.user?.midName ?? "",
                  email: data?.user?.email ?? "",
                  phoneNumber: data?.user?.phoneNumber ?? "",
                  avatar: null,
                }}
                // validateOnBlur={false}
                // validateOnChange={false}
                validationSchema={ValidationSchema}
                onSubmit={(values) => update(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="pb-4 flex ltr:float-right rtl:float-left p-6">
                      <div className="mt-2 flex sm:mt-0 sm:ml-4">
                        <button
                          type="submit"
                          className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {t("SaveChanges")}
                        </button>
                      </div>
                    </div>

                    <div className="border border-gray-200 rounded-lg shadow-lg p-10 bg-white">
                      <div className="flex justify-center min-w-full">
                        {/* avatar */}
                        <div className="relative">
                          <button
                            type="button"
                            className="absolute mt-20 rounded-full bg-amber-400 p-1 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                          >
                            <label htmlFor="avatar">
                              <PencilIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </label>
                          </button>
                          {values.avatar ? (
                            <PreviewImage file={values.avatar} />
                          ) : (
                            <img
                              className="h-32 w-32 rounded-full"
                              src={apiUrl + "user-image/" + data?.user?.avatar}
                              alt=""
                            />
                          )}
                          {data?.user?.avatar === null ?? (
                            <img
                              className="h-32 w-32 rounded-full"
                              src={UserAvatar}
                              alt=""
                            />
                          )}
                          <input
                            id="avatar"
                            name="avatar"
                            type="file"
                            className="sr-only"
                            onChange={(event) => {
                              setTouched({
                                ...touched,
                                thumbnail: true,
                              });
                              setFieldValue("avatar", event.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 m-10">
                        <div className="sm:col-span-12">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("FirstName")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              autoComplete="firstName"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.firstName && errors.firstName
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.firstName &&
                              touched.firstName &&
                              errors.firstName &&
                              errors.firstName && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"password-error"}
                                >
                                  {errors.firstName}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="sm:col-span-12">
                          <label
                            htmlFor="midName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("LastName")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="midName"
                              id="midName"
                              autoComplete="midName"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.midName && errors.midName
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.midName &&
                              touched.midName &&
                              errors.midName &&
                              errors.midName && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"password-error"}
                                >
                                  {errors.midName}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="sm:col-span-12">
                          <label
                            htmlFor="phoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("PhoneNumber")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              autoComplete="phoneNumber"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.phoneNumber && errors.phoneNumber
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber &&
                              errors.phoneNumber && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"phoneNUmber-error"}
                                >
                                  {errors.phoneNumber}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="sm:col-span-12">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("Email")}
                          </label>
                          <div className="mt-1">
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.email && errors.email
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.email &&
                              touched.email &&
                              errors.email &&
                              errors.email && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"email-error"}
                                >
                                  {errors.email}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
