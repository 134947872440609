import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getGiftCard = (id) => {
    return axios.get(`${base.apiUrl}giftCards/${id}`, {
        headers: headers.authHeader(),
    });
};

const getGiftCards = (page, limit) => {
    return axios.get(`${base.apiUrl}giftCards/${page}/${limit}?instituteId=${localStorage.getItem("instituteId")}`, {
        headers: headers.authHeader(),
    });
};

const createGiftCards = (body) => {
    let formData = new FormData();
    for (let index = 0; index < body.cards.length; index++) {
        formData.append(`image`, body.cards[index].image);

        formData.append(`cards[${index}][instituteId]`, body.cards[index].instituteId);
        formData.append(`cards[${index}][title]`, body.cards[index].title);
        formData.append(`cards[${index}][type]`, body.cards[index].type);
        formData.append(`cards[${index}][quantity]`, body.cards[index].quantity);
        formData.append(`cards[${index}][points]`, body.cards[index].points);
        formData.append(`cards[${index}][currency]`, body.cards[index].currency);
        formData.append(`cards[${index}][expireAt]`, body.cards[index].expireAt);
        formData.append(`cards[${index}][exchangeInstruction]`, body.cards[index].exchangeInstruction);

        if (body.cards[index].codes)
            for (let codeIndex = 0; codeIndex < body.cards[index].codes.length; codeIndex++) {
                formData.append(`cards[${index}][codes][${codeIndex}][code]`, body.cards[index].codes[codeIndex].code);
            }
    }

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${base.apiUrl}giftCards`,
        headers: headers.authHeaderWithMultipart(),
        data: formData
    };

    return axios.request(config);
};

const updateGiftCards = (id, body) => {
    let formData = new FormData();

    formData.append(`instituteId`, body.instituteId);
    formData.append(`title`, body.title);
    formData.append(`type`, body.type);
    formData.append(`quantity`, body.quantity);
    formData.append(`points`, body.points);
    formData.append(`currency`, body.currency);
    formData.append(`expireAt`, body.expireAt);
    formData.append(`exchangeInstruction`, body.exchangeInstruction);

    if (body.image instanceof File)
        formData.append('image', body.image);

    for (let codeIndex = 0; codeIndex < body.codes.length; codeIndex++) {
        formData.append(`codes[${codeIndex}][code]`, body.codes[codeIndex].code);
    }

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${base.apiUrl}giftCards/${id}`,
        headers: headers.authHeaderWithMultipart(),
        data: formData
    };

    return axios.request(config);
};

const deleteGiftCard = (id, body) => {
    return axios.delete(`${base.apiUrl}giftCards/${id}`, {
        headers: headers.authHeader(),
    });
};



export {
    getGiftCard,
    getGiftCards,
    createGiftCards,
    updateGiftCards,
    deleteGiftCard,
};
