import React from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../assets/logo.svg";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authServices from "../../services/auth.services";
import { clearError } from "../../slices/errorSlice";
import { setError } from "../../slices/errorSlice";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../components/root/AuthLayout";

export default function ResetPassword() {
  let { email } = useParams();
  let { token } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { error } = useSelector((state) => state.error);

  const handleSubmit = (code, newPassword) => {
    authServices
      .resetPassword(token, {
        email: email,
        code: code,
        newPassword: newPassword,
      })
      .then((response) => {
        console.log("response: ", response);
        if (response.status === 200) {
          navigate("/");
        } else {
          dispatch(setError({ message: response.data.message }));
        }
      })
      .catch((error) => {
        dispatch(setError({ message: error.response.data.message }));
      });
  };

  const ValidationSchema = Yup.object().shape({
    code: Yup.string().required(t("Required")),
    newPassword: Yup.string().min(3, t("PasswordMin3")).required(t("Required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("PasswordMustMatch"))
      .required(t("Required")),
  });

  const handleCloseMessage = () => {
    dispatch(clearError());
  };

  return (
    <div className="h-screen bg-gradient-to-br from-gray-shade to-secondary">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={logo} alt="" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-100 drop-shadow-md">
            {t("UpdatePassword")}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white/25 py-8 px-4 sm:px-10 shadow-lg rounded-lg">
            <Formik
              initialValues={{ code: "", newPassword: "", confirmPassword: "" }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={ValidationSchema}
              onSubmit={(values) =>
                handleSubmit(values.code, values.newPassword)
              }
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="space-y-6">
                  <div className="mt-1">
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("OTP")}
                    </label>
                    <Field
                      name="code"
                      id="code"
                      type="text"
                      autoComplete="code"
                      className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                        touched.code && errors.code
                          ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                          : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                    />
                    {touched.code && (
                      <label className="text-rose-600 text-sm">
                        {errors.code}
                      </label>
                    )}
                  </div>

                  <div className="mt-1">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("NewPassword")}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockClosedIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <Field
                        name="newPassword"
                        id="newPassword"
                        type="password"
                        autoComplete="newPassword"
                        className={`block w-full rounded-md border border-transparent pl-10 shadow-sm sm:text-sm ${
                          touched.newPassword && errors.newPassword
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                    {touched.newPassword && (
                      <label className="text-rose-600 text-sm">
                        {errors.newPassword}
                      </label>
                    )}
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("ConfirmPassword")}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockClosedIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <Field
                        name="confirmPassword"
                        id="confirmPassword"
                        type="password"
                        autoComplete="confirmPassword"
                        className={`block w-full rounded-md border border-transparent pl-10 shadow-sm sm:text-sm ${
                          touched.confirmPassword && errors.confirmPassword
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                    {touched.confirmPassword && (
                      <label className="text-rose-600 text-sm">
                        {errors.confirmPassword}
                      </label>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    >
                      {"ResetPassword"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {error && (
              <div className="mt-6">
                <ErrorMessage
                  message={error.message}
                  close={handleCloseMessage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
