import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import authServices from "../../services/auth.services";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";
import rounds from '../../assets/rounds.jpg';
import points from '../../assets/points.jpg';
import { CheckCircleIcon } from '@heroicons/react/24/solid'; // Import Heroicons

export default function AppMode() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(localStorage.getItem("mode"));
    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);

    const updateMode = async (mode) => {
        await authServices
            .updateInstitute({ mode: mode })
            .then((response) => {
                setSendSuccess(true);
                setHovered(mode);
                localStorage.setItem("mode", mode);
                setTimeout(() => {
                    setSendSuccess(false);
                }, 5000);
                console.log("🚀 ~ awaitpointsServices.createPoint ~ response:", response);
            })
            .catch((error) => {
                setSendFailed(true);
                setTimeout(() => {
                    setSendFailed(false);
                }, 5000);
                console.log("🚀 ~ .then ~ error:", error);
            });
    };

    return (
        <div>
            <SuccessAlert show={sendSuccess} message={t("SavedSuccessfully")} />
            <FailedAlert show={sendFailed} message={t("SomethingWentWroing")} />
            <div className="flex justify-center p-8">
                <div
                    className={`relative w-1/2 p-4 cursor-pointer rounded-lg mx-10 hover:bg-yellow-400 ${hovered === 'ROUNDS' ? 'bg-yellow-400' : 'bg-gray-400'}`}
                    onClick={() => updateMode('ROUNDS')}
                >
                    {hovered === 'ROUNDS' && (
                        <CheckCircleIcon className="absolute top-2 right-2 h-8 w-8 text-white" />
                    )}
                    <div className="flex justify-center">
                        <img src={rounds} alt={'ROUNDS'} className="h-40 rounded-lg" />
                    </div>
                    <div className="text-center mt-4">
                        <p className="text-xl font-semibold">{t('roundMode')}</p>
                    </div>
                </div>
                <div
                    className={`relative w-1/2 p-4 cursor-pointer rounded-lg mx-10 ${hovered === 'POINTS' ? 'bg-yellow-400' : 'bg-gray-400'} hover:bg-yellow-400`}
                    onClick={() => updateMode('POINTS')}
                >
                    {hovered === 'POINTS' && (
                        <CheckCircleIcon className="absolute top-2 right-2 h-8 w-8 text-white" />
                    )}
                    <div className="flex justify-center">
                        <img src={points} alt={'POINTS'} className="h-40 rounded-lg" />
                    </div>
                    <div className="text-center mt-4">
                        <p className="text-xl font-semibold">{t('pointsMode')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
