import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  PlusIcon,
  ArrowDownTrayIcon,
  PencilIcon,
  DocumentIcon,
  TrashIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import Select from "react-tailwindcss-select";
import * as classRoomServices from "../../services/classroom.services";
import * as studentServices from "../../services/student.services";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import LoadingOver from "../../components/common/LoadingOver";

export default function StudentsList() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [classRooms, setClassRooms] = useState([]);
  const [selectedClassRoom, setSelectedClassRoom] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletedItem, setDeletedItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  let navigate = useNavigate();
  let { gradeId } = useParams();

  const pages = [
    { name: t("Grades"), href: `/students/index`, current: false },
    { name: t("Students"), href: "#", current: true },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    getClassRooms();
    // eslint-disable-next-line
  }, []);

  const getClassRooms = async () => {
    try {
      const response = await classRoomServices.getClassRooms(gradeId, 1, 1000);
      if (response.status === 200) {
        if (response.data.classRooms.rows.length > 0) {
          setClassRooms(
            response.data.classRooms.rows.map((classRoom) => ({
              label: classRoom.arabicName,
              value: classRoom.id,
            }))
          );

          const lastSelectedClassRoom = JSON.parse(
            localStorage.getItem("lastSelectedClassRoom")
          );

          console.log(lastSelectedClassRoom);
          console.log(localStorage.getItem("currentGrade"));
          console.log(gradeId);

          if (
            lastSelectedClassRoom !== null &&
            localStorage.getItem("currentGrade") === gradeId
          ) {
            setSelectedClassRoom(lastSelectedClassRoom);
            await getItems(1, lastSelectedClassRoom.value);
          } else {
            localStorage.setItem("currentGrade", gradeId);
            localStorage.setItem(
              "lastSelectedClassRoom",
              JSON.stringify({
                label: response.data.classRooms.rows[0].arabicName,
                value: response.data.classRooms.rows[0].id,
              })
            );
            setSelectedClassRoom({
              label: response.data.classRooms.rows[0].arabicName,
              value: response.data.classRooms.rows[0].id,
            });
            getItems(1, response.data.classRooms.rows[0].id);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getItems = async (page, classRoomId) => {
    setIsLoading(true);
    try {
      const response = await studentServices.getStudents(
        classRoomId,
        page,
        limit
      );
      if (response.status === 200) {
        setRows(response.data.students.rows);
        setCount(response.data.students.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await studentServices.getStudents(
        selectedClassRoom.value,
        page,
        limit
      );
      if (response.status === 200) {
        setRows(response.data.students.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handleClasses = (value) => {
    setSelectedClassRoom(value);
    getItems(1, value.value);
    localStorage.setItem("lastSelectedClassRoom", JSON.stringify(value));
  };

  const alertDelete = async (id) => {
    setDeletedItem(id);
    setDeleteAlert(true);
  };

  const deleteItem = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await studentServices.deleteStudent(deletedItem);
      if (response.status === 200) {
        getItems(1, selectedClassRoom.value);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("UserName")}
        </div>
      ),
      selector: (row) => (
        <div
          className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 hover:cursor-pointer"
          onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
        >
          {row.user?.firstName + " " + row.user?.midName}
          <br />
          <p className="text-sm text-gray-400">{row.user?.code}</p>
        </div>
      ),
      sortable: true,
      width: "190px",
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("IdentityNumber")}
        </div>
      ),
      selector: (row) => (
        <div
          className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 hover:cursor-pointer"
          onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
        >
          {row.user?.identityNumber}
          <br />
          <p className="text-sm text-gray-400">{row.user?.code}</p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("PhoneNumber")}
        </div>
      ),
      selector: (row) => (
        <p
          className="ltr:text-left rtl:text-right hover:cursor-pointer"
          onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
        >
          {row.user?.phoneNumber}
        </p>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Parents")}
        </div>
      ),
      selector: (row) =>
        row.studentParent !== null ? (
          <p
            className="ltr:text-left rtl:text-right hover:cursor-pointer"
            onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
          >
            {row.studentParent?.parent?.firstName}{" "}
            {row.studentParent?.parent?.midName}
          </p>
        ) : (
          <center>
            <span
              className="px-1 inline-flex items-center text-xs font-medium hover:cursor-pointer"
              onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
            >
              <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                {t("NotAssignedyet")}
              </dd>
            </span>
          </center>
        ),
      width: "100px",
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          <div>{t("ParentsContacts")}</div>
          <span className="text-gray-700 font-thin text-xs">
            {t("ParentsContactsDesc")}
          </span>
        </div>
      ),
      selector: (row) =>
        row.studentParent !== null ? (
          <div
            className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 hover:cursor-pointer"
            onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
          >
            {row.studentParent.parent.email}
            <br />
            <p className="text-sm text-gray-400">
              {row.studentParent?.parent?.phoneNumber}
            </p>
          </div>
        ) : (
          <center>
            <span
              className="px-1 inline-flex items-center text-xs font-medium hover:cursor-pointer"
              onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
            >
              <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                {t("NotAssignedyet")}
              </dd>
            </span>
          </center>
        ),
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      cellExport: (row) => row.null,
      selector: (row) => (
        <>
          <button
            className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() => navigate(`/student/edit/${gradeId}/${row.id}`)}
          >
            <span>
              <PencilIcon
                className="h-5 w-5"
                stroke="currentColor"
                aria-hidden="true"
              ></PencilIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3  justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() => navigate(`/student/details/${gradeId}/${row.id}`)}
          >
            <span>
              <DocumentIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></DocumentIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
            onClick={() => alertDelete(row.id)}
          >
            <span>
              <TrashIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></TrashIcon>
            </span>
          </button>
        </>
      ),
      sortable: true,
      width: "190px",
    },
  ];

  const createStudents = () => {
    setLoading(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setLoading(false);
      navigate(
        `/students/create/${gradeId}/${selectedClassRoom.value}?class=${selectedClassRoom.label}`
      );
    }, 1000);
  };

  const downloadStudents = () => {
    window.open(
      `http://localhost:3000/institute/print/students/download/sheet/${selectedClassRoom.value}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      {loading && <LoadingOver />}
      <DeleteItemAlert
        show={deleteAlert}
        confirm={deleteItem}
        cancel={() => setDeleteAlert(false)}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="px-4 sm:px-6 lg:px-8 py-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                {t("StudentsOf")}{" "}
                {new URLSearchParams(window.location.search).get("grade")}
              </h1>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between mt-2">
            {!isLoading && (
              <div className="w-1/4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t("selectClassRoom")}
                </label>
                <Select
                  placeholder={"Select Class Room"}
                  primaryColor={"amber"}
                  options={classRooms}
                  onChange={handleClasses}
                  value={selectedClassRoom}
                  isSearchable={true}
                  searchInputPlaceholder={t("Search")}
                />
              </div>
            )}
            <div className="rtl:text-left ltr:text-right mt-[20px]">
              <button
                type="button"
                onClick={() => downloadStudents()}
                className="inline-flex items-center justify-center rounded-md border border-secondary bg-white px-4 py-2 mr-2 text-sm font-medium text-secondary shadow-sm hover:text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
              >
                <ArrowDownTrayIcon
                  className="ml-2 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                {t("PrintStudents")}
              </button>
              <button
                type="button"
                onClick={() => createStudents()}
                className="rtl:mr-2 ltr:ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
              >
                <PlusIcon className="ml-2 mr-2 h-4 w-4" aria-hidden="true" />
                {t("AddNewStudents")}
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <div className="min-w-full divide-y divide-gray-300">
                    <div className="divide-y divide-gray-200 bg-white">
                      {/* Grades List Table */}
                      {/* <DataTableExtensions {...tableData}> */}
                      <DataTable
                        columns={columns}
                        data={rows}
                        progressPending={isLoading}
                        Header
                        pagination
                        paginationServer
                        highlightOnHover
                        paginationTotalRows={count}
                        onChangeRowsPerPage={getNextItems}
                        onChangePage={getItems}
                        noDataComponent={<NoData />}
                        progressComponent={<LoadingData />}
                      />
                      {/* </DataTableExtensions> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
