import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import * as groupServices from "../../services/group.services";
import DataTable from "react-data-table-component";
import NoData from "../common/NoData";
import LoadingData from "../common/LoadingData";

const GroupStudentsModal = ({ open, setOpen, groupId, setGroupId }) => {
  console.log("🚀 ~ GroupStudentsModal ~ groupId:", groupId);
  const [students, setStudents] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getStudents(1);
    // eslint-disable-next-line
  }, []);

  const getStudents = async (page) => {
    await groupServices
      .getSubjectStudents(groupId, page, limit)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setStudents(response.data.rows);
        setCount(response.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ awaitgroupServices.getGroup ~ error:", error);
        setIsLoading(false);
      });
  };

  const getNextItems = async (page, limit) => {
    setIsLoading(true);
    await groupServices
      .getSubjectStudents(groupId, page, limit)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setStudents(response.data.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ awaitgroupServices.getGroup ~ error:", error);
        setIsLoading(false);
      });
  };

  const columns = [
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("ID")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm text-gray-900">
          {row.student.id}
        </div>
      ),
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("Name")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm text-gray-900">
          {row.student.user.firstName + " " + row.student.user.midName}
        </div>
      ),
    },
    // {
    //   name: (
    //     <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 px-4">
    //       {t("subjects")}
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <div className="flex flex-wrap gap-1.5 py-1 px-4">
    //       {row.subjects.map((subject) => (
    //         <p
    //           key={subject.id}
    //           onClick={() => showModal(subject.id)}
    //           className="text-white bg-primary font-semibold p-1 px-2 border-none rounded-xl hover:cursor-pointer hover:transform hover:scale-110 transition-transform"
    //         >
    //           {subject.name}
    //         </p>
    //       ))}
    //     </div>
    //   ),
    //   width: "700px",
    //   sortable: true,
    // },
  ];

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpen(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none"
                      onClick={() => {
                        setOpen(false);
                        setGroupId(null);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {t("Students")}
                      </Dialog.Title>
                      <div className="mt-2 text-center">
                        {students && (
                          <DataTable
                            columns={columns}
                            data={students}
                            progressPending={isLoading}
                            Header
                            pagination
                            paginationServer
                            highlightOnHover
                            paginationTotalRows={count}
                            onChangeRowsPerPage={getNextItems}
                            onChangePage={getStudents}
                            noDataComponent={<NoData />}
                            progressComponent={<LoadingData />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default GroupStudentsModal;
