import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field } from "formik";
import { logout } from "../../slices/authSlice";
import * as contactServices from "../../services/contact.services";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import SuccessAlert from "../common/SuccessAlert";
import FailedAlert from "../common/FailedAlert";

export default function SendNotification({ open, close, phoneNumber, userId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);

  const validationSchema = Yup.object().shape({
    arabicTitle: Yup.string().required(t("Required")),
    arabicBody: Yup.string().required(t("Required")),
  });

  const handleSubmit = (inputs) => {
    contactServices
      .sendNotification(userId, {
        ...inputs,
        englishTitle: inputs.arabicTitle,
        englishBody: inputs.arabicBody,
        action: "message",
      })
      .then((response) => {
        setSendSuccess(true);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setSendSuccess(false);
        }, 5000);
        close();
      })
      .catch((error) => {
        setSendFailed(true);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setSendFailed(false);
        }, 5000);
        close();

        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  return (
    <>
      <SuccessAlert show={sendSuccess} message={t("NotificationSent")} />
      <FailedAlert show={sendFailed} message={t("SomethingWentWroing")} />

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <>
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                        <PaperAirplaneIcon
                          className="h-6 w-6 text-primary"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {phoneNumber}
                        </Dialog.Title>
                        <div className="mt-2"></div>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        arabicTitle: "",
                        arabicBody: "",
                      }}
                      validateOnBlur={false}
                      validateOnChange={false}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {({
                        touched,
                        errors,
                        setFieldValue,
                        setTouched,
                        values,
                      }) => (
                        <Form>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                            <div className="sm:col-span-12">
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  name="arabicTitle"
                                  id="arabicTitle"
                                  placeholder="arabicTitle"
                                  className={`block w-full rounded-md border-red-600 shadow-sm focus:border-secondary  sm:text-sm ${
                                    errors.arabicTitle
                                      ? "border-2 border-red-600 focus:ring-secondary"
                                      : "focus:border-secondary focus:ring-secondary"
                                  }`}
                                />
                                {errors.arabicTitle && (
                                  <p
                                    className="mt-1 px-4 text-sm text-red-600"
                                    id={"arabicTitle-error"}
                                  >
                                    {errors.arabicTitle}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="sm:col-span-12">
                              <div className="mt-1">
                                <Field
                                  component="textarea"
                                  rows="5"
                                  name="arabicBody"
                                  id="arabicBody"
                                  placeholder="Write Message Here ..."
                                  autoComplete="arabicBody"
                                  className={`block w-full rounded-md border-red-600 shadow-sm focus:border-secondary  sm:text-sm ${
                                    errors.arabicBody
                                      ? "border-2 border-red-600 focus:ring-secondary"
                                      : "focus:border-secondary focus:ring-secondary"
                                  }`}
                                />
                                {errors.arabicBody && (
                                  <p
                                    className="mt-1 px-4 text-sm text-red-600"
                                    id={"arabicBody-error"}
                                  >
                                    {errors.arabicBody}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
                            >
                              {t("Send")}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <div className="mt-2 sm:mt-2">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                        onClick={close}
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
