import { useTranslation } from "react-i18next";

export default function LanguageFloatingButton({ show, confirm, cancel }) {
  const { i18n } = useTranslation();
  const handleLanguage = () => {
    if (window.localStorage.getItem("i18nextLng") === "ar") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ar");
    }
  };
  return (
    <>
      <button
        onClick={handleLanguage}
        className="fixed px-5 py-2 top-10 right-14 rounded-full bg-gray-600 px-2.5 py-1 text-sm/[14px] font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      >
        {window.localStorage.getItem("i18nextLng") === "ar"
          ? "English"
          : "عربي"}
      </button>
    </>
  );
}
