import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon
} from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as AdsServices from "../../services/ads.services";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import { format } from "date-fns";

export default function AdsList() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getItems(1);
    // eslint-disable-next-line
  }, []);

  const getItems = async (page) => {
    setIsLoading(true);
    try {
      const response = await AdsServices.getAds(
        page,
        limit
      );
      console.log("🚀 ~ getItems ~ response:", response)
      if (response.status === 200) {
        setRows(response.data.ads.rows);
        setCount(response.data.ads.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await AdsServices.getAds(
        page,
        limit
      );
      if (response.status === 200) {
        setRows(response.data.grades.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const alertDelete = async (id) => {
    setDeletedItem(id);
    setDeleteAlert(true);
  };

  const deleteItem = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await AdsServices.deleteAd(deletedItem);
      if (response.status === 200) {
        getItems(1);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("adTitle")}
        </div>
      ),
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Description")}
        </div>
      ),
      selector: (row) => row?.description,
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("CreatedAt")}
        </div>
      ),
      selector: (row) => format(new Date(row?.createdAt), "MMMM d, y"),
      sortable: true,
    },
    {
      name: <div className="text-sm font-semibold text-gray-600 "></div>,
      selector: (row) => (
        <>
          <button
            className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() => navigate(`/ads/edit/${row.id}`)}
          >
            <span>
              <PencilIcon
                className="h-5 w-5"
                stroke="currentColor"
                aria-hidden="true"
              ></PencilIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
            onClick={() => alertDelete(row.id)}
          >
            <span>
              <TrashIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></TrashIcon>
            </span>
          </button>
        </>
      ),
      cellExport: (row) => row.null,
      width: "250px",
    },
  ]

  return (
    <>
      <DeleteItemAlert
        show={deleteAlert}
        confirm={deleteItem}
        cancel={() => setDeleteAlert(false)}
      />
      <Layout>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("ads")}
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => navigate(`/ads/create`)}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
            >
              <PlusIcon className=" h-4 w-4" aria-hidden="true" />
              <p className="ltr:ml-2 rtl:mr-2">{t("PublishAd")}</p>
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white">
                    {/* <DataTableExtensions {...tableData}> */}
                    <DataTable
                      columns={columns}
                      data={rows}
                      progressPending={isLoading}
                      Header
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationTotalRows={count}
                      onChangeRowsPerPage={getNextItems}
                      onChangePage={getItems}
                      noDataComponent={<NoData />}
                      progressComponent={<LoadingData />}
                    />
                    {/* </DataTableExtensions> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
