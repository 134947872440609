import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UsersIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import CreateParentModal from "../parents/CreateParentModal";

function NoParentsInfo({ id }) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-10">
        <button
          type="button"
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <UsersIcon className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            {t("NoParentsInfo")}
          </span>
        </button>
      </div>
    </>
  );
}

export default NoParentsInfo;
