import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setError } from "./errorSlice";
import AuthService from "../services/auth.services";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk("auth/login", async (model, thunkAPI) => {
  try {
    const response = await AuthService.login(model);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data));

    if (response.data.institute !== null) {
      localStorage.setItem("instituteId", response.data.institute.id);
      localStorage.setItem("mode", response.data.institute.mode);
    }
    return thunkAPI.fulfillWithValue({
      user: response.data,
      status: response.status,
    });
  } catch (error) {
    thunkAPI.dispatch(setError({ message: "EmailPasswordIncorrect" }));
    return thunkAPI.rejectWithValue({
      user: null,
      status: error.response.status,
    });
  }
});

export const register = createAsyncThunk(
  "auth/register",
  async (model, thunkAPI) => {
    try {
      const response = await AuthService.register(model);
      console.log(response.data.user.token);
      localStorage.removeItem("token");
      localStorage.setItem("token", response.data.user.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      return thunkAPI.fulfillWithValue({
        user: response.data.user,
        status: response.status,
      });
    } catch (error) {
      thunkAPI.dispatch(
        setError({ message: "EmailAlreadyTakenOrSomethingWentWroing" })
      );
      return thunkAPI.rejectWithValue({
        user: null,
        status: error.response.status,
      });
    }
  }
);

export const completeRegister = createAsyncThunk(
  "auth/completeRegister",
  async (model, thunkAPI) => {
    try {
      const response = await AuthService.completeRegister(model);
      console.log("🚀 ~ response:", response.status)
      localStorage.setItem("instituteId", response.data.institute.id);
      localStorage.setItem("mode", response.data.institute.mode);
      return thunkAPI.fulfillWithValue({
        institute: response.data.institute,
        status: response.status,
      });
    } catch (error) {
      thunkAPI.dispatch(setError({ message: "schoolEmailIsAlreadyTaken" }));
      return thunkAPI.rejectWithValue({
        institute: null,
        status: error.response.status,
      });
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  //await AuthService.logout();
  //localStorage.clear();
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("instituteId");
  return { isLoggedIn: false, isCompleteRegister: null, user: null };
});

export const confirmEmail = createAsyncThunk("auth/confirm-email", async () => {
  await AuthService.confirmEmail();
});

const initialState = user
  ? { isLoggedIn: true, isCompleteRegister: user.institute, user: user }
  : { isLoggedIn: false, isCompleteRegister: null, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.isCompleteRegister = action.payload.user.institute;
      state.user = action.payload.user;
      state.status = action.payload.status;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.isCompleteRegister = null;
      state.user = null;
      state.status = action.payload.status;
    },
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.isCompleteRegister = null;
      state.user = action.payload.user;
      state.status = action.payload.status;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.isCompleteRegister = null;
      state.user = null;
      state.status = action.payload.status;
    },
    [completeRegister.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.isCompleteRegister = action.payload.institute;
      state.status = action.payload.status;
    },
    [completeRegister.rejected]: (state, action) => {
      state.isCompleteRegister = null;
      state.status = action.payload.status;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.isCompleteRegister = null;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
