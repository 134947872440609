import React, { Fragment } from "react";
import Header from "../../components/root/Header";
import SideBar from "../../components/root/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
  PlusIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import * as classRoomServices from "../../services/classroom.services";
import * as Yup from "yup";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";

export default function CreateClass() {
  let { gradeId } = useParams();
  const { t } = useTranslation();

  let navigate = useNavigate();
  const pages = [
    { name: t("Grades"), href: "/grades/class", current: false },
    {
      name: t("GradeDetails"),
      href: `/grade/details/${gradeId}`,
      current: true,
    },
    { name: t("AddNewClasses"), href: "#", current: true },
  ];

  const handleSubmit = (inputs) => {
    console.log("inputs: ", inputs);
    classRoomServices
      .createClassRooms(inputs)
      .then((response) => {
        if (response.status === 201) navigate(`/grade/details/${gradeId}`);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Create grades.js:39 ~ handleSubmit ~ error",
          error
        );
      });
  };

  const handleCancel = async () => {
    navigate(`/grade/details/${gradeId}`);
  };

  const classRoomsValidationSchema = Yup.object().shape({
    classRooms: Yup.array()
      .of(
        Yup.object().shape({
          arabicName: Yup.string().required(t("Required")),
        })
      )
      .required(t("OneRecordMin"))
      .min(1, t("OneRecordMin")),
  });

  return (
    <>
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <Formik
              initialValues={{
                classRooms: [
                  {
                    instituteGradeId: gradeId,
                    arabicName: "",
                    englishName: "",
                  },
                ],
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={classRoomsValidationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form>
                  <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-xl font-semibold text-gray-900">
                      {t("AddNewClasses")}
                    </h3>
                    <div className="mt-2 flex sm:mt-0 sm:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        onClick={handleCancel}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                  <FieldArray
                    name="classRooms"
                    render={(classArrayHelpers) => (
                      <>
                        {values.classRooms && (
                          <div className="flex flex-col pb-3 ">
                            <div className="relative mt-6 border rounded-lg shadow-lg px-4 py-8 bg-white">
                              <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block  px-1 text-xl font-semibold text-gray-900">
                                {t("Classes")}
                              </h2>
                              {values.classRooms.map(
                                (classroom, classRoomIdx) => (
                                  <div
                                    key={classRoomIdx.toString()}
                                    className="mt-6"
                                  >
                                    <label key={classRoomIdx}>
                                      {classRoomIdx > 0 && (
                                        <button
                                          onClick={() =>
                                            classArrayHelpers.remove(
                                              classRoomIdx
                                            )
                                          }
                                        >
                                          <MinusCircleIcon className="w-6 h-6 text-red-500" />
                                        </button>
                                      )}
                                      <div className="relative grid grid-cols-1 gap-y-6 gap-4 grid-cols-6 pt-2">
                                        <div className="sm:col-span-3">
                                          <label
                                            htmlFor="arabic-name"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            {t("ArabicName")}
                                          </label>
                                          <div className="mt-1">
                                            <Field
                                              autoComplete="arabic-name"
                                              name={
                                                "classRooms[" +
                                                classRoomIdx +
                                                "].arabicName"
                                              }
                                              type="text"
                                              id={
                                                "classRooms-" +
                                                classRoomIdx +
                                                "-arabicName"
                                              }
                                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                                errors.classRooms &&
                                                errors.classRooms[classRoomIdx]
                                                  ?.arabicName
                                                  ? "border-2 border-red-700 focus:ring-secondary"
                                                  : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                            />
                                            {touched.classRooms &&
                                              touched.classRooms[classRoomIdx]
                                                ?.arabicName &&
                                              errors.classRooms &&
                                              errors.classRooms[classRoomIdx]
                                                ?.arabicName && (
                                                <p
                                                  className="mt-1 px-4 text-sm text-red-600"
                                                  id={
                                                    "classRooms-" +
                                                    classRoomIdx +
                                                    "-arabicName-error"
                                                  }
                                                >
                                                  {
                                                    errors.classRooms[
                                                      classRoomIdx
                                                    ].arabicName
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                          <label
                                            htmlFor="english-name"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            {t("EnglishName")}
                                          </label>
                                          <div className="mt-1">
                                            <Field
                                              autoComplete="english-name"
                                              name={
                                                "classRooms[" +
                                                classRoomIdx +
                                                "].englishName"
                                              }
                                              type="text"
                                              id={
                                                "classRooms-" +
                                                classRoomIdx +
                                                "-englishName"
                                              }
                                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                                errors.classRooms &&
                                                errors.classRooms[classRoomIdx]
                                                  ?.englishName
                                                  ? "border-2 border-red-700 focus:ring-secondary"
                                                  : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                            />
                                            {touched.classRooms &&
                                              touched.classRooms[classRoomIdx]
                                                ?.englishName &&
                                              errors.classRooms &&
                                              errors.classRooms[classRoomIdx]
                                                ?.englishName && (
                                                <p
                                                  className="mt-1 px-4 text-sm text-red-600"
                                                  id={
                                                    "classRooms-" +
                                                    classRoomIdx +
                                                    "-englishName-error"
                                                  }
                                                >
                                                  {
                                                    errors.classRooms[
                                                      classRoomIdx
                                                    ].englishName
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                )
                              )}
                              <div className="flex  justify-end flex-wrap items-center px-4 py-2">
                                <button
                                  type="button"
                                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 my-4 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                  onClick={() =>
                                    classArrayHelpers.push({
                                      instituteGradeId: gradeId,
                                      arabicName: "",
                                      englishName: "",
                                    })
                                  }
                                >
                                  <PlusIcon className="h-5 w-5 mx-2"></PlusIcon>
                                  <span>{t("AddClass")}</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
}
