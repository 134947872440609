import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { AcademicCapIcon } from "@heroicons/react/24/outline";
import { logout } from "../../slices/authSlice";
import * as gradeServices from "../../services/grade.services";
import * as classRoomServices from "../../services/classroom.services";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import { useTranslation } from "react-i18next";

export default function SearchModal({ open, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);

  const doSearch = async (e) => {
    try {
      let key = e.target.value;
      if (key !== "") {
        const response = await classRoomServices.search(key);
        console.log(response);
        if (response.status === 200) {
          if (response.data.result.length > 0) {
            setResults(response.data.result);
          }
          setLoading(false);
        }
      } else {
        setResults([]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  //setAddedParent(response.data.user.userId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-start justify-center p-4 text-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <>
                  <div className="">
                    <Formik
                      initialValues={{
                        key: "",
                      }}
                      validateOnBlur={false}
                      validateOnChange={false}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        setTouched,
                        setFieldValue,
                      }) => (
                        <Form>
                          <input
                            id="key"
                            name="key"
                            placeholder={t("Search")}
                            type="text"
                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm focus:border-secondary focus:ring-secondary`}
                            onChange={doSearch}
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="mt-5 max-h-[45rem] overflow-auto">
                    <ul role="list" className="divide-y divide-gray-100">
                      {results.map((item) =>
                        item.type === "class_room" ? (
                          <li
                            key={item.id}
                            className="flex items-center justify-between gap-x-6 py-5"
                          >
                            <div className="min-w-0">
                              <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {item.arabicName}
                                </p>
                                <p className="text-amber-700 bg-amber-50 ring-amber-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                  {t("instituteClassRoom")}
                                </p>
                              </div>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <svg
                                  viewBox="0 0 2 2"
                                  className="h-0.5 w-0.5 fill-current"
                                >
                                  <circle cx={1} cy={1} r={1} />
                                </svg>
                                <p className="truncate">
                                  {item.instituteGrade.arabicName}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                              <a
                                href={`/class-room/details/${item.instituteGrade.id}/${item.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                              >
                                {t("View")}
                              </a>
                            </div>
                          </li>
                        ) : item.type === "grade" ? (
                          <li
                            key={item.id}
                            className="flex items-center justify-between gap-x-6 py-5"
                          >
                            <div className="min-w-0">
                              <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {item.arabicName}
                                </p>
                                <p className="text-emerald-700 bg-emerald-50 ring-emerald-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                  {t("instituteGrade")}
                                </p>
                              </div>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <svg
                                  viewBox="0 0 2 2"
                                  className="h-0.5 w-0.5 fill-current"
                                >
                                  <circle cx={1} cy={1} r={1} />
                                </svg>
                                <p className="truncate">
                                  {item.institute.arabicName}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                              <a
                                href={`/grade/details/${item.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                              >
                                {t("View")}
                              </a>
                            </div>
                          </li>
                        ) : item.type === "teacher" ? (
                          <li
                            key={item.id}
                            className="flex items-center justify-between gap-x-6 py-5"
                          >
                            <div className="min-w-0">
                              <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {item.user.firstName +
                                    " " +
                                    item.user.midName}
                                </p>
                                <p className="text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                  {t("Teacher")}
                                </p>
                              </div>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <svg
                                  viewBox="0 0 2 2"
                                  className="h-0.5 w-0.5 fill-current"
                                >
                                  <circle cx={1} cy={1} r={1} />
                                </svg>
                                <p className="truncate">
                                  {item.user.phoneNumber}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                              <a
                                href={`/teacher/details/${item.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                              >
                                {t("View")}
                              </a>
                            </div>
                          </li>
                        ) : (
                          <li
                            key={item.id}
                            className="flex items-center justify-between gap-x-6 py-5"
                          >
                            <div className="min-w-0">
                              <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {item.user.firstName +
                                    " " +
                                    item.user.midName}
                                </p>
                                <p className="text-blue-700 bg-blue-50 ring-blue-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                  {t("Student")}
                                </p>
                              </div>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <svg
                                  viewBox="0 0 2 2"
                                  className="h-0.5 w-0.5 fill-current"
                                >
                                  <circle cx={1} cy={1} r={1} />
                                </svg>
                                <p className="truncate">
                                  {item.user.phoneNumber}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                              <a
                                href={`/student/details/${item.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                              >
                                {t("View")}
                              </a>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
