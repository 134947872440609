const english = {
  champion: "Who's Champion",
  Home: "Home",
  Email: "Email",
  GetStartedByDownloadingaTemplate:
    "Get started by downloadinga template and fill all new Student by grades and their class rooms",
  // SaveUploadedFile: "Then Save New Added Students Sheet And Upload The File",
  SaveUploadedFile: "Add students data from the excel sheet model,",
  SaveUploadedTeacherFile: "Add teachers data from the excel sheet model,",
  SaveFile: "save file",
  ReUpload: "and then upload it.",
  SearchByEmailPhoneName: "Search By Name, Email Or Phone Number ...",
  WriteMessage: "Write Message Here ...",
  //Messages
  Required: "Required",
  PasswordMin3: "Password must be 3 characters at minimum",
  PasswordMustMatch: "Passwords must match",
  InvalidEmail: "Email format is incorrect",
  OneRecordMin: "One record as minimum is required",
  EmailPasswordIncorrect: "Email or password is incorrect, please try again.",
  //Update
  Name: "Name",
  Password: "Password",
  RememberMe: "Remember Me",
  ForgetPassword: "Forget Password ?",
  Login: "Login ",
  Logout: "Logout",
  DontHaveAccountYet: "Dont Have Account Yet ?",
  CreateNewAccount: "Create New Account",
  IndexHeroHeader: "Lorem Ipsum",
  IndexHeroBody:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  FirstName: "First Name",
  LastName: "Mid Name",
  ConfirmPassword: "Confirm Password",
  RegisterNewAccount: "Register A New Account",
  AlreadyRegistered: "Already Registered !",
  Dashboard: "Dashboard",
  GradesAndClasses: "Grades & Classrooms",
  Teachers: "Teachers",
  Students: "Students",
  Parents: "Parents",
  ParentsManagement: "Parents Management",
  PricesAndSubscriptions: "Prices & Subscriptions",
  Reports: "Reports",
  ContactUs: "Contact Us",
  ViewProfile: "View Profile",
  Statistic: "Statistic",
  OTP: "OTP",
  ConfirmEmail: "Confirm Email",
  DidntGetOTP: "Didnt Get OTP Yet ?",
  ResendOTP: "Resend OTP ",
  ArabicName: "Arabic Name ",
  EnglishName: "English Name ",
  PhoneNumber: "Phone Number",
  Address: "Address",
  CompleteRegister: "Complete Registration",
  SchoolInformation: "Institute Information ",
  ChangePassword: "Change Password",
  NewPassword: "New Password",
  ResetPassword: "Reset your Password",
  Continue: "Continue",
  Grades: "Institute Grades",
  AddNewGrade: "Add New Grade",
  CreatedAt: "Created At",
  UpdatedAt: "Updated At",
  Cancel: "Cancel",
  Save: "Save",
  Remove: "Remove",
  Classes: "Classrooms",
  AddClass: "Add Classrooms ",
  AddNewClasses: "Add New Classrooms  ",
  AddGrade: "Add Grade",
  EditGrade: "Edit Grade Information",
  Delete: "Delete",
  DeleteThisItem: "Delete This Item",
  GradeDetails: "Grade Details",
  ClassRooms: "Classrooms",
  EditClassRoom: "Edit Classroom",
  ClassRoomDetails: "Classroom Details",
  UploadExcel: "Upload Excel File",
  AddNewStudents: "Add New Students",
  UserName: "User-Name",
  Parent: "Parent",
  NotAssignedyet: "Not Assigned Yet",
  ParentsContacts: "Parents Contacts",
  BirthDate: "BirthDate",
  Gender: "Gender",
  Female: "Female",
  Male: "Male",
  SelectedParent: "Selected Parent",
  AddNewStudent: "Add New Student",
  CLassRooms: "CLassrooms",
  SelectedClassRoom: "Selected Classroom",
  StudentDetails: "Student Details ",
  StudentInformation: "Student Information",
  PersonalDetails: "Personal Details",
  FullName: "Full Name",
  Code: "Code",
  Confirmed: "Confirmed",
  NotConfirmedYet: "Not Confirmed Yet",
  RegistrationDate: "Registration Date",
  Attachments: "Attachments",
  Download: "Download",
  Details: "Details",
  ClassName: "Class Name",
  Grade: "Grade",
  CreattionDate: "Creation Date",
  ParentsManagements: "Parents Managements",
  SendEmail: "Send Email",
  Send: "Send",
  Description: "Description",
  Notes: "Notes",
  AddParent: "Add Parents",
  UploadAndAddNewStudents: "Upload And Add New Students",
  UploadAndAddNew: "Upload And Add New ",
  FirstDownloadSheetTemplate:
    "To upload student data using an Excel sheet follow bellow steps:",
  FirstStep: "First step:",
  SecondStep: "Second step:",
  Downlad: "Downlad",
  NewStudentsSheet: "Press here to upload the student's Excel sheet model",
  ChooseFile: "Choose File ",
  EditStudent: "Edit Student  ",
  UpdateStudentDetails: "Update Student Details  ",
  ChangeCLassRoom: "ChangeCLassroom ",
  AssignedCLassRooms: "Assigned CLassrooms",
  AddNewTeachers: "Add New Teachers",
  AddNewTeacher: "Add New Teacher",
  EditTeacherDetails: "Edit Teacher Details",
  TeacherDetails: "Teacher Details",
  UploadAndAddNewTeachers: "Upload And Add New Teachers",
  FirstDownloadTeachersSheetTemplate: "First Download Teachers Sheet Template",
  GetStartedByDownloadingaTeachersTemplate:
    "Get Started By Downloadinga Teachers Template",
  NewTeachersSheet: "Press here to upload the teacher's Excel sheet model",
  SaveUploadedTeachersFile: "Save Uploaded Teachers File",
  SendNotification: "Send Notification ",
  SelectParent: "Select Parent ",
  SearchParents: "Search Parents",
  SendEmailTo: " Send Email To  ",
  Subject: "Subject",
  SendNotificationTo: "Send Notification To ",
  CreateParent: "Create Parent  ",
  NewParent: "New Parent",
  GradesClassrooms: "Grades Classrooms  ",
  ClassRoom: "Classroom",
  ClassRoomsOf: "Classrooms",
  Champions: "Champions ",
  Print: "Print",
  EditClassRooms: "Edit Classrooms ",
  Create: "Create ",
  Select: "Select ",
  SelectOrCreate: "Select Or Create",
  SelectGradeFirst: "Select Grade First",
  ShowStudents: "Show Students",
  Search: "Search",
  ParentPhoneNumber: "Parent Phone Number",
  IdentityNumber: "Identity Number ",
  PrintStudents: "PrintStudents ",
  View: "View",
  instituteGrade: "institute Grade ",
  instituteClassRoom: "institute Classroom",
  Teacher: "Teacher",
  Student: "Student",
  UpdatePassword: "Change password",
  StudentsOf: "Students",
  classRoom: "Classroom",
  selectClassRoom: "select Classroom",
  SaveChanges: "Save Changes",
  //Messages
  SureWantToDeleteThisItem: "Sure Want To Delete This Item ?",
  ParentInformation: "Parent Information",
  NotifyParents: "Notify Parents  ",
  UploadTeachersCompleted: "Upload Teachers Completed",
  UploadTeachersFailed: "Upload Teachers Failed",
  UploadStudentsCompleted: "Upload Students Completed",
  UploadStudentsFailed: "Upload Students Failed",
  NoData: "No Records.",
  NoRecords: "No Records",
  LoadingData: "Loading, please wait ",
  EmailAlreadyTakenOrSomethingWentWroing:
    "Email Already Taken Or Something Went Wroing",
  SomethingWentWroing: "Sorry .. Something Went Wroing",
  NewParentsAddedAearchAgain: "New Parents Added Search Again  please",
  schoolEmailIsAlreadyTaken: "Institute Email Is Already Taken",
  NoParentsInfo: "No Parents Informations",
  EmailSent: "Email Sent",
  NotificationSent: "Notification Sent",
  changesSaved: "Changes are Saved ",
  changesNotSaved: "Changes are Not Saved, Something Went wrong",
  DataUpdated: "Data Updated Successfully",
  ParentsContactsDesc:
    "The entered phone number is the username and the password for parents",
  PasswordDesc: "You can add identity number as password",
  Rounds: "Rounds",
  RoundTime: "Round Time",
  Periodic: "Periodic",
  Specific: "Specific Date",
  Period: "Period",
  Weekly: "Weekly",
  Monthly: "Monthly",
  FromDate: "From Date",
  ToDate: "To Date",
  EditRound: "Edit Round",
  UpdateRound: "Round updated successfully",
  identityDesc: "Student name in b6lapp will be the this enterd number. ",
  EmailOrPhoneAlreadyTakenOrSomethingWentWroing:
    "Email or phone number is already taken or something went wroing",
  ThereIsEmailOrPhoneAlreadyTakenOrSomethingWentWroing:
    "There is email or phone number already taken or something wentwroing",
  HowToUse: "How to use!",
  Attendance: "Attendance",
  absent: "Absent",
  attend: "Present",
  permitted: "Excused",
  remote: "Remote",
  pickDate: "Pick Date",
  Subjects: "Subjects",
  AddSubjects: "Add Subjects",
  SubjectName: "Subject Name",
  EditSubject: "Edit Subject",
  AttendancePoints: "Attendance Points",
  save: "Save",
  arabicDescription: "Arabic Description",
  englishDescription: "English Description",
  SavedSuccessfully: "Saved Successfully",
  cLassRooms: "Classrooms",
  superviser: "Superviser",
  classRoomName: "Classroom Name",
  true: "True",
  false: "False",
  subjects: "Subjects",
  subjectGroup: "Subject Groups",
  groupName: "Group Name",
  studentStatistics: "Student Statistics",
  budget: "Budget",
  budgetDays: "Budget Days",
  totalKids: "Total kids number",
  amountPerPoint: "Budget amount per point",
  totalStudents: "Number of students",
  pointsExpireAfter: "Given points expire after - in days",
  configs: "Configurations",
  PointsConfigs: "Points",
  attendanceConfgis: "Attendance",
  CreateFailed: "Something went wroing while trying to create new record.",
  UpdateFailed: "Something went wroing while trying to update record.",
  DeleteFailed: "Something went wroing while trying to delete record.",
  Error: "Something went worning , please try again.",
  AddNewGiftCard: "Add new giftcard",
  title: "Title",
  quantity: "Quantity",
  points: "Points",
  exchangeInstruction: "Exchange Instructions",
  GiftCards: "Giftcards",
  AddGiftCards: "Add new giftcard",
  expireAt: "Expire  At",
  type: "Type",
  RedeemCard: "Redeem giftcard",
  ExhcangeCard: "exchangable giftcard",
  ExchangePoints: "Exchange points",
  GiftDetails: "Gift details",
  GiftsSupervisor: "Gifts supervisor",
  EditAd: "Edit Ads",
  url: "URL",
  PublishAd: "Publish Ads",
  ads: "Ads",
  sar: "SAR",
  point: "Point",
  NoCodes: "No added gift cards yet.",
  Codes: "Gift cards",
  AddCode: "Add new card",
  ExchangeCard: "Physical Gift",
  adTitle: "Title",
  adDescription: "Description",
  optionalField: "Optional",
  SupervisedCLassRooms: "Supervised classrooms (Attendance)",
  roundMode: "Rounds Mode",
  pointsMode: "Points Mode",
  appMode: "Define App mode",
  roundsModeDescription: "Each student can collect points and compete each other for the first place ( Gifts are for a defined places only)",
  pointsModeDescription: "Collect exchangable points, here each student can collect points and exchange it with a gift"
};

export default english;
