import React, { Fragment } from "react";
import {
  HomeIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  ArrowDownTrayIcon,
  PencilIcon,
  DocumentIcon,
  TrashIcon,
  XMarkIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as classRoomServices from "../../services/classroom.services";
import * as studentServices from "../../services/student.services";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import UploadSheet from "../../components/common/UploadSheet";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import { Formik, Form, Field } from "formik";

export default function ClassRoomDetails() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [isStudentsLoading, setIsStudentsLoading] = useState(true);
  const [classRoom, setClassRoomData] = useState([]);
  const [editClassRoom, setEditClassRoom] = useState(false);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteStudentAlert, setDeleteStudentAlert] = useState(false);
  const [deletedStudent, setDeletedStudent] = useState([]);
  const [uploadSheet, setUploadSheet] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);

  let { gradeId } = useParams();
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Grades"), href: "/grades/list", current: false },
    {
      name: t("GradeDetails"),
      href: `/grade/details/${gradeId}`,
      current: false,
    },
    { name: t("ClassRoomDetails"), href: "#", current: true },
  ];

  useEffect(() => {
    classRoomServices
      .getClassRoom(id)
      .then((response) => {
        if (response.status === 200) {
          setClassRoomData(response.data.classRoom);
          setIsLoading(false);
          getItems(1);
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, [id]);

  const handleEdit = () => {
    navigate(`/class-room/edit/${gradeId}/${id}`);
  };

  const deleteItem = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await classRoomServices.deleteClassRoom(id);
      if (response.status === 200) {
        console.log("response: ", response.data);
        navigate(`/grade/details/${gradeId}`);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const saveClassRoomChange = async (inputs) => {
    classRoomServices
      .updateClassRoom(id, inputs)
      .then((response) => {
        setEditSuccess(true);
        setEditClassRoom(false);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setEditSuccess(false);
        }, 3000);

        //update page Data
        classRoomServices.getClassRoom(id).then((response) => {
          if (response.status === 200) {
            setClassRoomData(response.data.classRoom);
            setIsLoading(false);
            getItems(1);
          }
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const getItems = async (page) => {
    setIsStudentsLoading(true);
    try {
      const response = await studentServices.getStudents(id, page, limit);
      console.log("🚀 ~ file: Details.js:131 ~ getItems ~ response:", response);
      if (response.status === 200) {
        setRows(response.data.students.rows);
        setCount(response.data.students.count);
        setIsStudentsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsStudentsLoading(true);
    try {
      const response = await studentServices.getStudents(id, page, limit);
      if (response.status === 200) {
        setRows(response.data.students.rows);
        setLimit(limit);
        setIsStudentsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const alertDeleteStudent = async (id) => {
    setDeletedStudent(id);
    setDeleteStudentAlert(true);
  };

  const deleteStudent = async () => {
    setIsLoading(true);
    setDeleteStudentAlert(false);
    try {
      const response = await studentServices.deleteStudent(deletedStudent);
      if (response.status === 200) {
        console.log("response: ", response.data);
        getItems(1);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const uploadingSuccess = async (success) => {
    if (success) {
      setUploadSuccess(true);
      getItems(1);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setUploadSuccess(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    } else {
      setUploadFailed(true);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setUploadFailed(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  };

  const columns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("UserName")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {row.user.firstName + " " + row.user.midName}
          <br />
          <p className="text-sm text-gray-400">{row.user.code}</p>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("IdentityNumber")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {row.user.identityNumber}
          <br />
          <p className="text-sm text-gray-400">{row.user.code}</p>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("PhoneNumber")}
        </div>
      ),
      selector: (row) => (
        <p className="ltr:text-left rtl:text-right">{row.user.phoneNumber}</p>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Parents")}
        </div>
      ),
      selector: (row) =>
        row.studentParent !== null ? (
          <p className="ltr:text-left rtl:text-right">
            {row.studentParent.parent.firstName}{" "}
            {row.studentParent.parent.midName}
          </p>
        ) : (
          <center>
            <span className="px-1 inline-flex items-center text-xs font-medium">
              <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                {t("NotAssignedyet")}
              </dd>
            </span>
          </center>
        ),
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("ParentsContacts")}
        </div>
      ),
      selector: (row) =>
        row.studentParent !== null ? (
          <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
            {row.studentParent.parent.email}
            <br />
            <p className="text-sm text-gray-400">
              {row.studentParent.parent.phoneNumber}
            </p>
          </div>
        ) : (
          <center>
            <span className="px-1 inline-flex items-center text-xs font-medium">
              <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                {t("NotAssignedyet")}
              </dd>
            </span>
          </center>
        ),
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      cellExport: (row) => row.null,
      selector: (row) => (
        <>
          <button
            className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() =>
              navigate(
                `/student/edit/${row.classRoom.instituteGradeId}/${row.id}`
              )
            }
          >
            <span>
              <PencilIcon
                className="h-5 w-5"
                stroke="currentColor"
                aria-hidden="true"
              ></PencilIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3  justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() =>
              navigate(
                `/student/details/${row.classRoom.instituteGradeId}/${row.id}`
              )
            }
          >
            <span>
              <DocumentIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></DocumentIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
            onClick={() => alertDeleteStudent(row.id)}
          >
            <span>
              <TrashIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></TrashIcon>
            </span>
          </button>
        </>
      ),
      sortable: true,
      width: "190px",
    },
  ];

  const handleAddStudents = async () => {
    navigate(`/students/create/${gradeId}/${id}`);
  };

  const downloadStudents = () => {
    window.open(
      `http://localhost:3000/institute/print/students/download/sheet/${id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <SuccessAlert show={editSuccess} message={t("changesSaved")} />
      <UploadSheet
        open={uploadSheet}
        close={() => setUploadSheet(false)}
        instituteId={localStorage.getItem("instituteId")}
        success={uploadingSuccess}
      />
      <SuccessAlert
        show={uploadSuccess}
        message="Upload Completed Successfully!"
      />
      <FailedAlert
        show={uploadFailed}
        message="Failed to Upload Sheets, Try Again !"
      />
      <DeleteItemAlert
        show={deleteAlert}
        confirm={deleteItem}
        cancel={() => setDeleteAlert(false)}
      />
      <DeleteItemAlert
        show={deleteStudentAlert}
        confirm={deleteStudent}
        cancel={() => setDeleteStudentAlert(false)}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6">
          <div className="mx-auto max-w-9xl px-4 sm:px-6 md:px-8">
            <div className="px-10 pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("ClassRoomDetails")}
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-dark bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dark focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => handleEdit(id)}
                >
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="ltr:ml-3 rtl:mr-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => setDeleteAlert(true)}
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            {!isLoading && (
              <Formik
                initialValues={{
                  instituteGradeId: gradeId ?? "",
                  arabicName: classRoom.arabicName ?? "",
                  englishName: classRoom.englishName ?? "",
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => saveClassRoomChange(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              {t("ArabicName")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                              <Field
                                type="text"
                                name="arabicName"
                                id="arabicName"
                                onClick={() => {
                                  setEditClassRoom(true);
                                }}
                                className={`block w-full rounded-md ${
                                  classRoom.arabicName !== ""
                                    ? "border-0 border-gray-400"
                                    : "border-1 border-amber-400"
                                } focus:border-1 focus:border-secondary  focus:ring-secondary  sm:text-sm`}
                              />
                            </dd>
                            {editClassRoom && (
                              <div className=" rtl:text-left ltr:text-right">
                                <button
                                  type="button"
                                  className="w-16 inline-flex m-1 items-center p-2 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-green-500"
                                  onClick={() =>
                                    document
                                      .getElementById(classRoom.id)
                                      .click()
                                  }
                                >
                                  <span>
                                    <CheckIcon
                                      className="h-4 w-4 "
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    ></CheckIcon>
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  className="w-16 inline-flex m-1 items-center p-2 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
                                  onClick={() => setEditClassRoom(false)}
                                >
                                  <span>
                                    <XMarkIcon
                                      className="h-4 w-4 "
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    ></XMarkIcon>
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              {t("EnglishName")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                              <Field
                                type="text"
                                name="englishName"
                                id="englishName"
                                onClick={() => {
                                  setEditClassRoom(true);
                                }}
                                className={`block w-full rounded-md ${
                                  classRoom.englishName !== ""
                                    ? "border-0 border-gray-400"
                                    : "border-1 border-amber-400"
                                } focus:border-1 focus:border-secondary  focus:ring-secondary  sm:text-sm`}
                              />
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                    <button id={classRoom.id} hidden type="submit" />
                  </Form>
                )}
              </Formik>
            )}

            <div className="sm:flex sm:items-center my-6 px-8">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  {t("Students")}
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 ltr:sm:ml-16 rtl:sm:mr-16 sm:flex-none">
                <button
                  type="button"
                  onClick={() => downloadStudents()}
                  className="rtl:ml-2 ltr:ml-2 inline-flex items-center justify-center rounded-md border border-secondary bg-white px-4 py-2 mr-2 text-sm font-medium text-secondary shadow-sm hover:text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
                >
                  <ArrowDownTrayIcon
                    className="ml-2 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t("PrintStudents")}
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
                  onClick={handleAddStudents}
                >
                  <PlusIcon className="ml-2 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("AddNewStudents")}
                </button>
              </div>
            </div>
            <div className="mt-8 px-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div className="min-w-full divide-y divide-gray-300">
                      <div className="divide-y divide-gray-200 bg-white">
                        <DataTable
                          columns={columns}
                          data={rows}
                          progressPending={isLoading}
                          Header
                          pagination
                          paginationServer
                          highlightOnHover
                          paginationTotalRows={count}
                          onChangeRowsPerPage={getNextItems}
                          onChangePage={getItems}
                          noDataComponent={<NoData />}
                          progressComponent={<LoadingData />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
