import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
    PencilIcon
} from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as ExchangePointServices from "../../services/exchangePoints.services";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import { format } from "date-fns";

export default function ExchangePointList() {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getItems(1);
        // eslint-disable-next-line
    }, []);

    const getItems = async (page) => {
        setIsLoading(true);
        try {
            const response = await ExchangePointServices.getExchangePointsList(
                page,
                limit
            );
            if (response.status === 200) {

                setRows(response.data.data.rows);
                setCount(response.data.data.count);
                setIsLoading(false);
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(logout());
            }
        }
    };

    const getNextItems = async (limit, page) => {
        setIsLoading(true);
        try {
            const response = await ExchangePointServices.getExchangePointsList(
                page,
                limit
            );
            if (response.status === 200) {
                setRows(response.data.data.rows);
                setLimit(limit);
                setIsLoading(false);
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(logout());
            }
        }
    };



    const columns = [
        {
            name: (
                <div className="text-left text-sm font-semibold text-gray-900">
                    {t("title")}
                </div>
            ),
            selector: (row) => row.giftCard.title,
            sortable: true,
        },
        {
            name: (
                <div className="text-left text-sm font-semibold text-gray-900">
                    {t("points")}
                </div>
            ),
            selector: (row) => row.giftCard.points,
            sortable: true,
        },
        {
            name: (
                <div className="text-left text-sm font-semibold text-gray-900">
                    {t("CreatedAt")}
                </div>
            ),
            selector: (row) => format(new Date(row?.createdAt), "MMMM d, y"),
            sortable: true,
        },
        {
            name: <div className="text-sm font-semibold text-gray-600 "></div>,
            selector: (row) => (
                <>
                    <button
                        className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
                        onClick={() =>
                            navigate(`/exchange-points/details/${row.id}`)
                        }
                    >
                        <span>
                            <PencilIcon
                                className="h-5 w-5"
                                stroke="currentColor"
                                aria-hidden="true"
                            ></PencilIcon>
                        </span>
                    </button>
                </>
            ),
            cellExport: (row) => row.null,
            width: "250px",
        },
    ];

    return (
        <>
            <Layout>
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            {t("ExchangePoints")}
                        </h1>
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <div className="min-w-full divide-y divide-gray-300">
                                    <div className="divide-y divide-gray-200 bg-white">
                                        {/* <DataTableExtensions {...tableData}> */}
                                        <DataTable
                                            columns={columns}
                                            data={rows}
                                            progressPending={isLoading}
                                            Header
                                            pagination
                                            paginationServer
                                            highlightOnHover
                                            paginationTotalRows={count}
                                            onChangeRowsPerPage={getNextItems}
                                            onChangePage={getItems}
                                            noDataComponent={<NoData />}
                                            progressComponent={<LoadingData />}
                                        />
                                        {/* </DataTableExtensions> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
