import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

export const Config = (instituteId, type, body) => {
  return axios.post(
    `${base.apiUrl}/config/${instituteId}/feeling/for/${type}`,
    body,
    {
      headers: headers.authHeader(),
    }
  );
};

export const getConfig = (type) => {
  return axios.get(`${base.apiUrl}/config/feeling/for/${type}`, {
    headers: headers.authHeader(),
  });
};
