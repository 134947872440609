import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../components/root/AuthLayout";
import { clearError } from "../../slices/errorSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirmEmail } from "../../slices/authSlice";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";

export default function ConfirmAccount() {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (inputs) => {
    dispatch(confirmEmail(inputs))
      .then((response) => {
        if (response.payload.success) navigate("/");
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: confirm-account.js:39 ~ handleSubmit ~ error",
          error
        );
      });
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t("Required")),
  });

  return (
    <>
      <LanguageFloatingButton />
      <AuthLayout title={t("ConfirmEmail")}>
        <div className="flex justify-center">
          <label className="mb-4 text-xl font-bold tracking-tight text-gray-100 drop-shadow-md">
            00:00:00
          </label>
        </div>
        <Formik
          initialValues={{
            code: "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className="space-y-6">
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("OTP")}
                </label>
                <div className="mt-1">
                  <Field
                    name="code"
                    id="code"
                    placeholder={t("OTP")}
                    type="text"
                    autoComplete="code"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.code && errors.code
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                  {errors.code && (
                    <p
                      className="mt-1 px-4 text-sm text-red-600"
                      id={"code-error"}
                    >
                      {errors.code}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                >
                  {t("ConfirmEmail")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <p className="mt-6 text-sm text-gray-500">
          {t("DidntGetOTP")}

          <Link
            to="#"
            className="rtl:mr-2 ltr:ml-2 font-medium text-yellow-700 hover:text-secondary"
          >
            {t("ResendOTP")}
          </Link>
        </p>
      </AuthLayout>
    </>
  );
}
