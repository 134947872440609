import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { AtSymbolIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field } from "formik";
import { logout } from "../../slices/authSlice";
import * as contactServices from "../../services/contact.services";
import * as Yup from "yup";

export default function SendEmail({ open, close, email }) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("required"),
    message: Yup.string().required("required"),
  });

  const handleSubmit = (inputs) => {
    contactServices
      .sendEmail(inputs)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                      <AtSymbolIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Send Email to {email}
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      subject: "",
                      message: "",
                      email: email,
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                  >
                    {({
                      touched,
                      errors,
                      setFieldValue,
                      setTouched,
                      values,
                    }) => (
                      <Form>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="sm:col-span-12">
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                                className={`block w-full rounded-md border-red-600 shadow-sm focus:border-secondary  sm:text-sm ${
                                  errors.subject
                                    ? "border-2 border-red-600 focus:ring-secondary"
                                    : "focus:border-secondary focus:ring-secondary"
                                }`}
                              />
                              {errors.subject && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"subject-error"}
                                >
                                  {errors.subject}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="sm:col-span-12">
                            <div className="mt-1">
                              <Field
                                component="textarea"
                                rows="12"
                                name="message"
                                id="message"
                                placeholder="Write Message Here ..."
                                autoComplete="message"
                                className={`block w-full rounded-md border-red-600 shadow-sm focus:border-secondary  sm:text-sm ${
                                  errors.message
                                    ? "border-2 border-red-600 focus:ring-secondary"
                                    : "focus:border-secondary focus:ring-secondary"
                                }`}
                              />
                              {errors.message && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"message-error"}
                                >
                                  {errors.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
                          >
                            Send
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="mt-2 sm:mt-2">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                      onClick={close}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
