import { useState } from "react";

export default function GiftCardPreviewImage({ file }) {
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };
  return (
    <div className="text-center">
      <img src={preview} alt="Preview" className="h-[200px] w-[420px] rounded-md" />
    </div>
  );
}
