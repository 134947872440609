import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../assets/logo.svg";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authServices from "../../services/auth.services";
import { clearError } from "../../slices/errorSlice";
import { setError } from "../../slices/errorSlice";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../components/root/AuthLayout";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";

export default function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.error);

  const handleSubmit = (password, newPassword) => {
    authServices
      .changePassword({
        email: user.email,
        password: password,
        newPassword: newPassword,
      })
      .then((response) => {
        console.log("response: ", response);
        if (response.status === 200) {
          navigate("/");
        } else {
          dispatch(setError({ message: response.data.message }));
        }
      })
      .catch((error) => {
        dispatch(setError({ message: error.response.data.message }));
      });
  };

  const ValidationSchema = Yup.object().shape({
    password: Yup.string().min(3, t("PasswordMin3")).required(t("Required")),
    newPassword: Yup.string().min(3, t("PasswordMin3")).required(t("Required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("PasswordMustMatch"))
      .required(t("Required")),
  });

  const handleCloseMessage = () => {
    dispatch(clearError());
  };

  return (
    <>
      <LanguageFloatingButton />
      <AuthLayout title={t("ChangePassword")}>
        <Formik
          initialValues={{
            password: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={ValidationSchema}
          onSubmit={(values) =>
            handleSubmit(values.password, values.newPassword)
          }
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className="space-y-6">
              <div className="mt-1">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Password")}
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <Field
                    name="password"
                    id="password"
                    type="password"
                    autoComplete="password"
                    className={`block w-full rounded-md border border-transparent pl-10 shadow-sm sm:text-sm ${
                      touched.password && errors.password
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
                {errors.password && (
                  <p
                    className="mt-1 px-4 text-sm text-red-600"
                    id={"code-error"}
                  >
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="mt-1">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("NewPassword")}
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <Field
                    name="newPassword"
                    id="newPassword"
                    type="password"
                    autoComplete="newPassword"
                    className={`block w-full rounded-md border border-transparent pl-10 shadow-sm sm:text-sm ${
                      touched.newPassword && errors.newPassword
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
                {errors.newPassword && (
                  <p
                    className="mt-1 px-4 text-sm text-red-600"
                    id={"code-error"}
                  >
                    {errors.newPassword}
                  </p>
                )}
              </div>
              <div className="mt-1">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("ConfirmPassword")}
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <Field
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    autoComplete="confirmPassword"
                    className={`block w-full rounded-md border border-transparent pl-10 shadow-sm sm:text-sm ${
                      touched.confirmPassword && errors.confirmPassword
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
                {errors.confirmPassword && (
                  <p
                    className="mt-1 px-4 text-sm text-red-600"
                    id={"code-error"}
                  >
                    {errors.confirmPassword}
                  </p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                >
                  {t("ChangePassword")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {error && (
          <div className="mt-6">
            <ErrorMessage message={error.message} close={handleCloseMessage} />
          </div>
        )}
      </AuthLayout>
    </>
  );
}
