import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { AcademicCapIcon } from "@heroicons/react/24/outline";
import { logout } from "../../slices/authSlice";
import * as gradeServices from "../../services/grade.services";
import * as classRoomServices from "../../services/classroom.services";
import Select from "react-tailwindcss-select";

import { useTranslation } from "react-i18next";

export default function SelectGradeClassRoomModal({
  open,
  close,
  returnSelectedGrade,
  returnSelectedClassRoom,
  action,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [grades, setGrades] = useState([{ label: "All", value: 0 }]);
  const [classRooms, setClassRooms] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedClassRoom, setSelectedClassRoom] = useState(null);
  useEffect(() => {
    getFirstGrades();
    // eslint-disable-next-line
  }, []);

  const getFirstGrades = async () => {
    setIsLoading(true);
    try {
      const response = await gradeServices.getGrades(1, 1000);
      console.log(response);
      if (response.status === 200) {
        if (response.data.grades.rows.length > 0) {
          setGrades([
            ...grades,
            ...response.data.grades.rows.map((grade) => ({
              label: grade.arabicName,
              value: grade.id,
            })),
          ]);

          //check last selected grade
          const lastSelectedGrade = JSON.parse(
            localStorage.getItem("lastSelectedGrade")
          );

          if (lastSelectedGrade) {
            setSelectedGrade(lastSelectedGrade);
            await getFirstClassRooms(lastSelectedGrade.value);
          } else {
            setSelectedGrade({
              label: response.data.grades.rows[0].arabicName,
              value: response.data.grades.rows[0].id,
            });
            await getFirstClassRooms(response.data.grades.rows[0].id);
          }
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getFirstClassRooms = async (gradeId) => {
    try {
      const response = await classRoomServices.getClassRooms(gradeId, 1, 1000);
      if (response.status === 200) {
        if (response.data.classRooms.rows.length > 0) {
          setClassRooms([
            { label: "All", value: 0 },
            ...response.data.classRooms.rows.map((classRoom) => ({
              label: classRoom.arabicName,
              value: classRoom.id,
            })),
          ]);

          setSelectedClassRoom({
            label: response.data.classRooms.rows[0].arabicName,
            value: response.data.classRooms.rows[0].id,
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getClassRooms = async (gradeId) => {
    try {
      if (gradeId !== 0) {
        const response = await classRoomServices.getClassRooms(
          gradeId,
          1,
          1000
        );
        if (response.status === 200) {
          if (response.data.classRooms.rows.length > 0) {
            setClassRooms([
              { label: "ALl", value: 0 },
              ...response.data.classRooms.rows.map((classRoom) => ({
                label: classRoom.arabicName,
                value: classRoom.id,
              })),
            ]);
            setSelectedClassRoom({
              label: response.data.classRooms.rows[0].arabicName,
              value: response.data.classRooms.rows[0].id,
            });
          }
          setIsLoading(false);
        }
      } else {
        setSelectedClassRoom({
          label: "All",
          value: 0,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handleGrades = (value) => {
    setSelectedGrade(value);
    getClassRooms(value.value);
  };

  const handleClasses = (value) => {
    setSelectedClassRoom(value);
  };

  const showReport = () => {
    if (action === "students") {
      window.open(
        `/reports/students/${selectedGrade.value}/${selectedClassRoom.value}`,
        "_blank",
        "rel=noopener noreferrer"
      );
    } else {
      window.open(
        `/reports/champions/${selectedGrade.value}/${selectedClassRoom.value}`,
        "_blank",
        "rel=noopener noreferrer"
      );
    }
  };
  //setAddedParent(response.data.user.userId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                      <AcademicCapIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {t("GradesAndClasses")}
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    {!isLoading && (
                      <div className="sm:col-span-3">
                        <Select
                          placeholder={"Select Grade"}
                          primaryColor={"amber"}
                          options={grades}
                          onChange={handleGrades}
                          value={selectedGrade}
                          isSearchable={true}
                        />
                      </div>
                    )}

                    {!isLoading && (
                      <div className="sm:col-span-3">
                        <Select
                          placeholder={"Select Class Room"}
                          primaryColor={"amber"}
                          options={classRooms}
                          onChange={handleClasses}
                          value={selectedClassRoom}
                          isSearchable={true}
                        />
                      </div>
                    )}
                  </div>

                  <div className="mt-2 sm:mt-2">
                    <button
                      type="button"
                      onClick={() => showReport()}
                      className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
                    >
                      {t("Continue")}
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
