import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout } from "../../slices/authSlice";
import { useDispatch, } from "react-redux";
import * as homeServices from "../../services/home.services";
import {
  Bars3Icon,
  BanknotesIcon,
  ChartBarIcon,
  HomeIcon,
  ArrowTopRightOnSquareIcon,
  UserIcon,
  UserGroupIcon,
  UsersIcon,
  XMarkIcon,
  AcademicCapIcon,
  RocketLaunchIcon,
  StarIcon,
  ClipboardDocumentIcon,
  TicketIcon,
  ArrowsRightLeftIcon,
  Square3Stack3DIcon
} from "@heroicons/react/24/outline";
import logo from "../../assets/logo.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [SideBarOpen, setSideBarOpen] = useState(false);
  const [howToUselink, setHowToUselink] = useState("https://b6lapp.com/");
  const { pathname } = useLocation();

  useEffect(() => {
    getHowToUseLink();
    // eslint-disable-next-line
  }, []);

  const getHowToUseLink = async () => {
    try {
      const response = await homeServices.getHowToUseLink();
      if (response.status === 200) {
        console.log(response.data);
        setHowToUselink(response.data.link);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const navigation = [
    { name: t("Dashboard"), href: "/", icon: HomeIcon, id: "dashboard" },
    {
      name: t("GradesAndClasses"),
      href: "/grades/list",
      icon: AcademicCapIcon,
      id: "grades",
      divider: false,
    },
    {
      name: t("Teachers"),
      href: "/teachers/list",
      href1: "/teachers/create",
      icon: UserIcon,
      id: "students",
      divider: false,
    },
    {
      name: t("Students"),
      href: "/students/index",
      icon: UserGroupIcon,
      id: "students",
      divider: false,
    },
    {
      name: t("ParentsManagement"),
      href: "/parents/email",
      icon: UsersIcon,
      id: "parents",
      divider: false,
    },
    {
      name: t("PricesAndSubscriptions"),
      href: "/subscription/prices",
      icon: BanknotesIcon,
      id: "price",
      divider: false,
    },
    {
      name: t("Rounds"),
      href: "/rounds/edit",
      icon: RocketLaunchIcon,
      id: "round",
      divider: false,
    },
    {
      name: t("GiftCards"),
      href: "/giftCards/list",
      icon: TicketIcon,
      id: "giftCards",
      divider: false,
    },
    {
      name: t("ExchangePoints"),
      href: "/exchange-points/list",
      icon: ArrowsRightLeftIcon,
      id: "exchange-points",
      divider: false,
    },
    {
      name: t("HowToUse"),
      href: howToUselink,
      icon: ArrowTopRightOnSquareIcon,
      id: "how",
      divider: false,
    },
    {
      name: t("Reports"),
      href: "/reports/index",
      icon: ChartBarIcon,
      id: "reports",
      divider: false,
    },
    // {
    //   name: t("configs"),
    //   href: "/",
    //   icon: undefined,
    //   id: "configs",
    //   divider: true,
    // },
    {
      name: t("PointsConfigs"),
      href: "/config/points",
      icon: StarIcon,
      id: "points",
      divider: false,
    },
    {
      name: t("attendanceConfgis"),
      href: "/config/attendance",
      icon: ClipboardDocumentIcon,
      id: "config",
      divider: false,
    },
    {
      name: t("ads"),
      href: "/ads/list",
      icon: Square3Stack3DIcon,
      id: "ads",
      divider: false,
    },
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <div>
        <Transition.Root show={SideBarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSideBarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSideBarOpen(false)}
                      >
                        <span className="sr-only">Close SideBar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 justify-center items-center px-4">
                      <img
                        className="h-20 w-auto"
                        src={logo}
                        alt="Whos champion school"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) =>
                        item.id === "how" ? (
                          <a
                            key={item.name}
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              pathname === item.href || pathname === item.href1
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                pathname === item.href ||
                                  pathname === item.href1
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-secondary",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )
                      )}
                    </nav>
                  </div>
                  <div className="bg-primary flex flex-shrink-0 border-t border-gray-200 p-4">
                    <Link to="/profile" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <div>
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-stone-700">
                              <span className="font-medium leading-none text-white">
                                {JSON.parse(localStorage.getItem("user"))
                                  .firstName.substring(0, 1)
                                  .toUpperCase()}
                                {JSON.parse(localStorage.getItem("user"))
                                  .midName.substring(0, 1)
                                  .toUpperCase()}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="ltr:ml-3 rtl:mr-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {JSON.parse(
                              localStorage.getItem("user")
                            ).firstName.toUpperCase()}
                            &nbsp;
                            {JSON.parse(
                              localStorage.getItem("user")
                            ).midName.toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force SideBar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static SideBar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* SideBar component, swap this element with another SideBar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 justify-center items-center px-4">
                <img
                  className="h-20 w-auto"
                  src={logo}
                  alt="Whos champion school"
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {navigation.map((item) =>
                  item.id === "how" ? (
                    <a
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classNames(
                        pathname === item.href || pathname === item.href1
                          ? "bg-primary text-gray-900"
                          : "text-gray-600 hover:bg-primary hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          pathname === item.href || pathname === item.href1
                            ? "text-gray-600"
                            : "text-gray-400 group-hover:text-gray-600",
                          "ltr:mr-3 rtl:ml-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ) : item.divider ? (
                    <div className="px-2 py-2 text-lg font-normal border-t border-gray-300">
                      {item.name}
                    </div>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        pathname === item.href || pathname === item.href1
                          ? "bg-primary text-gray-900"
                          : "text-gray-600 hover:bg-primary hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          pathname === item.href || pathname === item.href1
                            ? "text-gray-600"
                            : "text-gray-400 group-hover:text-gray-600",
                          "ltr:mr-3 rtl:ml-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  )
                )}
              </nav>
            </div>
            <div className="bg-amber-400 flex flex-shrink-0 border-t border-gray-200 p-4">
              <Link to="/profile" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-stone-700">
                      <span className="font-medium leading-none text-white">
                        {JSON.parse(localStorage.getItem("user"))
                          .firstName.substring(0, 1)
                          .toUpperCase()}
                        {JSON.parse(localStorage.getItem("user"))
                          .midName.substring(0, 1)
                          .toUpperCase()}
                      </span>
                    </span>
                  </div>
                  <div className="ltr:ml-3 rtl:mr-3">
                    <p className="text-sm font-medium text-white">
                      {JSON.parse(
                        localStorage.getItem("user")
                      ).firstName.toUpperCase()}
                      &nbsp;
                      {JSON.parse(
                        localStorage.getItem("user")
                      ).midName.toUpperCase()}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-white">
                      {t("ViewProfile")}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col lg:pl-64">
          <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSideBarOpen(true)}
            >
              <span className="sr-only">Open SideBar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
