import React, { Fragment } from "react";
import {
  ChevronRightIcon,
  HomeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as teacherServices from "../../services/teacher.services";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import PagesHeaderNav from "../../components/common/PagesHeaderNav";
import ClassRoomsTable from "../../components/teacher/ClassRoomsTable";
import SubjectsTable from "../../components/teacher/SubjectsTable";

export default function TeacherDetails() {
  let { id } = useParams();

  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const { error } = useSelector((state) => state.error);
  const [data, setData] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const pages = [
    { name: t("Teachers"), href: "/teachers/list", current: false },
    { name: t("TeacherDetails"), href: "#", current: true },
  ];

  useEffect(() => {
    teacherServices
      .getTeacher(id)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response.status === 200) {
          setData(response.data.teacher);
          setIsLoading(false);
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <PagesHeaderNav page={page} />
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("TeacherDetails")}
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-dark bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dark focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => navigate(`/teacher/edit/${id}`)}
                >
                  <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="ltr:ml-3 rtl:mr-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            {!isLoading && (
              <div className="bg-white border border-gray-200 rounded-lg shadow-lg">
                <dl>
                  <div className=" px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("FirstName")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.user.firstName}
                    </dd>

                    <dt className="text-sm font-medium text-gray-500">
                      {t("LastName")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.user.midName}
                    </dd>
                  </div>
                  <div className=" px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.user.email}
                    </dd>

                    <dt className="text-sm font-medium text-gray-500">
                      {t("PhoneNumber")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.user.phoneNumber}
                    </dd>
                  </div>
                </dl>
              </div>
            )}

            <div className="bg-white  border border-gray-200 rounded-lg shadow-lg px-4 py-8 my-12">
              <h2 className=" mb-3 ltr:left-4 rtl:right-4   bg-white px-1 text-xl font-semibold text-gray-900">
                {t("cLassRooms")}
              </h2>
              <ClassRoomsTable
                data={data.assignedClassRooms}
                isLoading={isLoading}
              />
            </div>

            <div className="bg-white  border border-gray-200 rounded-lg shadow-lg px-4 py-8 my-12">
              <h2 className=" mb-3 ltr:left-4 rtl:right-4   bg-white px-1 text-xl font-semibold text-gray-900">
                {t("subjects")}
              </h2>
              <SubjectsTable id={id} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
