import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";

import * as studentServices from "../../services/student.services";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import { Switch } from "@headlessui/react";
import Select from "react-tailwindcss-select";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";
import CreateParentModal from "../../components/parents/CreateParentModal";
import LoadingData from "../../components/common/LoadingData";

export default function EditStudent() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedClassRooms, setSelectedClassRooms] = useState(null);
  const [classRooms, setClassRooms] = useState([]);
  const [createParent, setCreateParent] = useState(false);
  const [newParentSuccess, setNewParentSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const { t } = useTranslation();
  let { gradeId } = useParams();
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Students"), href: `/students/list/${gradeId}`, current: false },
    { name: t("EditStudent"), href: "#", current: true },
  ];

  useEffect(() => {
    setIsLoading(true);
    studentServices
      .getStudent(id)
      .then((response) => {
        console.log("🚀 ~ file: Edit.js:51 ~ .then ~ response:", response);
        if (response.status === 200) {
          setData(response.data.student);
          setSelectedClassRooms({
            value: response.data.student.classRoom.id,
            label: response.data.student.classRoom.arabicName,
            disabled: false,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    getClassRooms(1);
    // eslint-disable-next-line
  }, []);

  const update = async (inputs) => {
    console.log("🚀 ~ file: Edit.js:69 ~ update ~ inputs:", inputs);
    console.log(inputs);
    studentServices
      .updateStudent(id, inputs)
      .then((response) => {
        console.log(response);
        navigate(`/students/list/${gradeId}`);
      })
      .catch((error) => {
        setIsLoading(false);
        setError("ThereIsEmailOrPhoneAlreadyTakenOrSomethingWentWroing");
        setShowError(true);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const getClassRooms = async () => {
    setIsLoading(true);
    try {
      const response = await studentServices.getClassRooms(1);
      if (response.status === 200) {
        const options = response.data.grades.map((grade) => ({
          label: grade.arabicName,
          options: grade.classrooms.map((classRoom) => ({
            value: classRoom.id,
            label: classRoom.arabicName,
          })),
        }));

        setClassRooms(options);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handleChange = (value) => {
    console.log(value);
    setSelectedClassRooms(value);
  };

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    midName: Yup.string().required(t("Required")),
    email: Yup.string().required(t("Required")),
    phoneNumber: Yup.string().required(t("Required")),
    identityNumber: Yup.string().required(t("Required")),
    gender: Yup.string().required(t("Required")),
    birthDate: Yup.string().required(t("Required")),
  });

  const handleCancel = () => {
    navigate(-1);
    // navigate(`/students/list/${gradeId}`);
  };

  const setAddedParent = async (userId) => {
    console.log(userId);
    setNewParentSuccess(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setNewParentSuccess(false);
    }, 5000);
  };

  return (
    <>
      <FailedAlert
        show={showError}
        message={t(error)}
        close={() => setShowError(false)}
      />
      <SuccessAlert
        show={newParentSuccess}
        message={t("NewParentsAddedAearchAgain")}
      />
      <CreateParentModal
        open={createParent}
        setAddedParent={setAddedParent}
        close={() => setCreateParent(false)}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <LoadingData />}
        {!isLoading && data && (
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <Formik
                initialValues={{
                  firstName: data?.user?.firstName ?? "",
                  midName: data?.user?.midName ?? "",
                  email: data?.user?.email ?? "",
                  identityNumber: data?.user?.identityNumber ?? "",
                  phoneNumber: data?.user?.phoneNumber ?? "",
                  birthDate: data?.user?.birthDate ?? new Date(),
                  gender: data?.user?.gender ?? "male",
                  parentPhoneNumber:
                    data?.studentParent?.parent?.phoneNumber ?? "",
                  student: {
                    classRoomId: "",
                  },
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={ValidationSchema}
                onSubmit={(values) => update(values)}
              >
                {({ touched, errors, values, setTouched, setFieldValue }) => (
                  <Form>
                    <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                      <h3 className="text-xl font-semibold text-gray-900">
                        {t("UpdateStudentDetails")}
                      </h3>
                      <div className="mt-2 flex sm:mt-0 sm:ml-4">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                          onClick={handleCancel}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                    <div className="border border-gray-200 rounded-lg shadow-lg p-10 bg-white">
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("FirstName")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              autoComplete="firstName"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                errors.firstName && errors.firstName
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                              }`}
                            />
                            {touched.firstName && errors.firstName && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"firstName-error"}
                              >
                                {errors.firstName}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="midName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("LastName")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="midName"
                              id="midName"
                              autoComplete="midName"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                errors.midName && errors.midName
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                              }`}
                            />
                            {touched.midName && errors.midName && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"password-error"}
                              >
                                {errors.midName}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="phoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("PhoneNumber")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              autoComplete="phoneNumber"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                errors.phoneNumber && errors.phoneNumber
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                              }`}
                            />
                            {touched.phoneNumber && errors.phoneNumber && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"phoneNUmber-error"}
                              >
                                {errors.phoneNumber}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("Email")}
                          </label>
                          <div className="mt-1">
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                errors.email && errors.email
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                              }`}
                            />
                            {touched.email && errors.email && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"email-error"}
                              >
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("NewPassword")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              autoComplete="password"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm  focus:ring-secondary`}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="identityNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("IdentityNumber")}
                          </label>
                          <div className="mt-1">
                            <Field
                              id="identityNumber"
                              name="identityNumber"
                              type="text"
                              autoComplete="identityNumber"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                errors.identityNumber && errors.identityNumber
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                              }`}
                            />
                            {touched.identityNumber &&
                              errors.identityNumber && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"identityNumber-error"}
                                >
                                  {errors.identityNumber}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="birthDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("BirthDate")}
                          </label>
                          <div className="mt-1">
                            <CustomDatePicker
                              selected={
                                values.birthDate
                                  ? new Date(values.birthDate)
                                  : new Date()
                              }
                              onChange={(newValue) => {
                                setTouched({
                                  ...touched,
                                  birthDate: true,
                                });
                                setFieldValue(
                                  "birthDate",
                                  newValue
                                    ? newValue.toISOString()
                                    : new Date().toISOString
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="parentPhoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("ParentsContacts")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="parentPhoneNumber"
                              id="parentPhoneNumber"
                              autoComplete="parentPhoneNumber"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                errors.parentPhoneNumber &&
                                errors.parentPhoneNumber
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                              }`}
                            />
                            {touched.parentPhoneNumber &&
                              errors.parentPhoneNumber && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"student-studentParent-userId-error"}
                                >
                                  {errors.parentPhoneNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("Gender")}
                          </label>
                          <div className="mt-1">
                            <Switch
                              checked={values.gender === "male"}
                              onChange={(newValue) => {
                                if (newValue) {
                                  setTouched({
                                    ...touched,
                                    gender: true,
                                  });
                                  setFieldValue("gender", "male");
                                } else {
                                  setTouched({
                                    ...touched,
                                    gender: true,
                                  });
                                  setFieldValue("gender", "female");
                                }
                              }}
                              className="bg-gray-200 relative inline-flex flex-shrink-0 h-8 w-60 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary shadow-sm"
                            >
                              <span
                                className={`
                                    ${
                                      values.gender === "male"
                                        ? "rtl:-translate-x-28 ltr:translate-x-28"
                                        : "translate-x-0"
                                    }
                                    pointer-events-none relative inline-block h-8 w-32 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                              >
                                <span
                                  className={`
                                      ${
                                        values.gender === "male"
                                          ? "opacity-0 ease-out duration-100"
                                          : "opacity-100 ease-in duration-200"
                                      }
                                      absolute inset-0 h-full w-full bg-primary text-white text-sm flex items-center justify-center transition-opacity rounded-2xl`}
                                  aria-hidden="true"
                                >
                                  {t("Female")}
                                </span>
                                <span
                                  className={`
                                      ${
                                        values.gender === "male"
                                          ? "opacity-100 ease-in duration-200"
                                          : "opacity-0 ease-out duration-100"
                                      }
                                      absolute inset-0 h-full w-full bg-gray-400 text-white text-sm flex items-center justify-center transition-opacity rounded-2xl
                                    `}
                                  aria-hidden="true"
                                >
                                  {t("Male")}
                                </span>
                              </span>
                            </Switch>
                          </div>
                        </div>
                      </div>
                      <div className="relative border border-gray-200 rounded-lg shadow-sm px-4 py-8 my-8">
                        <h2 className="absolute -top-3 ltr:left-4 rtl:right-3 -mt-px inline-block bg-white px-1 text-xl font-semibold text-gray-900">
                          {t("ChangeCLassRoom")}
                        </h2>
                        <div className="relative grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="sm:col-span-12">
                            {!isLoading && (
                              <Select
                                name={"student.classRoomId"}
                                value={selectedClassRooms}
                                onChange={(value) => {
                                  //Show selections in the input field
                                  handleChange(value);
                                  //Add values to inputs
                                  setFieldValue(
                                    "student.classRoomId",
                                    value.value
                                  );
                                  // values.students[
                                  //   studentIdx
                                  // ].student.classRoomId =
                                  //   value.value;
                                }}
                                options={classRooms}
                                isMultiple={false}
                                formatGroupLabel={(data) => (
                                  <div
                                    className={`py-2 text-xs flex items-center justify-between`}
                                  >
                                    <span className="font-bold ">
                                      {data.label}
                                    </span>
                                    <span className="h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                      {data.options.length}
                                    </span>
                                  </div>
                                )}
                                formatOptionLabel={(data) => (
                                  <li
                                    className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                      !data.isSelected
                                        ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                        : `bg-amber-100 text-amber-600`
                                    }`}
                                  >
                                    {data.label}
                                  </li>
                                )}
                              />
                            )}
                          </div>
                        </div>
                        {errors.student?.classRoomId && (
                          <p
                            className="mt-1 px-4 text-sm text-red-600"
                            id={"student-classRoomId-error"}
                          >
                            {errors.student.classRoomId}
                          </p>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
