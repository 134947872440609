import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getSubjectsGroup = (subjectId, page, limit) => {
  return axios.get(`${base.apiUrl}group/${subjectId}/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
};

const getSubjectStudents = (groupId, page, limit) => {
  return axios.get(`${base.apiUrl}group/${groupId}/students/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
};

export { getSubjectsGroup, getSubjectStudents };
