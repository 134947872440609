import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisHorizontalIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import avatar from "../../assets/avatar.png";
import { useTranslation } from "react-i18next";

export const StudentCard = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <center ref={ref}>
      <div className="mt-10 w-1/2 overflow-hidden rounded-xl border border-gray-200">
        <div className=" flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          {/* <img
            alt="student"
            src={avatar}
            className="h-12 w-12 flex-none rounded-full bg-white object-cover ring-1 ring-gray-900/10"
          /> */}
          <UserCircleIcon className="w-12 h-12 bg-gray-900" />
          <div className="text-sm font-medium leading-6 text-gray-900">
            {props.data.firstName + " " + props.data.midName}
          </div>
        </div>
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">{t("IdentityNumber")} </dt>
            <dd className="text-gray-700">
              {props.data.identityNumber ?? "-"}
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">{t("email")} </dt>
            <dd className="text-gray-700">{props.data.email}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">{t("phoneNumber")}</dt>
            <dd className="text-gray-700">{props.data.phoneNumber}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500"> </dt>
            <dd className="text-gray-700"></dd>
          </div>
        </dl>
      </div>
    </center>
  );
});
