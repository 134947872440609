import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getSubject = (id) => {
  return axios.get(`${base.apiUrl}subject/${id}`, {
    headers: headers.authHeader(),
  });
};

const getSubjects = (classRoomId, page, limit) => {
  return axios.get(`${base.apiUrl}subject/${classRoomId}/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
};

const createSubjects = (body) => {
  return axios.post(`${base.apiUrl}subject/`, body, {
    headers: headers.authHeader(),
  });
};

const updateSubjects = (id, body) => {
  return axios.put(`${base.apiUrl}subject/${id}`, body, {
    headers: headers.authHeader(),
  });
};

const deleteSubject = (id, body) => {
  return axios.delete(`${base.apiUrl}subject/${id}`, {
    headers: headers.authHeader(),
  });
};

const getTeacherSubjects = (teacherId, page, limit) => {
  return axios.get(
    `${base.apiUrl}subject/teacher/${page}/${limit}?teacherId=${teacherId}`,
    {
      headers: headers.authHeader(),
    }
  );
};

export {
  getSubject,
  getSubjects,
  createSubjects,
  updateSubjects,
  deleteSubject,
  getTeacherSubjects,
};
