import logo from "../../assets/logo.svg";
import { useTranslation } from "react-i18next";

// import Container from "./Container";
// import Breadcrumb from "../Breadcrumb";

const AuthLayout = ({ title, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="min-h-screen bg-gradient-to-br from-gray-shade to-secondary">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-28 w-auto" src={logo} alt="" />
            <h2 className="mt-3 text-center text-3xl font-bold tracking-tight text-gray-100 drop-shadow-md">
              {title}
            </h2>
          </div>
          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white/25 py-8 px-4 sm:px-10 shadow-lg rounded-lg">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
