import { useRef } from "react";
import Body from "./Body";
import ReportHeader from "./ReportHeader";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
// import Container from "./Container";
// import Breadcrumb from "../Breadcrumb";

const ReportLayout = ({ title, children }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="min-h-screen bg-slate-50">
      <ReportHeader reportTitle={title} doPrint={handlePrint} />
      <div ref={componentRef} id="report" className="flex flex-1 flex-col ">
        <Body>{children}</Body>
      </div>
    </div>
  );
};

export default ReportLayout;
