import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import femaleImg from "../../assets/member_female.png";
import maleImg from "../../assets/member_male.png";
import * as statsServices from "../../services/stats.services";
import { apiUrl } from "../../services/base";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { UsersIcon, UserIcon } from "@heroicons/react/24/solid";
import NoData from "../../components/common/NoData";

const RoundStatistics = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isGroupView, setIsGroupView] = useState(false);

  const { t } = useTranslation();

  const classRoomId = new URLSearchParams(window.location.search).get(
    "classRoomId"
  );
  const roundId = new URLSearchParams(window.location.search).get("roundId");

  useEffect(() => {
    getStats();
  }, [classRoomId, roundId, isGroupView]);

  // 17, 104398
  // classRoomId=17&roundId=104398
  const getStats = async () => {
    console.log("🚀 ~ RoundStatistics ~ isGroupView:", isGroupView)
    if (classRoomId && roundId) {
      await statsServices
        .getRoundStatistics(classRoomId, roundId, isGroupView)
        .then((response) => {
          if (response.status === 200) {
            setStats(response.data.students);
            setLoading(false);
          }
          console.log("🚀 ~ .then ~ response.data.students:", response)
        })
        .catch((error) => {
          console.log("🚀 ~ awaitstatsServices.getStats ~ error:", error);
          setLoading(false);
        });
    }
  };

  const handleToggleView = () => {
    setIsGroupView((prev) => !prev);
  };

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6 text-xl font-semibold">
        {/* Content goes here */}
        {/* We use less vertical padding on card headers on desktop than on body sections */}
        {t("studentStatistics")}
      </div>
      {!loading && stats ? (
        <div className="px-4 py-5 sm:p-6 gap-4 flex flex-row-reverse justify-center overflow-x-auto mx-6">
          {stats.length > 0 ? (
            stats.map((item, index) => (
              <div
                key={index}
                className="h-screen w-28 bg-stone-100 shadow-inner rounded-md flex flex-col items-center gap-2 p-4"
              >
                <div className="relative">
                  <span className="flex h-14 w-14 items-center justify-center rounded-full bg-white outline outline-secondary outline-offset-2 outline-2">
                    <span className="font-medium text-xl leading-none text-white">
                      {item.user.avatar !== null ? (
                        <img
                          src={`${apiUrl}user-image/${item.user.avatar}`}
                          alt=""
                          className="h-14 w-14 rounded-full"
                        />
                      ) : item.user.gender === "female" ? (
                        <img src={femaleImg} alt="" className="h-12 w-12" />
                      ) : (
                        <img src={maleImg} alt="" className="h-12 w-12" />
                      )}
                    </span>
                  </span>
                  <span className="flex h-6 w-6 items-center justify-center rounded-full bg-secondary absolute right-0 -top-2">
                    <span className="font-medium leading-none text-white">
                      {index + 1}
                    </span>
                  </span>
                </div>
                <p>{item.user.firstName}</p>
                <span className="flex h-11 w-11 items-center justify-center rounded-full bg-stone-200 border border-stone-300 text-lg font-semibold">
                  <span className="text-black">+{item.totalPoints}</span>
                </span>
                <div className="mt-4">
                  {item.points.map((point, index) => (
                    <div key={index} className="mt-1">
                      <div className="flex flex-col items-center justify-center gap-1">
                        <span className="flex h-9 w-9 items-center justify-center rounded-full outline outline-stone-200 outline-offset-2 outline-2">
                          <img
                            src={`${apiUrl}feeling-type-image/${point.feelingFlag?.type === "attendance" ? "checklist.png" : point.feelingFlag?.feelingType?.image}`}
                            alt=""
                            className="rounded-full"
                          />
                        </span>
                        <span>{point.totalPoints}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="px-4 py-5 sm:p-6 gap-4 flex justify-center overflow-x-auto">
              <NoData />
            </div>
          )}
        </div>
      ) : (
        <div className="px-4 py-5 sm:p-6 gap-4 flex justify-center overflow-x-auto">
          <LoadingSpinner />
        </div>
      )}
      <button
        onClick={handleToggleView}
        className="fixed bottom-10 right-10 bg-yellow-400 text-white rounded-full p-4 shadow-lg"
      >
        {isGroupView ? <UserIcon className="h-6 w-6" /> : <UsersIcon className="h-6 w-6" />}
      </button>
    </div>
  );
};

export default RoundStatistics;
