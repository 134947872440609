import React, { Fragment } from "react";
import {
  HomeIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  PencilIcon,
  DocumentIcon,
  TrashIcon,
  XMarkIcon,
  CheckIcon,
  CalendarDaysIcon,
  BookOpenIcon,
  TableCellsIcon,
} from "@heroicons/react/20/solid";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as gradeServices from "../../services/grade.services";
import * as classRoomServices from "../../services/classroom.services";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import { Formik, Form, Field } from "formik";
import SuccessAlert from "../../components/common/SuccessAlert";

export default function GradeDetails() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isClassesLoading, setIsClassesLoading] = useState(true);
  const { error } = useSelector((state) => state.error);
  const [grade, setGradeData] = useState([]);
  const [editGrade, setEditGrade] = useState(false);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteClassRoomAlert, setDeleteClassRoomAlert] = useState(false);
  const [deletedClassRoom, setDeletedClassRoom] = useState([]);
  const [editSuccess, setEditSuccess] = useState(false);
  const [editedClassRoomRow, setEditedClassRoomRow] = useState(0);

  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Grades"), href: "/grades/list", current: false },
    { name: t("GradeDetails"), href: "#", current: true },
  ];

  useEffect(() => {
    gradeServices
      .getGrade(id)
      .then((response) => {
        if (response.status === 200) {
          setGradeData(response.data.grade);
          setIsLoading(false);
          getItems(1);
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, [id]);

  const handleEdit = () => {
    navigate(`/grade/edit/${id}`);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await gradeServices.deleteGrade(id);
      if (response.status === 200) {
        console.log("response: ", response.data);
        navigate("/grades/list");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const saveGradeChange = async (values) => {
    gradeServices
      .updateGrade(values.id, values)
      .then((response) => {
        setEditSuccess(true);
        setEditGrade(false);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setEditSuccess(false);
        }, 3000);

        //update page data
        gradeServices.getGrade(id).then((response) => {
          if (response.status === 200) {
            setGradeData(response.data.grade);
            setIsLoading(false);
            getItems(1);
          }
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const getItems = async (page) => {
    setIsClassesLoading(true);
    try {
      const response = await classRoomServices.getClassRooms(id, page, limit);
      if (response.status === 200) {
        setRows(response.data.classRooms.rows);
        setCount(response.data.classRooms.count);
        setIsClassesLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsClassesLoading(true);
    try {
      const response = await classRoomServices.getClassRooms(id, page, limit);
      if (response.status === 200) {
        setRows(response.data.classRooms.rows);
        setLimit(limit);
        setIsClassesLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const saveClassRoomChange = (inputs) => {
    classRoomServices
      .updateClassRoom(inputs.id, inputs)
      .then((response) => {
        console.log(response);
        setEditSuccess(true);
        editedClassRoomRow(0);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setEditSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const alertDeleteClassRoom = async (id) => {
    setDeletedClassRoom(id);
    setDeleteClassRoomAlert(true);
  };

  const deleteClassRoom = async () => {
    setIsLoading(true);
    setDeleteClassRoomAlert(false);
    try {
      const response = await classRoomServices.deleteClassRoom(
        deletedClassRoom
      );
      if (response.status === 200) {
        console.log("response: ", response.data);
        getItems(1);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Name")}
        </div>
      ),
      selector: (row) => (
        <Formik
          initialValues={{
            id: row.id ?? 0,
            instituteGradeId: row.instituteGradeId ?? "",
            arabicName: row.arabicName ?? "",
            englishName: row.englishName ?? "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => saveClassRoomChange(values)}
        >
          {({ touched, errors, setFieldValue, setTouched, values }) => (
            <Form>
              <Field
                type="text"
                name="arabicName"
                id="arabicName"
                onClick={() => {
                  setEditedClassRoomRow(row.id);
                }}
                className="block w-full rounded-md border-0 focus:border-2 focus:border-secondary border-gray-400 focus:ring-secondary  sm:text-sm"
              />
              <button id={`class-${row.id}`} hidden type="submit" />
            </Form>
          )}
        </Formik>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      selector: (row) => (
        <>
          {editedClassRoomRow === row.id ? (
            <div className="px-1">
              <button
                type="button"
                className="w-16 inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-green-500"
                onClick={() =>
                  document.getElementById(`class-${row.id}`).click()
                }
              >
                <span>
                  <CheckIcon
                    className="h-5 w-5 "
                    stroke="currentColor"
                    aria-hidden="true"
                  ></CheckIcon>
                </span>
              </button>
              <button
                type="button"
                className="w-16 inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
                onClick={() => setEditedClassRoomRow(0)}
              >
                <span>
                  <XMarkIcon
                    className="h-5 w-5 "
                    stroke="currentColor"
                    aria-hidden="true"
                  ></XMarkIcon>
                </span>
              </button>
            </div>
          ) : (
            <>
              <button
                className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
                onClick={() => navigate(`/schedule/${row.id}`)}
              >
                <span>
                  <TableCellsIcon
                    className="h-5 w-5"
                    stroke="currentColor"
                    aria-hidden="true"
                  ></TableCellsIcon>
                </span>
              </button>
              <button
                className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
                onClick={() => navigate(`/attendance/${row.id}`)}
              >
                <span>
                  <CalendarDaysIcon
                    className="h-5 w-5"
                    stroke="currentColor"
                    aria-hidden="true"
                  ></CalendarDaysIcon>
                </span>
              </button>
              <button
                className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
                onClick={() => navigate(`/subjects/${row.id}`)}
              >
                <span>
                  <BookOpenIcon
                    className="h-5 w-5"
                    stroke="currentColor"
                    aria-hidden="true"
                  ></BookOpenIcon>
                </span>
              </button>
              <button
                className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
                onClick={() =>
                  navigate(`/class-room/edit/${row.instituteGradeId}/${row.id}`)
                }
              >
                <span>
                  <PencilIcon
                    className="h-5 w-5"
                    stroke="currentColor"
                    aria-hidden="true"
                  ></PencilIcon>
                </span>
              </button>
              <button
                className="inline-flex m-1 items-center p-3  justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
                onClick={() =>
                  navigate(
                    `/class-room/details/${row.instituteGradeId}/${row.id}`
                  )
                }
              >
                <span>
                  <DocumentIcon
                    className="h-5 w-5 "
                    stroke="currentColor"
                    aria-hidden="true"
                  ></DocumentIcon>
                </span>
              </button>
              <button
                className="inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
                onClick={() => alertDeleteClassRoom(row.id)}
              >
                <span>
                  <TrashIcon
                    className="h-5 w-5 "
                    stroke="currentColor"
                    aria-hidden="true"
                  ></TrashIcon>
                </span>
              </button>
            </>
          )}
        </>
      ),
      sortable: true,
      width: "400px",
    },
  ];

  const handleAddClasses = async () => {
    navigate(`/class-room/create/${id}`);
  };

  return (
    <>
      <SuccessAlert show={editSuccess} message={t("changesSaved")} />
      <DeleteItemAlert
        show={deleteAlert}
        confirm={handleDelete}
        cancel={() => setDeleteAlert(false)}
      />
      <DeleteItemAlert
        show={deleteClassRoomAlert}
        confirm={deleteClassRoom}
        cancel={() => setDeleteClassRoomAlert(false)}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-xl font-semibold text-gray-900">
                {t("GradeDetails")}
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-dark bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dark focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => handleEdit(id)}
                >
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="ltr:ml-3 rtl:mr-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => setDeleteAlert(true)}
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            {!isLoading && (
              <Formik
                initialValues={{
                  id: grade.id,
                  instituteId: grade.instituteId ?? "",
                  arabicName: grade.arabicName ?? "",
                  englishName: grade.englishName ?? "",
                  startDate: grade.startDate ?? "",
                  endDate: grade.endDate ?? "",
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => saveGradeChange(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              {t("ArabicName")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                              <Field
                                type="text"
                                name="arabicName"
                                id="arabicName"
                                onClick={() => {
                                  setEditGrade(true);
                                }}
                                className={`block w-full rounded-md ${
                                  grade.arabicName !== ""
                                    ? "border-0 border-gray-400"
                                    : "border-1 border-amber-400"
                                } focus:border-1 focus:border-secondary  focus:ring-secondary  sm:text-sm`}
                              />
                            </dd>
                            {editGrade && (
                              <div className=" rtl:text-left ltr:text-right">
                                <button
                                  type="button"
                                  className="w-16 inline-flex m-1 items-center p-2 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-green-500"
                                  onClick={() =>
                                    document.getElementById(grade.id).click()
                                  }
                                >
                                  <span>
                                    <CheckIcon
                                      className="h-4 w-4 "
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    ></CheckIcon>
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  className="w-16 inline-flex m-1 items-center p-2 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
                                  onClick={() => setEditGrade(false)}
                                >
                                  <span>
                                    <XMarkIcon
                                      className="h-4 w-4 "
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    ></XMarkIcon>
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              {t("EnglishName")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                              <Field
                                type="text"
                                name="englishName"
                                id="englishName"
                                onClick={() => {
                                  setEditGrade(true);
                                }}
                                className={`block w-full rounded-md ${
                                  grade.englishName !== ""
                                    ? "border-0 border-gray-400"
                                    : "border-1 border-amber-400"
                                } focus:border-1 focus:border-secondary  focus:ring-secondary  sm:text-sm`}
                              />
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                    <button id={grade.id} hidden type="submit" />
                  </Form>
                )}
              </Formik>
            )}

            <div className="sm:flex sm:items-center my-6 px-8">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  {t("ClassRooms")}
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
                  onClick={handleAddClasses}
                >
                  <PlusIcon className="ml-2 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("AddClass")}
                </button>
              </div>
            </div>
            <div className="mt-8 px-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div className="min-w-full divide-y divide-gray-300">
                      <div className="divide-y divide-gray-200 bg-white">
                        <DataTable
                          columns={columns}
                          data={rows}
                          progressPending={isLoading}
                          Header
                          pagination
                          paginationServer
                          highlightOnHover
                          paginationTotalRows={count}
                          onChangeRowsPerPage={getNextItems}
                          onChangePage={getItems}
                          noDataComponent={<NoData />}
                          progressComponent={<LoadingData />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
