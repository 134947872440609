import React, { useEffect, useState } from "react";
import * as subjectsServices from "../../services/subject.services";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import NoData from "../common/NoData";
import LoadingData from "../common/LoadingData";
import { useNavigate } from "react-router-dom";

const SubjectsTable = ({ id }) => {
  const [subjects, setSubjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  useEffect(() => {
    getSubjects(1);
    // eslint-disable-next-line
  }, [id]);

  const getSubjects = async (page) => {
    await subjectsServices
      .getTeacherSubjects(id, page, limit)
      .then((response) => {
        setSubjects(response.data.rows);
        setCount(response.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getNextItems = async (page, limit) => {
    setIsLoading(true);
    await subjectsServices
      .getTeacherSubjects(id, page, limit)
      .then((response) => {
        setSubjects(response.data.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("classRoomName")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {i18n.language === "en" ? row.englishName : row.arabicName}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 px-4">
          {t("subjects")}
        </div>
      ),
      selector: (row) => (
        <div className="flex flex-wrap gap-1.5 py-1 px-4">
          {row.subjects.map((subject) => (
            <p
              key={subject.id}
              onClick={() =>
                navigate(`/subject/${subject.id}/groups?teacherId=${id}`)
              }
              className="text-white bg-primary font-semibold p-1 px-2 border-none rounded-xl hover:cursor-pointer hover:transform hover:scale-110 transition-transform"
            >
              {subject.name}
            </p>
          ))}
        </div>
      ),
      width: "700px",
      sortable: true,
    },
  ];

  return (
    <div>
      {subjects && (
        <DataTable
          columns={columns}
          data={subjects}
          progressPending={isLoading}
          Header
          pagination
          paginationServer
          highlightOnHover
          paginationTotalRows={count}
          onChangeRowsPerPage={getNextItems}
          onChangePage={getSubjects}
          noDataComponent={<NoData />}
          progressComponent={<LoadingData />}
        />
      )}
    </div>
  );
};

export default SubjectsTable;
