import React from "react";
import { AtSymbolIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import SendEmailModal from "../contact/SendEmailModal";
import SendNotificationModal from "../contact/SendNotificationModal";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

function ParentInfo({ data }) {
  const [sendEmail, setSendEmail] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <SendEmailModal
        open={sendEmail}
        close={() => setSendEmail(false)}
        email={data.email}
      />
      <SendNotificationModal
        open={sendNotification}
        close={() => setSendNotification(false)}
        phoneNumber={data.phoneNumber}
        userId={data.id}
      />
      <div className="mt-10 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {t("ParentInformation")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {t("PersonalDetails")}
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("FullName")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.firstName + " " + data.midName}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t("Code")}</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.code}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("Email")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.email}
                {data.emailConfirmed === true ? (
                  <span className="mx-2 inline-flex items-center text-xs font-medium bg-green-100  text-green-55 py-1 rounded-full">
                    {t("Confirmed")}
                  </span>
                ) : (
                  <span className="mx-2 inline-flex items-center text-xs font-medium bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                    {t("NotConfirmedYet")}
                  </span>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("PhoneNumber")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.phoneNumber}
                {data.phoneNumberConfirmed === true ? (
                  <span className="mx-2 inline-flex items-center text-xs font-medium bg-green-100  text-green-500 px-2.5 py-1 rounded-full">
                    {t("Confirmed")}
                  </span>
                ) : (
                  <span className="mx-2 inline-flex items-center text-xs font-medium bg-red-100 rounded-fullbg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                    {t("NotConfirmedYet")}
                  </span>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("Gender")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{data.gender}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("RegistrationDate")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {format(new Date(data?.createdAt), "MMMM d, y")}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                {t("ParentsManagements")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  {/* <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <AtSymbolIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        {t("SendEmail")}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <button
                        onClick={() => setSendEmail(true)}
                        className="font-medium text-primary hover:text-amber-500"
                      >
                        {t("Send")}
                      </button>
                    </div>
                  </li> */}
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperAirplaneIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        {t("NotifyParents")}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <button
                        onClick={() => setSendNotification(true)}
                        className="font-medium text-primary hover:text-amber-500"
                      >
                        {t("Send")}
                      </button>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

export default ParentInfo;
