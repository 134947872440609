import { useTranslation } from "react-i18next";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";

export default function LoadingData() {
  const { t } = useTranslation();

  return (
    <div className="text-center pt-10 pb-10">
      <ButtonLoadingSpinner />
      <p className="mt-1 text-sm text-gray-500">{t("LoadingData")}</p>
    </div>
  );
}
