import React, { Fragment } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import * as gradeService from "../../services/grade.services";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
  TrashIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
// import * as Yup from "yup";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";

export default function CreateGrades() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const pages = [
    { name: t("Grades"), href: "/grades/list", current: false },
    { name: t("AddNewGrade"), href: "#", current: true },
  ];

  const options = [
    { value: "تمهيدي", label: "تمهيدي" },
    { value: "اولى ابتدائي", label: "اولى ابتدائي" },
    { value: "ثاني ابتدائي", label: "ثاني ابتدائي" },
    { value: "ثالث ابتدائي", label: "ثالث ابتدائي" },
    { value: "رابع ابتدائي", label: "رابع ابتدائي" },
    { value: "خامس ابتدائي", label: "خامس ابتدائي" },
    { value: "سادس ابتدائي", label: "سادس ابتدائي" },
    { value: "اول متوسط", label: "اول متوسط" },
    { value: "ثاني متوسط", label: "ثاني متوسط" },
    { value: "ثالث متوسط", label: "ثالث متوسط" },
    { value: "اول ثانوي", label: "اول ثانوي" },
    { value: "ثاني ثانوي", label: "ثاني ثانوي" },
    { value: "ثالث ثانوي", label: "ثالث ثانوي" },
  ];

  const handleSubmit = (inputs) => {
    console.log(inputs);
    gradeService
      .createGradesWithClassrooms(inputs)
      .then((response) => {
        if (response.status === 201) navigate("/grades/list");
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Create grades.js:39 ~ handleSubmit ~ error",
          error
        );
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <Formik
              initialValues={{
                grades: [
                  {
                    instituteId: localStorage.getItem("instituteId"),
                    arabicName: "",
                    englishName: "",
                    classrooms: [
                      {
                        arabicName: "",
                        englishName: "",
                      },
                    ],
                  },
                ],
              }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ touched, errors, values, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-xl font-semibold text-gray-900">
                      {t("AddNewGrade")}
                    </h3>
                    <div className="mt-2 flex sm:mt-0 ltr:sm:ml-4 rtl:sm:mr-4">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        onClick={handleCancel}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                  <FieldArray
                    name="grades"
                    render={(gradesArrayHelpers) => (
                      <>
                        <div>
                          {values.grades && (
                            <div>
                              {values.grades.map((grade, gradeIdx) => (
                                <div
                                  key={gradeIdx.toString()}
                                  className="mt-2 border rounded-lg shadow-lg px-4 py-8 bg-white"
                                >
                                  <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor={
                                          "grades-" + gradeIdx + "-arabicName"
                                        }
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("ArabicName")}
                                      </label>

                                      <div className="mt-1">
                                        <Creatable
                                          name={
                                            "grades[" +
                                            gradeIdx +
                                            "].arabicName"
                                          }
                                          onChange={(value) => {
                                            if (value !== null)
                                              setFieldValue(
                                                "grades[" +
                                                  gradeIdx +
                                                  "].arabicName",
                                                value.value
                                              );
                                          }}
                                          options={options}
                                          isClearable
                                          // styles={customStyle}
                                          formatCreateLabel={(userInput) =>
                                            `${t("Create")} - ${userInput}`
                                          }
                                          placeholder={t("SelectOrCreate")}
                                        />
                                        {/* <Field
                                          autoComplete="grade-arabic-name"
                                          name={
                                            "grades[" +
                                            gradeIdx +
                                            "].arabicName"
                                          }
                                          type="text"
                                          id={
                                            "grades-" + gradeIdx + "-arabicName"
                                          }
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                            errors.grades &&
                                            errors.grades[gradeIdx]?.arabicName
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                          }`}
                                        /> */}
                                        {errors.grades &&
                                          errors.grades[gradeIdx]
                                            ?.arabicName && (
                                            <p
                                              className="mt-1 px-4 text-sm text-red-600"
                                              id={
                                                "grades-" +
                                                gradeIdx +
                                                "-arabicName-error"
                                              }
                                            >
                                              {
                                                errors.grades[gradeIdx]
                                                  .arabicName
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor={
                                          "grades-" + gradeIdx + "-englishName"
                                        }
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("EnglishName")}
                                      </label>
                                      <div className="mt-1">
                                        <Field
                                          autoComplete="grade-english-name"
                                          name={
                                            "grades[" +
                                            gradeIdx +
                                            "].englishName"
                                          }
                                          type="text"
                                          id={
                                            "grades-" +
                                            gradeIdx +
                                            "-englishName"
                                          }
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                            errors.grades &&
                                            errors.grades[gradeIdx]?.englishName
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                          }`}
                                        />
                                        {errors.grades &&
                                          errors.grades[gradeIdx]
                                            ?.englishName && (
                                            <p
                                              className="mt-1 px-4 text-sm text-red-600"
                                              id={
                                                "grades-" +
                                                gradeIdx +
                                                "-englishName-error"
                                              }
                                            >
                                              {
                                                errors.grades[gradeIdx]
                                                  .englishName
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex justify-end">
                                    {gradeIdx > 0 && (
                                      <button
                                        type="button"
                                        className="my-4 inline-flex items-center justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                        onClick={() =>
                                          gradesArrayHelpers.remove(gradeIdx)
                                        }
                                      >
                                        <TrashIcon
                                          className="ml-1 mr-1 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        {t("Remove")}
                                      </button>
                                    )}
                                  </div>

                                  <FieldArray
                                    name={"grades[" + gradeIdx + "].classrooms"}
                                    render={(classArrayHelpers) => (
                                      <>
                                        {values.grades[gradeIdx].classrooms && (
                                          <div className="flex flex-col pb-3">
                                            <div className="relative mt-10 border rounded-lg shadow-lg px-4 py-8">
                                              <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block bg-white px-1 text-xl font-semibold text-gray-900">
                                                {t("ClassRoomsOf")}{" "}
                                                {values.grades[gradeIdx]
                                                  .englishName
                                                  ? " - "
                                                  : null}
                                                {window.localStorage.getItem(
                                                  "i18nextLng"
                                                ) === "ar"
                                                  ? values.grades[gradeIdx]
                                                      .arabicName
                                                  : values.grades[gradeIdx]
                                                      .englishName}
                                              </h2>
                                              {values.grades[
                                                gradeIdx
                                              ].classrooms.map(
                                                (classroom, classroomIdx) => (
                                                  <div
                                                    key={classroomIdx.toString()}
                                                    className="mt-6"
                                                  >
                                                    <label>
                                                      {classroomIdx > 0 && (
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            classArrayHelpers.remove(
                                                              classroomIdx
                                                            )
                                                          }
                                                        >
                                                          <MinusCircleIcon className="h-6 w-6 text-red-500" />
                                                        </button>
                                                      )}
                                                      <div className="relative grid gap-y-6 gap-4 grid-cols-6">
                                                        <div className="sm:col-span-3">
                                                          <div className="mt-1">
                                                            <Field
                                                              autoComplete="arabic-name"
                                                              placeholder={t(
                                                                "ArabicName"
                                                              )}
                                                              name={
                                                                "grades[" +
                                                                gradeIdx +
                                                                "].classrooms[" +
                                                                classroomIdx +
                                                                "].arabicName"
                                                              }
                                                              type="text"
                                                              id={
                                                                "grades-" +
                                                                gradeIdx +
                                                                "-classrooms-" +
                                                                classroomIdx +
                                                                "-arabicName"
                                                              }
                                                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                                                errors.grades &&
                                                                errors.grades[
                                                                  gradeIdx
                                                                ]?.classrooms[
                                                                  classroomIdx
                                                                ]?.arabicName
                                                                  ? "border-2 border-red-700 focus:ring-secondary"
                                                                  : "focus:border-secondary focus:ring-secondary"
                                                              }`}
                                                            />
                                                            {errors.grades &&
                                                              errors.grades[
                                                                gradeIdx
                                                              ]?.classrooms[
                                                                classroomIdx
                                                              ]?.arabicName && (
                                                                <p
                                                                  className="mt-1 px-4 text-sm text-red-600"
                                                                  id={
                                                                    "grades[-" +
                                                                    gradeIdx +
                                                                    "-classrooms-" +
                                                                    classroomIdx +
                                                                    "-arabicName-error"
                                                                  }
                                                                >
                                                                  {
                                                                    errors
                                                                      .grades[
                                                                      gradeIdx
                                                                    ]
                                                                      .classrooms[
                                                                      classroomIdx
                                                                    ].arabicName
                                                                  }
                                                                </p>
                                                              )}
                                                          </div>
                                                        </div>
                                                        <div className="sm:col-span-3">
                                                          <div className="mt-1">
                                                            <Field
                                                              autoComplete="english-name"
                                                              placeholder={t(
                                                                "EnglishName"
                                                              )}
                                                              name={
                                                                "grades[" +
                                                                gradeIdx +
                                                                "].classrooms[" +
                                                                classroomIdx +
                                                                "].englishName"
                                                              }
                                                              type="text"
                                                              id={
                                                                "grades-" +
                                                                gradeIdx +
                                                                "-classrooms-" +
                                                                classroomIdx +
                                                                "-englishName"
                                                              }
                                                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                                                errors.grades &&
                                                                errors.grades[
                                                                  gradeIdx
                                                                ]?.classrooms[
                                                                  classroomIdx
                                                                ]?.englishName
                                                                  ? "border-2 border-red-700 focus:ring-secondary"
                                                                  : "focus:border-secondary focus:ring-secondary"
                                                              }`}
                                                            />
                                                            {errors.grades &&
                                                              errors.grades[
                                                                gradeIdx
                                                              ]?.classrooms[
                                                                classroomIdx
                                                              ]
                                                                ?.englishName && (
                                                                <p
                                                                  className="mt-1 px-4 text-sm text-red-600"
                                                                  id={
                                                                    "grades[-" +
                                                                    gradeIdx +
                                                                    "-classrooms-" +
                                                                    classroomIdx +
                                                                    "-englishName-error"
                                                                  }
                                                                >
                                                                  {
                                                                    errors
                                                                      .grades[
                                                                      gradeIdx
                                                                    ]
                                                                      .classrooms[
                                                                      classroomIdx
                                                                    ]
                                                                      .englishName
                                                                  }
                                                                </p>
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}

                                        <div className="flex  justify-end flex-wrap items-center px-4 py-2">
                                          <button
                                            type="button"
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 my-4 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                            onClick={() =>
                                              classArrayHelpers.push({
                                                arabicName: "",
                                                englishName: "",
                                              })
                                            }
                                          >
                                            <PlusIcon className="h-5 w-5 mx-2"></PlusIcon>
                                            <span>{t("AddClass")}</span>
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end m-12">
                          <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-dark bg-gray-shade py-2 px-4 my-4 text-sm font-medium leading-4 text-dark shadow-sm hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() =>
                              gradesArrayHelpers.push({
                                instituteId:
                                  localStorage.getItem("instituteId"),
                                arabicName: "",
                                englishName: "",
                                classrooms: [
                                  {
                                    arabicName: "",
                                    englishName: "",
                                  },
                                ],
                              })
                            }
                          >
                            <PlusIcon className="h-5 w-5 mx-2"></PlusIcon>
                            <span>{t("AddGrade")}</span>
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
}
