import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register } from "../../slices/authSlice";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../components/root/AuthLayout";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import FailedAlert from "../../components/common/FailedAlert";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";

export default function ManagerInfo() {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (inputs) => {
    setIsLoading(true);
    dispatch(register({ ...inputs, role: "ADMIN" }))
      .then((response) => {
        if (response.payload.status === 201) navigate("/complete-register");
        else {
          setShowError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setShowError(true);
        console.log("🚀 ~ file: Login.js:39 ~ handleSubmit ~ error", error);
      });
  };

  const RegisterValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    midName: Yup.string().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
    password: Yup.string().min(3, t("PasswordMin3")).required(t("Required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("PasswordMustMatch"))
      .required(t("Required")),
  });

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      <LanguageFloatingButton />
      {error && (
        <FailedAlert
          show={showError}
          message={t(error.message)}
          close={handleCloseError}
        />
      )}
      <AuthLayout title={t("RegisterNewAccount")}>
        <Formik
          initialValues={{
            firstName: "",
            midName: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={RegisterValidationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className="space-y-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("FirstName")}
                </label>
                <div className="mt-1">
                  <Field
                    name="firstName"
                    id="firstName"
                    type="text"
                    autoComplete="firstName"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.firstName && errors.firstName
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="midName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("LastName")}
                </label>
                <div className="mt-1">
                  <Field
                    name="midName"
                    id="midName"
                    type="text"
                    autoComplete="midName"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.midName && errors.midName
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Email")}
                </label>
                <div className="mt-1">
                  <Field
                    name="email"
                    id="email"
                    type="email"
                    autoComplete="email"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.email && errors.email
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Password")}
                </label>
                <div className="mt-1">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.password && errors.password
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("ConfirmPassword")}
                </label>
                <div className="mt-1">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-confirmPassword"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.confirmPassword && errors.confirmPassword
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                >
                  {isLoading === false ? (
                    t("RegisterNewAccount")
                  ) : (
                    <ButtonLoadingSpinner />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <p className="mt-6 text-sm text-gray-900 ">
          {t("AlreadyRegistered")}
          <Link
            to="/login"
            className="font-medium text-yellow-700 hover:text-secondary rtl:pr-2 ltr:pl-2"
          >
            {t("Login")}
          </Link>
        </p>
      </AuthLayout>
    </>
  );
}
