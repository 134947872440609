export const authHeader = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
};

export const resetPasswordHeader = (token) => {
  if (token) {
    return {
      "Content-Type": "application/json",
      token: token,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
};

export const authHeaderWithMultipart = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {
      "Content-Type": "multipart/form-data",
    };
  }
};
