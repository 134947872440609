import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ConfirmAccount from "./pages/Auth/ConfirmAccount";
import CompleteRegister from "./pages/Auth/CompleteRegister";
import RequestResetPassword from "./pages/Auth/RequestResetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

import Home from "./pages/Home/Index";

import GradesList from "./pages/Grade/List";
import CreateGrades from "./pages/Grade/Create";
import GradeDetails from "./pages/Grade/Details";

import CreateClass from "./pages/Classroom/Create";
import EditClass from "./pages/Classroom/Edit";
import ClassRoomDetails from "./pages/Classroom/Details";
import CreateClassRoomStudents from "./pages/Classroom/CreateStudents";

import TeachersList from "./pages/Teacher/List";
import CreateTeachers from "./pages/Teacher/Create";
import EditTeacher from "./pages/Teacher/Edit";
import TeacherDetails from "./pages/Teacher/Details";

import StudentsList from "./pages/Student/List";
import CreateStudents from "./pages/Student/Create";
import EditStudent from "./pages/Student/Edit";
import StudentDetails from "./pages/Student/Details";
import EditGrade from "./pages/Grade/Edit";
import SendEmail from "./pages/Parents/SendEmail";
import SendNotification from "./pages/Parents/SendNotification";
import Prices from "./pages/Prices/Index";
import Reports from "./pages/Report/Index";
import GradesWithClassrooms from "./pages/Report/reports/GradesWithClassrooms";
import Teachers from "./pages/Report/reports/Teachers";
import Students from "./pages/Report/reports/Students";
import Champions from "./pages/Report/reports/Champions";
import StudentsGrades from "./pages/Student/Index";
import Profile from "./pages/User/Profile";
import Rounds from "./pages/Rounds/Rounds";
import EditRound from "./pages/Rounds/EditRound";
import AttendanceList from "./pages/Attendance/List";

import SubjectsList from "./pages/Subject/List";
import CreateSubjects from "./pages/Subject/Create";
import EditSubject from "./pages/Subject/Edit";
import EditSchedule from "./pages/Schedule/Edit";
import AttendanceConfig from "./pages/Config/Attendance";
import SubjectsGroup from "./pages/Teacher/SubjectsGroup";
import RoundStatistics from "./pages/Statistics/RoundStatistics";
import PointsStatistics from "./pages/Statistics/PointsStatistics";
import PointsConfig from "./pages/Config/Points";
import CreateGiftCards from "./pages/GiftCard/Create";
import GiftCardsList from "./pages/GiftCard/List";
import EditGiftCard from "./pages/GiftCard/Edit";
import ExchangePointsDetails from "./pages/ExchangePoint/Details";
import ExchangePointList from "./pages/ExchangePoint/List";
import AdsList from "./pages/Ads/List";
import CreateAd from "./pages/Ads/Create";
import EditAd from "./pages/Ads/Edit";
import Mode from "./pages/Config/Mode";

function App() {
  const { i18n } = useTranslation();

  //* To set the direction based on the selected language
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*">{Error}</Route>
          {/* Home Route */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          {/* Login Route */}
          <Route path="/login" element={<Login />} />

          {/* Register Route */}
          <Route path="/register" element={<Register />} />

          {/* Confirm account Route */}
          <Route
            path="/confirm-account"
            element={
              // <ProtectedRoute>
              <ConfirmAccount />
              // </ProtectedRoute>
            }
          />

          {/* Complete Register Route */}
          <Route
            path="/complete-register"
            element={
              // <ProtectedRoute>
              <CompleteRegister />
              // </ProtectedRoute>
            }
          />

          {/* Request Reset Password Route */}
          <Route
            path="/request-reset-password"
            element={<RequestResetPassword />}
          />

          {/* Reset Password Route */}
          <Route
            path="/reset-password/:email/:token"
            element={<ResetPassword />}
          />

          {/* Change Password Route */}
          <Route
            path="/change-password"
            element={
              // <ProtectedRoute>
              <ChangePassword />
              // </ProtectedRoute>
            }
          />

          {/* App Mode */}
          <Route
            path="/mode"
            element={
              <ProtectedRoute>
                <Mode />
              </ProtectedRoute>
            }
          />

          {/* Profile */}
          <Route
            path="/profile"
            element={
              // <ProtectedRoute>
              <Profile />
              // </ProtectedRoute>
            }
          />

          {/* Grades List */}
          <Route
            path="/grades/list"
            element={
              // <ProtectedRoute>
              <GradesList />
              // </ProtectedRoute>
            }
          />

          {/* Add New Grade */}
          <Route
            path="/grades/create"
            element={
              // <ProtectedRoute>
              <CreateGrades />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/grade/edit/:id"
            element={
              // <ProtectedRoute>
              <EditGrade />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/grade/details/:id"
            element={
              // <ProtectedRoute>
              <GradeDetails />
              // </ProtectedRoute>
            }
          />

          {/* Add New Class */}
          <Route
            path="/class-room/create/:gradeId"
            element={
              <ProtectedRoute>
                <CreateClass />
              </ProtectedRoute>
            }
          />

          {/* Add New Class */}
          <Route
            path="/class-room/edit/:gradeId/:id"
            element={
              <ProtectedRoute>
                <EditClass />
              </ProtectedRoute>
            }
          />

          {/* Add New Class */}
          <Route
            path="/class-room/details/:gradeId/:id"
            element={
              <ProtectedRoute>
                <ClassRoomDetails />
              </ProtectedRoute>
            }
          />

          {/* Teacher List */}
          <Route
            path="/teachers/list"
            element={
              // <ProtectedRoute>
              <TeachersList />
              // </ProtectedRoute>
            }
          />

          {/* Create New Teacher */}
          <Route
            path="/teachers/create"
            element={
              <ProtectedRoute>
                <CreateTeachers />
              </ProtectedRoute>
            }
          />

          {/* Edit Teacher */}
          <Route
            path="/teacher/edit/:id"
            element={
              // <ProtectedRoute>
              <EditTeacher />
              // </ProtectedRoute>
            }
          />

          {/* Teacher Details */}
          <Route
            path="/teacher/details/:id"
            element={
              <ProtectedRoute>
                <TeacherDetails />
              </ProtectedRoute>
            }
          />

          {/*  Students Grades index */}
          <Route
            path="/students/index"
            element={
              <ProtectedRoute>
                <StudentsGrades />
              </ProtectedRoute>
            }
          />

          {/*  Students List */}
          <Route
            path="/students/list/:gradeId"
            element={
              <ProtectedRoute>
                <StudentsList />
              </ProtectedRoute>
            }
          />

          {/* Create New Student */}
          <Route
            path="/students/create/:gradeId/:classRoomId"
            element={
              <ProtectedRoute>
                <CreateStudents />
              </ProtectedRoute>
            }
          />

          <Route
            path="/students/create/:gradeId/:classRoomId"
            element={
              <ProtectedRoute>
                <CreateClassRoomStudents />
              </ProtectedRoute>
            }
          />

          {/* Edit Student */}
          <Route
            path="/student/edit/:gradeId/:id"
            element={
              <ProtectedRoute>
                <EditStudent />
              </ProtectedRoute>
            }
          />

          {/* Student Details */}
          <Route
            path="/student/details/:gradeId/:id"
            element={
              <ProtectedRoute>
                <StudentDetails />
              </ProtectedRoute>
            }
          />

          {/* Parents Management */}
          <Route
            path="/parents/email"
            element={
              <ProtectedRoute>
                <SendEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/parents/send-notification"
            element={
              <ProtectedRoute>
                <SendNotification />
              </ProtectedRoute>
            }
          />

          {/* Pricing index */}
          <Route
            path="/subscription/prices"
            element={
              <ProtectedRoute>
                <Prices />
              </ProtectedRoute>
            }
          />

          {/* Reports index */}
          <Route
            path="/reports/index"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          {/* Reports index */}
          <Route
            path="/reports/grades-with-classes"
            element={
              <ProtectedRoute>
                <GradesWithClassrooms />
              </ProtectedRoute>
            }
          />

          {/* Reports index */}
          <Route
            path="/reports/teachers"
            element={
              <ProtectedRoute>
                <Teachers />
              </ProtectedRoute>
            }
          />
          {/* Reports index */}
          <Route
            path="/reports/students/:gradeId/:classRoomId"
            element={
              <ProtectedRoute>
                <Students />
              </ProtectedRoute>
            }
          />

          {/* Reports index */}
          <Route
            path="/reports/champions/:gradeId/:classRoomId"
            element={
              <ProtectedRoute>
                <Champions />
              </ProtectedRoute>
            }
          />

          {/* Rounds */}
          <Route
            path="/rounds"
            element={
              <ProtectedRoute>
                <Rounds />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rounds/edit"
            element={
              <ProtectedRoute>
                <EditRound />
              </ProtectedRoute>
            }
          />

          <Route
            path="/attendance/:classRoomId/"
            element={
              <ProtectedRoute>
                <AttendanceList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/subjects/:classRoomId"
            element={
              <ProtectedRoute>
                <SubjectsList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/subjects/create/:classRoomId"
            element={
              <ProtectedRoute>
                <CreateSubjects />
              </ProtectedRoute>
            }
          />

          <Route
            path="/subjects/:classRoomId/edit/:id/"
            element={
              <ProtectedRoute>
                <EditSubject />
              </ProtectedRoute>
            }
          />

          <Route
            path="/schedule/:classRoomId"
            element={
              <ProtectedRoute>
                <EditSchedule />
              </ProtectedRoute>
            }
          />

          {/* Config */}
          <Route
            path="/config/attendance"
            element={
              <ProtectedRoute>
                <AttendanceConfig />
              </ProtectedRoute>
            }
          />

          {/* Subject */}
          <Route
            path="/subject/:id/groups"
            element={
              <ProtectedRoute>
                <SubjectsGroup />
              </ProtectedRoute>
            }
          />

          {/* Statistics */}
          <Route
            path={`/statistics/rounds`}
            element={<RoundStatistics />}
          />
          <Route
            path={`/statistics/points`}
            element={<PointsStatistics />}
          />

          {/* Points */}
          <Route
            path="/config/points"
            element={
              <ProtectedRoute>
                <PointsConfig />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/points/create"
            element={
              <ProtectedRoute>
                <CreatePoint />
              </ProtectedRoute>
            }
          />
          <Route
            path="/points/edit/:id"
            element={
              <ProtectedRoute>
                <EditPoint />
              </ProtectedRoute>
            }
          />
          <Route
            path="/points/details/:id"
            element={
              <ProtectedRoute>
                <PointDetails />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/giftCards/create"
            element={
              <ProtectedRoute>
                <CreateGiftCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/giftCards/list"
            element={
              <ProtectedRoute>
                <GiftCardsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/giftCards/edit/:id"
            element={
              <ProtectedRoute>
                <EditGiftCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/exchange-points/list"
            element={
              <ProtectedRoute>
                <ExchangePointList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/exchange-points/details/:id"
            element={
              <ProtectedRoute>
                <ExchangePointsDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ads/list/"
            element={
              <ProtectedRoute>
                <AdsList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ads/create/"
            element={
              <ProtectedRoute>
                <CreateAd />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ads/edit/:id"
            element={
              <ProtectedRoute>
                <EditAd />
              </ProtectedRoute>
            }
          />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
