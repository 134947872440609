import { useState } from "react";
import AuthLayout from "../../components/root/AuthLayout";
import { useTranslation } from "react-i18next";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";
import { useNavigate } from "react-router-dom";
import FailedAlert from "../../components/common/FailedAlert";
import authServices from "../../services/auth.services";
import SuccessAlert from "../../components/common/SuccessAlert";
import rounds from '../../assets/rounds.jpg';
import points from '../../assets/points.jpg';
import { CheckCircleIcon } from '@heroicons/react/24/solid'; // Import Heroicons
import logo from "../../assets/logo.svg";

const Mode = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [hovered, setHovered] = useState(localStorage.getItem("mode"));
    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);

    const updateMode = async (mode) => {
        await authServices
            .updateInstitute({ mode: mode })
            .then((response) => {
                setSendSuccess(true);
                setHovered(mode);
                localStorage.setItem("mode", mode);
                setTimeout(() => {
                    setSendSuccess(false);
                }, 5000);
                if (mode === "ROUNDS") {
                    navigate('/rounds')
                } else {
                    navigate('/config/points')
                }
            })
            .catch((error) => {
                setSendFailed(true);
                setTimeout(() => {
                    setSendFailed(false);
                }, 5000);
            });
    };

    return (
        <>
            <LanguageFloatingButton />
            <SuccessAlert show={sendSuccess} message={t("SavedSuccessfully")} />
            <FailedAlert show={sendFailed} message={t("SomethingWentWroing")} />
            <div className="min-h-screen bg-gradient-to-br from-gray-shade to-secondary">
                <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img className="mx-auto h-28 w-auto" src={logo} alt="" />
                        <h2 className="mt-3 text-center text-3xl font-bold tracking-tight text-gray-100 drop-shadow-md">
                            {t("appMode")}
                        </h2>
                    </div>
                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-3xl">
                        <div className="flex justify-center p-1">
                            <div
                                className={`relative w-1/2 p-4 cursor-pointer rounded-lg mx-1 hover:bg-yellow-400 ${hovered === 'ROUNDS' ? 'bg-yellow-400' : 'bg-gray-400'}`}
                                onClick={() => updateMode('ROUNDS')}
                            >
                                {hovered === 'ROUNDS' && (
                                    <CheckCircleIcon className="absolute top-2 right-2 h-8 w-8 text-white" />
                                )}
                                <div className="flex justify-center">
                                    <img src={rounds} alt={'ROUNDS'} className="h-32 rounded-lg" />
                                </div>
                                <div className="text-center mt-4">
                                    <p className="text-xl font-semibold">{t('roundMode')}</p>
                                    <p className="text-md font-sans">{t('roundsModeDescription')}</p>
                                </div>
                            </div>
                            <div
                                className={`relative w-1/2 p-4 cursor-pointer rounded-lg mx-1 ${hovered === 'POINTS' ? 'bg-yellow-400' : 'bg-gray-400'} hover:bg-yellow-400`}
                                onClick={() => updateMode('POINTS')}
                            >
                                {hovered === 'POINTS' && (
                                    <CheckCircleIcon className="absolute top-2 right-2 h-8 w-8 text-white" />
                                )}
                                <div className="flex justify-center">
                                    <img src={points} alt={'POINTS'} className="h-32 rounded-lg" />
                                </div>
                                <div className="text-center mt-4">
                                    <p className="text-xl font-semibold">{t('pointsMode')}</p>
                                    <p className="text-md font-sans">{t('pointsModeDescription')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Mode;
