import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field } from "formik";
import { logout } from "../../slices/authSlice";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Select from "react-tailwindcss-select";
import * as teacherServices from "../../services/teacher.services";

export default function EditClassRoomsModal({
  id,
  open,
  close,
  success,
  supervised,
}) {
  const [data, setData] = useState(null);
  const [selectedClassRooms, setSelectedClassRooms] = useState({
    selected: [],
  });
  const [classRooms, setClassRooms] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    midName: Yup.string().required(t("Required")),
    phoneNumber: Yup.string().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
  });

  useEffect(() => {
    teacherServices
      .getTeacher(id)
      .then((response) => {
        setData(response.data.teacher);

        const selectedOptions = supervised
          ? response.data.teacher.assignedClassRooms
              .filter((data) => data.supervised === true)
              .map((data) => ({
                label: data.classRoom.arabicName,
                value: data.classRoomId,
              }))
          : response.data.teacher.assignedClassRooms.map((data) => ({
              label: data.classRoom.arabicName,
              value: data.classRoomId,
            }));
        setSelectedClassRooms({ selected: selectedOptions });
      })
      .catch((error) => {});
  }, [id]);

  useEffect(() => {
    getClassRooms(1);
    // eslint-disable-next-line
  }, []);

  const SaveChanges = async (inputs) => {
    teacherServices
      .updateTeacher(id, inputs)
      .then((response) => {
        success(true);
        close();
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const getClassRooms = async () => {
    try {
      const response = await teacherServices.getClassRooms();
      if (response.status === 200) {
        const options = response.data.grades.map((grade) => ({
          label: grade.arabicName,
          options: grade.classrooms.map((classRoom) => ({
            value: classRoom.id,
            label: classRoom.arabicName,
          })),
        }));

        setClassRooms(options);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handleChange = (value) => {
    setSelectedClassRooms({ selected: value });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                      <PencilSquareIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {t("EditClassRooms")}
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: data?.user?.firstName ?? "",
                      midName: data?.user?.midName ?? "",
                      email: data?.user?.email ?? "",
                      phoneNumber: data?.user?.phoneNumber ?? "",
                      password: "",
                      teacher: {
                        id: data?.id,
                        instituteId: localStorage.getItem("instituteId"),
                        assignedClassRooms: data?.assignedClassRooms,
                      },
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={ValidationSchema}
                    onSubmit={(values) => SaveChanges(values)}
                  >
                    {({ touched, errors, values }) => (
                      <Form>
                        <div className="relative grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="sm:col-span-12">
                            <>
                              <p className="rtl:text-right rtl:text-left mb-4 block text-sm font-medium text-gray-700">
                                {t("ClassRooms")}
                              </p>
                              <Select
                                placeholder={t("Select")}
                                value={
                                  selectedClassRooms.selected?.length > 0
                                    ? selectedClassRooms.selected
                                    : null
                                }
                                onChange={(value) => {
                                  //Show selections in the input field
                                  handleChange(value);
                                  //Add values to inputs
                                  if (value !== null) {
                                    values.teacher.assignedClassRooms =
                                      value.map((classRoom) => ({
                                        teacherId: data.id,
                                        classRoomId: classRoom.value,
                                        supervised: supervised,
                                      }));
                                  } else {
                                    values.teacher.assignedClassRooms = [];
                                  }
                                }}
                                options={classRooms}
                                isMultiple={true}
                                formatGroupLabel={(data) => (
                                  <div
                                    className={`py-2 text-xs flex items-center justify-between`}
                                  >
                                    <span className="font-bold ">
                                      {data.label}
                                    </span>
                                    <span className="bg-gray-200 h-5 h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                      {data.options.length}
                                    </span>
                                  </div>
                                )}
                                formatOptionLabel={(data) => (
                                  <li
                                    className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                      !data.isSelected
                                        ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                        : `bg-amber-100 text-amber-600`
                                    }`}
                                  >
                                    {data.label}
                                  </li>
                                )}
                              />
                            </>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
                          >
                            {t("Save")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="mt-2 sm:mt-2">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                      onClick={close}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
