import React, { Fragment, useRef } from "react";
import {
  HomeIcon,
  PencilSquareIcon,
  TrashIcon,
  PaperClipIcon,
  PrinterIcon,
} from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as studentServices from "../../services/student.services";
import { logout } from "../../slices/authSlice";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import ParentsInfo from "../../components/student/ParentsInfo";
import Notes from "../../components/student/Notes";
import NoParentsInfo from "../../components/student/NoParentsInfo";
import PagesHeaderNav from "../../components/common/PagesHeaderNav";
import { useReactToPrint } from "react-to-print";
import { StudentCard } from "../../components/student/StudentCard";
import { format } from "date-fns";

export default function StudentDetails() {
  let { id } = useParams();
  let { gradeId } = useParams();

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const pages = [
    { name: t("Students"), href: `/students/list/${gradeId}`, current: false },
    { name: t("StudentDetails"), href: "#", current: true },
  ];

  useEffect(() => {
    studentServices
      .getStudent(id)
      .then((response) => {
        console.log(response.data);

        if (response.status === 200) {
          setData(response.data.student);
          setIsLoading(false);
        }
      })
      .catch((error) => { });
    // eslint-disable-next-line
  }, [id]);

  const handleDelete = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await studentServices.deleteStudent(id);
      if (response.status === 200) {
        console.log("response: ", response.data);
        navigate("/students/list");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  return (
    <>
      <DeleteItemAlert
        show={deleteAlert}
        confirm={handleDelete}
        cancel={() => setDeleteAlert(false)}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only" >{t("Home")}</span>
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <PagesHeaderNav page={page} />
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="pb-5 sm:flex sm:items-center sm:justify-between">
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-dark bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dark focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => navigate(`/student/edit/${gradeId}/${id}`)}
                >
                  <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="ltr:ml-3 rtl:mr-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => setDeleteAlert(true)}
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="ltr:ml-3 rtl:mr-3 inline-flex items-center rounded-md border border-transparent bg-amber-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
                  onClick={handlePrint}
                >
                  <PrinterIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            {!isLoading && (
              <>
                <div style={{ display: "none" }}>
                  <StudentCard
                    ref={componentRef}
                    data={data.user}
                  ></StudentCard>
                </div>
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      {t("StudentInformation")}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      {t("PersonalDetails")}
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("FullName")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.user.firstName + " " + data.user.midName}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Code")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.user.code}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("IdentityNumber")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.user.identityNumber ?? "-"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Email")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.user.email}
                          {data.user.emailConfirmed === true ? (
                            <span className="mx-2 inline-flex items-center text-xs font-medium bg-green-100  text-green-500 px-2.5 py-1 rounded-full">
                              {t("Confirmed")}
                            </span>
                          ) : (
                            <span className="mx-2 inline-flex items-center text-xs font-medium bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                              {t("NotConfirmedYet")}
                            </span>
                          )}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("PhoneNumber")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.user.phoneNumber}
                          {data.user.phoneNumberConfirmed === true ? (
                            <span className="mx-2 inline-flex items-center text-xs font-medium bg-green-100  text-green-500 px-2.5 py-1 rounded-full">
                              {t("Confirmed")}
                            </span>
                          ) : (
                            <span className="mx-2 inline-flex items-center text-xs font-medium bg-red-100  text-red-500 px-2.5 py-1 rounded-fullbg-red-100 rounded-full">
                              {t("NotConfirmedYet")}
                            </span>
                          )}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Gender")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.user.gender}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("RegistrationDate")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(new Date(data.user?.createdAt), "MMMM d, y")}
                        </dd>
                      </div>
                      {/* <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Last Note
                            </dt>

                            {notes.length > 0 ? (
                              <dd className="mt-1 text-sm text-gray-900">
                                {notes[0].description}
                              </dd>
                            ) : (
                              <dd className="mt-1 text-sm text-gray-900">
                                If
                                {data.user.firstName +
                                  " " +
                                  data.user.midName}{" "}
                                has notes the latest one will Appear here.
                              </dd>
                            )}
                          </div> */}
                      {/* <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Attachments")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                            <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 w-0 flex-1 truncate">
                                  class_room_sheet.xlsx
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <Link
                                  to="#"
                                  className="font-medium text-primary hover:text-amber-500"
                                >
                                  {t("Download")}
                                </Link>
                              </div>
                            </li>
                            <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 w-0 flex-1 truncate">
                                  {data.user.firstName +
                                    "_collected_points.xlsx"}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <Link
                                  to="#"
                                  className="font-medium text-primary hover:text-amber-500"
                                >
                                  {t("Download")}
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </dd>
                      </div> */}
                    </dl>
                  </div>
                  <div className="px-5 mt-5 border-t border-gray-200">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                          {t("ClassRoomDetails")}
                        </h3>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <span className="flex-grow"></span>
                          <span className="ml-4 flex-shrink-0">
                            <button
                              type="button"
                              className="rounded-md bg-white font-medium text-primary hover:text-amber-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              {t("Details")}
                            </button>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div className="px-5 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("ClassName")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.classRoom.arabicName}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Grade")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data.classRoom.instituteGrade.arabicName}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("CreattionDate")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {format(
                            new Date(data.classRoom?.createdAt),
                            "MMMM d, y"
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                {data.studentParent !== null ? (
                  <ParentsInfo data={data.studentParent.parent} />
                ) : (
                  <>
                    <NoParentsInfo id={id} />
                  </>
                )}
              </>
            )}

            <Notes studentId={id} />
          </div>
        </div>
      </Layout>
    </>
  );
}
