import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const instance = axios.create({
  baseURL: base.apiUrl,
  timeout: 1000,
  headers: headers.authHeader(),
});

const sendEmail = (body) => {
  let data = new FormData();
  data.append("email", body.email);
  data.append("subject", body.subject);
  data.append("message", body.message);
  body.attachments.map((attachment) =>
    data.append("attachments", attachment.file)
  );

  return axios({
    method: "post",
    url: `${base.apiUrl}config/contact/email`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const sendNotification = (userId, body) => {
  return axios.post(`${base.apiUrl}notifier/notifiy/${userId}`, body, {
    headers: headers.authHeader(),
  });
};

export { sendEmail, sendNotification };
