import { useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/root/Layout";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import RoundsCustomDatePicker from "../../components/common/RoundsDatePicker";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import { roundServices } from "../../services/round.services";
import { useEffect } from "react";
import LoadingData from "../../components/common/LoadingData";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";

const EditRound = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [round, setRound] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const pages = [
    {
      name: t("Rounds"),
      href: `#`,
      current: false,
    },
    { name: t("EditRound"), href: "#", current: true },
  ];

  const editRoundsSchema = Yup.object().shape({
    type: Yup.string(),
    period: Yup.string()
      .ensure()
      .when("type", {
        is: "periodic",
        then: Yup.string().required(t("Required")),
      }),
    fromDate: Yup.string().when("type", {
      is: "specific",
      then: Yup.string().required(t("Required")),
    }),
    toDate: Yup.string().when("type", {
      is: "specific",
      then: Yup.string().required(t("Required")),
    }),
  });

  useEffect(() => {
    getRound();
  }, []);

  const getRound = async () => {
    setIsLoading(true);
    await roundServices
      .getRounds()
      .then((response) => {
        setRound(response.data.timeFrame);
        setIsLoading(false);
      })
      .catch((error) => {
        setRound(
          {
            id: 0,
            type: 'periodic',
            period: "weekly",
            fromDate: new Date(),
            toDate: new Date(),
          })
        setIsLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    if (round.id !== 0) {
      roundServices
        .editRounds(values)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setSuccess(true);
            setTimeout(() => {
              // After 3 seconds set the show value to false
              setSuccess(false);
            }, 5000);
          } else {
            setError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      //create
      roundServices
        .createRounds(values)
        .then((response) => {
          if (response.status === 201) {
            getRound();
            setIsLoading(false);
            setSuccess(true);
            setTimeout(() => {
              // After 3 seconds set the show value to false
              setSuccess(false);
            }, 5000);
          } else {
            setError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handleClose = () => {
    setError(false);
  };

  return (
    <>
      <SuccessAlert show={success} message={t("UpdateRound")} />
      <FailedAlert
        show={error}
        message={t("SomethingWentWroing")}
        close={handleClose}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {round ? (
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <Formik
                initialValues={{
                  type: round.type ?? " ",
                  period: round.period ?? " ",
                  fromDate: new Date(round.fromDate) ?? new Date(),
                  toDate: new Date(round.toDate) ?? new Date(),
                }}
                validationSchema={editRoundsSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors, values, setTouched, setFieldValue }) => (
                  <div className="border border-gray-200 rounded-lg shadow-lg p-10 bg-white">
                    <div className="mt-6">
                      <Form className="space-y-6">
                        <div>
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("RoundTime")}
                          </label>
                          <div className="mt-1">
                            <Field
                              as="select"
                              id="type"
                              name="type"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.type && errors.type
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            >
                              <option value="">{t("Select")}</option>
                              <option value="specific">
                                ⏲️ {t("Specific")}
                              </option>
                              <option value="periodic">
                                📅 {t("Periodic")}
                              </option>
                            </Field>
                          </div>
                        </div>

                        {values.type === "periodic" && (
                          <div>
                            <label
                              htmlFor="period"
                              className="block text-sm font-medium text-gray-700"
                            >
                              {t("Period")}
                            </label>
                            <div className="mt-1">
                              <Field
                                as="select"
                                id="period"
                                name="period"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.period && errors.period
                                  ? "border-2 border-red-700 focus:ring-secondary"
                                  : "focus:border-secondary focus:ring-secondary"
                                  }`}
                              >
                                <option value="">{t("Select")}</option>
                                <option value="weekly">📅 {t("Weekly")}</option>
                                <option value="monthly">
                                  📅 {t("Monthly")}
                                </option>
                              </Field>
                            </div>
                          </div>
                        )}
                        {values.type === "specific" && (
                          <div>
                            <div>
                              <label
                                htmlFor="fromDate"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {t("FromDate")}
                              </label>
                              <div className="mt-1">
                                <RoundsCustomDatePicker
                                  selected={
                                    values.fromDate
                                      ? new Date(values.fromDate)
                                      : new Date()
                                  }
                                  onChange={(newValue) => {
                                    setTouched({
                                      ...touched,
                                      fromDate: true,
                                    });
                                    setFieldValue(
                                      "fromDate",
                                      newValue
                                        ? newValue.toISOString()
                                        : new Date().toISOString
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              <label
                                htmlFor="toDate"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {t("ToDate")}
                              </label>
                              <div className="mt-1">
                                <RoundsCustomDatePicker
                                  selected={
                                    values.toDate
                                      ? new Date(values.toDate)
                                      : new Date()
                                  }
                                  onChange={(newValue) => {
                                    setTouched({
                                      ...touched,
                                      toDate: true,
                                    });
                                    setFieldValue(
                                      "toDate",
                                      newValue
                                        ? newValue.toISOString()
                                        : new Date().toISOString
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div>
                          <button
                            type="submit"
                            className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                          >
                            {isLoading === false ? (
                              t("Save")
                            ) : (
                              <ButtonLoadingSpinner />
                            )}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <LoadingData />
        )}
      </Layout>
    </>
  );
};

export default EditRound;
