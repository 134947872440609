import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { logout } from "../../slices/authSlice";
import * as Yup from "yup";
import {
  PaperAirplaneIcon,
  AtSymbolIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import * as contactServices from "../../services/contact.services";
import { Link } from "react-router-dom";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";
import * as studentServices from "../../services/student.services";
import { Combobox } from "@headlessui/react";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";

function SendNotification() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);
  // eslint-disable-next-line
  const [searchParentsLoading, setSearchParentsLoading] = useState(true);
  const [parents, setParents] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);

  const tabs = [
    {
      name: t("SendNotification"),
      href: "#",
      icon: PaperAirplaneIcon,
      current: true,
    },
    {
      name: t("SendEmail"),
      href: "/parents/email",
      icon: AtSymbolIcon,
      current: false,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    getParents();
    // eslint-disable-next-line
  }, []);

  const handleParentChange = (value) => {
    console.log(value);
    setSelectedParent(value.user);
  };

  const getParents = async () => {
    setSearchParentsLoading(true);
  };

  const searchParents = async (key) => {
    if (key !== "") {
      let result = await studentServices.searchParents(key, 1, 20);
      setParents(result.data.parents.rows);
    } else {
      let result = await studentServices.searchParents("p", 1, 20);
      setParents(result.data.parents.rows);
    }
  };

  const validationSchema = Yup.object().shape({
    arabicTitle: Yup.string().required(t("Required")),
    arabicBody: Yup.string().required(t("Required")),
  });

  const handleSubmit = (inputs) => {
    console.log(inputs);
    contactServices
      .sendNotification(inputs.userId, {
        ...inputs,
        englishTitle: inputs.arabicTitle,
        englishBody: inputs.arabicBody,
        action: "message",
      })
      .then((response) => {
        setSendSuccess(true);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setSendSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        setSendFailed(true);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setSendFailed(false);
        }, 5000);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  return (
    <div>
      <SuccessAlert show={sendSuccess} message={t("NotificationSent")} />
      <FailedAlert show={sendFailed} message={t("SomethingWentWroing")} />
      <Layout>
        <div className="sm:hidden">
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-amber-400 focus:ring-amber-400"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-amber-400 text-amber-500"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? "text-amber-400"
                        : "text-gray-400 group-hover:text-gray-500",
                      "ml-2 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </Link>
              ))}
            </nav>
          </div>
        </div>
        <div className="px-10">
          <Formik
            initialValues={{
              arabicTitle: "",
              arabicBody: "",
              userId: "",
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, setFieldValue, setTouched, values }) => (
              <Form>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                  <div className="sm:col-span-12">
                    <div className="sm:col-span-12">
                      <Combobox
                        as="div"
                        name={`student.studentParent.userId`}
                        value={selectedParent || null}
                        onChange={(value) => {
                          handleParentChange(value);

                          setFieldValue("userId", value.user.id);
                        }}
                      >
                        <Combobox.Label className="block text-sm font-medium text-gray-700">
                          {t("SendNotificationTo")}
                          <span className="block text-[12px] font-small text-gray-400">
                            {t("SearchParents")}
                          </span>
                        </Combobox.Label>
                        <div className="relative mt-1">
                          <Combobox.Input
                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                            onChange={(event) =>
                              searchParents(event.target.value)
                            }
                            displayValue={(parent) =>
                              parent &&
                              parent?.firstName.toUpperCase() +
                                " " +
                                parent?.midName.toUpperCase() +
                                " - @" +
                                parent?.email
                            }
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>

                          {parents.length > 0 && (
                            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {parents.map((parent) => (
                                <Combobox.Option
                                  key={parent.id}
                                  value={parent}
                                  className={({ active }) =>
                                    classNames(
                                      "relative cursor-default select-none py-2 pl-3 pr-9",
                                      active
                                        ? "bg-amber-100 text-amber"
                                        : "text-amber-300"
                                    )
                                  }
                                >
                                  {({ active, selected }) => (
                                    <>
                                      <div className="flex items-center">
                                        <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                                          <span className="font-medium leading-none text-white">
                                            {parent.user.firstName
                                              .substring(0, 1)
                                              .toUpperCase() +
                                              parent.user.midName
                                                .substring(0, 1)
                                                .toUpperCase()}
                                          </span>
                                        </span>
                                        <div className="text-left text-sm font-semibold text-gray-900">
                                          <span
                                            className={classNames(
                                              "ml-3 truncate",
                                              selected && "font-semibold"
                                            )}
                                          >
                                            {parent.user.firstName.toUpperCase() +
                                              " " +
                                              parent.user.midName.toUpperCase()}
                                          </span>
                                          <br />
                                          <span
                                            className={classNames(
                                              "ml-3 truncate text-sm text-gray-400",
                                              selected && "font-semibold"
                                            )}
                                          >
                                            {parent.user.email +
                                              " - " +
                                              parent.user.phoneNumber}
                                          </span>
                                        </div>
                                      </div>

                                      {selected && (
                                        <span
                                          className={classNames(
                                            "absolute inset-y-0 right-0 flex items-center pr-4",
                                            active
                                              ? "text-amber"
                                              : "text-amber-600"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </Combobox.Options>
                          )}
                        </div>
                      </Combobox>
                    </div>
                  </div>
                  {errors.students && errors.student?.studentParent?.userId && (
                    <p
                      className="mt-1 px-4 text-sm text-red-600"
                      id={"student-studentParent-userId-error"}
                    >
                      {errors.student.studentParent.userId}
                    </p>
                  )}
                  <div className="sm:col-span-12">
                    <div className="mt-1">
                      <Field
                        type="text"
                        name="arabicTitle"
                        id="arabicTitle"
                        placeholder={t("Subject")}
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                          errors.arabicTitle
                            ? "border-2 border-red-700 focus:ring-secondary"
                            : "focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                      {errors.arabicTitle && (
                        <p
                          className="mt-1 px-4 text-sm text-red-600"
                          id={"arabicTitle-error"}
                        >
                          {errors.arabicTitle}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-12">
                    <div className="mt-1">
                      <Field
                        component="textarea"
                        rows="12"
                        name="arabicBody"
                        id="arabicBody"
                        placeholder={t("WriteMessage")}
                        autoComplete="message"
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                          errors.arabicBody
                            ? "border-2 border-red-700 focus:ring-secondary"
                            : "focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                      {errors.arabicBody && (
                        <p
                          className="mt-1 px-4 text-sm text-red-600"
                          id={"arabicBody-error"}
                        >
                          {errors.arabicBody}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className="inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                  >
                    {t("Send")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Layout>
    </div>
  );
}

export default SendNotification;
