import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as gradeService from "../../services/grade.services";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import { Formik, Form, Field } from "formik";
import SuccessAlert from "../../components/common/SuccessAlert";
import LayoutMiddle from "../../components/root/LayoutMiddle";

export default function StudentsGrades() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletedItem, setDeletedItem] = useState([]);
  const [editedRow, setEditedRow] = useState(0);
  const [editSuccess, setEditSuccess] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getItems(1);
    // eslint-disable-next-line
  }, []);

  const getItems = async (page) => {
    setIsLoading(true);
    try {
      const response = await gradeService.getGrades(page, limit);
      if (response.status === 200) {
        setRows(response.data.grades.rows);
        setCount(response.data.grades.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await gradeService.getGrades(page, limit);
      if (response.status === 200) {
        setRows(response.data.grades.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const alertDelete = async (id) => {
    setDeletedItem(id);
    setDeleteAlert(true);
  };

  const deleteItem = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await gradeService.deleteGrade(deletedItem);
      if (response.status === 200) {
        console.log("response: ", response.data);
        getItems(1);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const saveChange = (values) => {
    gradeService
      .updateGrade(values.id, values)
      .then((response) => {
        setEditSuccess(true);
        setEditedRow(0);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setEditSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const columns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Name")}
        </div>
      ),
      selector: (row) => (
        <p
          className="text-base hover:cursor-pointer"
          onClick={() =>
            navigate(`/students/list/${row.id}?grade=${row.arabicName}`)
          }
        >
          {row.arabicName}
        </p>
      ),
      sortable: true,
      wrap: true,
      grow: 6,
    },

    {
      selector: (row) => (
        <>
          <button
            type="button"
            onClick={() =>
              navigate(`/students/list/${row.id}?grade=${row.arabicName}`)
            }
            className="rtl:mr-2 ltr:ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-white px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-secondary  sm:w-auto "
          >
            {window.localStorage.getItem("i18nextLng") === "ar" ? (
              <ChevronLeftIcon className="w-6 h-6 text-gray-400 hover:text-white"></ChevronLeftIcon>
            ) : (
              <ChevronRightIcon className="w-6 h-6 text-gray-400 hover:text-white"></ChevronRightIcon>
            )}
          </button>
        </>
      ),
      cellExport: (row) => row.null,
    },
  ];

  return (
    <>
      <SuccessAlert show={editSuccess} message={t("changesSaved")} />
      <DeleteItemAlert
        show={deleteAlert}
        confirm={deleteItem}
        cancel={() => setDeleteAlert(false)}
      />
      <LayoutMiddle>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("SelectGradeFirst")}
            </h1>
          </div>
        </div>
        <div className="mt-8 flex flex-col ">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white">
                    {/* Grades List Table */}
                    {/* <DataTableExtensions {...tableData}> */}
                    <DataTable
                      columns={columns}
                      data={rows}
                      progressPending={isLoading}
                      Header
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationTotalRows={count}
                      onChangeRowsPerPage={getNextItems}
                      onChangePage={getItems}
                      noDataComponent={<NoData />}
                      progressComponent={<LoadingData />}
                    />
                    {/* </DataTableExtensions> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMiddle>
    </>
  );
}
