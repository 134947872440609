import React, { useState, useEffect } from "react";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import * as configServices from "../../services/config.services";
import LoadingData from "../../components/common/LoadingData";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";

const AttendanceConfig = () => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);

  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));

  const configSchema = Yup.object().shape({
    arabicDescription: Yup.string(),
    englishDescription: Yup.string(),
    point: Yup.string(),
  });

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = async () => {
    await configServices
      .getConfig("attendance")
      .then((response) => {
        console.log("🚀 ~ awaitconfigServices.getConfig ~ response:", response);
        if (response.status === 200) {
          setConfig(response.data.result);
          setLoading(false);
        } else {
          setConfig({
            arabicDescription: "",
            englishDescription: "",
            point: 0

          })
        }
      })
      .catch((error) => {
        setConfig({
          arabicDescription: "",
          englishDescription: "",
          point: 0

        })
        console.log("🚀 ~ awaitconfigServices.getConfig ~ error:", error);
        setLoading(false);
      });
  };

  const handleConfig = (values, setSubmitting) => {
    setSubmitting(true);
    configServices
      .Config(user.institute.id, "attendance", values)
      .then((response) => {
        if (response.status === 200) {
          setSubmitting(false);
          setSendSuccess(true);
          setTimeout(() => {
            // After 3 seconds set the show value to false
            setSendSuccess(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("🚀 ~ .then ~ error:", error);
        setSubmitting(false);
        setSendFailed(true);
        setTimeout(() => {
          // After 3 seconds set the show value to false
          setSendFailed(false);
        }, 5000);
      });
  };

  return (
    <Layout>
      <SuccessAlert show={sendSuccess} message={t("SavedSuccessfully")} />
      <FailedAlert show={sendFailed} message={t("SomethingWentWroing")} />
      <h3 className="mt-6 mb-3 text-xl font-semibold text-gray-900">
        {t("Attendance")}
      </h3>
      {!loading ? (
        <Formik
          initialValues={{
            arabicDescription: config.arabicDescription ?? "",
            englishDescription: config.englishDescription ?? "",
            points: config.points ?? "",
          }}
          validationSchema={configSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleConfig(values, setSubmitting);
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark p-5">
                <label className="">{t("AttendancePoints")}</label>
                <Field
                  type="text"
                  name="points"
                  className={`w-full mt-3 mb-4 rounded-md shadow-sm focus:border-secondary border border-gray-400 sm:text-sm py-2.5 outline-none ${errors.points
                    ? "border-red-600 focus:ring-secondary"
                    : "focus:border-secondary focus:ring-secondary"
                    }`}
                />
                <label className="">{t("arabicDescription")}</label>
                <Field
                  type="text"
                  name="arabicDescription"
                  as="textarea"
                  rows="3"
                  className={`w-full mt-3 mb-4 rounded-md shadow-sm focus:border-secondary border border-gray-400 sm:text-sm py-2.5 outline-none ${errors.arabicDescription
                    ? "border-red-600 focus:ring-secondary"
                    : "focus:border-secondary focus:ring-secondary"
                    }`}
                />
                <label className="">{t("englishDescription")}</label>
                <Field
                  type="text"
                  name="englishDescription"
                  as="textarea"
                  rows="3"
                  className={`w-full mt-3 mb-4 rounded-md shadow-sm focus:border-secondary border border-gray-400 sm:text-sm py-2.5 outline-none ${errors.englishDescription
                    ? "border-red-600 focus:ring-secondary"
                    : "focus:border-secondary focus:ring-secondary"
                    }`}
                />
              </div>
              <div className="flex justify-end gap-4.5 mt-3 ">
                <button
                  className="flex w-40 justify-center rounded bg-primary py-2 px-8 font-medium text-gray-100 hover:bg-opacity-95 shadow-default"
                  type="submit"
                >
                  {isSubmitting ? <ButtonLoadingSpinner /> : t("save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingSpinner />
      )}
    </Layout>
  );
};

export default AttendanceConfig;
