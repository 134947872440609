import { useTranslation } from "react-i18next";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";

export default function LoadingOver() {
  const { t } = useTranslation();

  return (
    <div className="  fixed z-20 overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <ButtonLoadingSpinner></ButtonLoadingSpinner>
        <p className="text-white px-2 ">{t("LoadingData")}</p>
      </div>
    </div>
  );
}
