import React, { useEffect } from "react";
import Statistic from "../../components/dashboard/Statistic";
import AppMode from "../../components/dashboard/AppMode";
import LineChart from "../../components/dashboard/LineChart";
import Donut from "../../components/dashboard/Donut";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import free_school from "../../assets/free_school.jpg";

export default function Index() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("instituteId") === null) {
      navigate("/complete-register");
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Layout>
      <Statistic />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 sm:py-24 lg:px-8">
        <AppMode />
        <div className="relative overflow-hidden rounded-lg">
          <div className="absolute inset-0">
            <img
              src={free_school}
              alt=""
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="relative bg-amber-500 bg-opacity-60 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
            <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span className="block sm:inline">{t("enjoy_free")}</span>
              </h2>
              <p className="mt-3 text-xl text-white">{t("enjoy_free_des")}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <h3 className="mt-6 mb-3 text-xl font-semibold text-gray-900">
        {t("Dashboard")}
      </h3>
      <div className="flex">
        <div className="bg-white w-1/2 mx-2 flex justify-center items-center border shadow rounded-lg">
          <Donut />
        </div>
        <div className="bg-white w-1/2 mx-2 p-4 flex items-center border shadow rounded-lg">
          <LineChart />
        </div>
      </div> */}
    </Layout>
  );
}
