import { useState } from "react";
import AuthLayout from "../../components/root/AuthLayout";
import { useTranslation } from "react-i18next";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import RoundsCustomDatePicker from "../../components/common/RoundsDatePicker";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import FailedAlert from "../../components/common/FailedAlert";
import { roundServices } from "../../services/round.services";

const Rounds = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();
  let navigate = useNavigate();

  const roundsSchema = Yup.object().shape({
    type: Yup.string().required(t("Required")),
    period: Yup.string()
      .ensure()
      .when("type", {
        is: "periodic",
        then: Yup.string().required(t("Required")),
      }),
    fromDate: Yup.string()
      .ensure()
      .when("type", {
        is: "specific",
        then: Yup.string().required(t("Required")),
      }),
    toDate: Yup.string()
      .ensure()
      .when("type", {
        is: "specific",
        then: Yup.string().required(t("Required")),
      }),
  });

  const handleSubmit = (inputs) => {
    setIsLoading(true);
    roundServices
      .createRounds(inputs)
      .then((response) => {
        if (response.status === 201) navigate("/");
        else {
          setError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  const handleClose = () => {
    setError(false);
  };

  return (
    <>
      <LanguageFloatingButton />
      <FailedAlert
        show={error}
        message={t("SomethingWentWroing")}
        close={handleClose}
      />
      <AuthLayout title={t("Rounds")}>
        <Formik
          initialValues={{
            type: "",
            period: "",
            fromDate: "",
            toDate: "",
          }}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={roundsSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, values, setTouched, setFieldValue }) => (
            <Form className="space-y-6">
              <div>
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("RoundTime")}
                </label>
                <div className="mt-1">
                  <Field
                    as="select"
                    id="type"
                    name="type"
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                      touched.type && errors.type
                        ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="specific">⏲️ {t("Specific")}</option>
                    <option value="periodic">📅 {t("Periodic")}</option>
                  </Field>
                </div>
              </div>

              {values.type === "periodic" && (
                <div>
                  <label
                    htmlFor="period"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Period")}
                  </label>
                  <div className="mt-1">
                    <Field
                      as="select"
                      id="period"
                      name="period"
                      className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${
                        errors.period
                          ? "border-1 border-red-800  focus:border-red-800"
                          : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                    >
                      <option value="">{t("Select")}</option>
                      <option value="weekly">📅 {t("Weekly")}</option>
                      <option value="monthly">📅 {t("Monthly")}</option>
                    </Field>
                    {errors.period && (
                      <p
                        className="mt-1 px-4 text-sm text-red-600"
                        id={"period-error"}
                      >
                        {errors.period}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {values.type === "specific" && (
                <div>
                  <div>
                    <label
                      htmlFor="fromDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("FromDate")}
                    </label>
                    <div className="mt-1">
                      <RoundsCustomDatePicker
                        selected={values.fromDate && new Date(values.fromDate)}
                        onChange={(newValue) => {
                          setTouched({
                            ...touched,
                            fromDate: true,
                          });
                          setFieldValue(
                            "fromDate",
                            newValue
                              ? newValue.toISOString()
                              : new Date().toISOString
                          );
                        }}
                        required={
                          errors.fromDate
                            ? "border-2 border-red-500 focus:ring-secondary"
                            : "focus:border-secondary border-gray-400 focus:ring-secondary"
                        }
                      />
                      {errors.fromDate && (
                        <p
                          className="mt-1 px-4 text-sm text-red-600"
                          id={"fromDate-error"}
                        >
                          {errors.fromDate}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="toDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("ToDate")}
                    </label>
                    <div className="mt-1">
                      <RoundsCustomDatePicker
                        selected={values.toDate && new Date(values.toDate)}
                        onChange={(newValue) => {
                          setTouched({
                            ...touched,
                            toDate: true,
                          });
                          setFieldValue(
                            "toDate",
                            newValue
                              ? newValue.toISOString()
                              : new Date().toISOString
                          );
                        }}
                        required={
                          errors.toDate
                            ? "border-2 border-red-500 focus:ring-secondary"
                            : "focus:border-secondary border-gray-400 focus:ring-secondary"
                        }
                      />
                      {errors.toDate && (
                        <p
                          className="mt-1 px-4 text-sm text-red-600"
                          id={"toDate-error"}
                        >
                          {errors.toDate}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                >
                  {isLoading === false ? (
                    t("CompleteRegister")
                  ) : (
                    <ButtonLoadingSpinner />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </>
  );
};

export default Rounds;
