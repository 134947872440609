const Body = ({ children }) => {
  return (
    <main className="flex-1">
      <div className="py-6">
        <div className="mx-auto max-w-9xl px-4 sm:px-6 lg:px-8">{children}</div>
      </div>
    </main>
  );
};

export default Body;
