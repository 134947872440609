import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const createRounds = (body) => {
  return axios.post(`${base.apiUrl}config/time-frame`, body, {
    headers: headers.authHeader(),
  });
};

const getRounds = () => {
  return axios.get(`${base.apiUrl}config/time-frame`, {
    headers: headers.authHeader(),
  });
};

const editRounds = (body) => {
  return axios.put(`${base.apiUrl}config/time-frame`, body, {
    headers: headers.authHeader(),
  });
};

export const roundServices = {
  createRounds,
  getRounds,
  editRounds,
};
