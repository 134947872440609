import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getClassRoom = (id) => {
  return axios.get(`${base.apiUrl}institute/class-room/${id}`, {
    headers: headers.authHeader(),
  });
  //return instance.get(`institute/class-room/${id}`);
};

const getClassRooms = (gradeId, page, limit) => {
  return axios.get(
    `${base.apiUrl}institute/class-rooms/${gradeId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
  //return instance.get(`institute/class-rooms/${gradeId}/${page}/${limit}`);
};

const search = (key) => {
  return axios.get(
    `${base.apiUrl}institute/search?instituteId=${localStorage.getItem(
      "instituteId"
    )}&key=${key}`,
    {
      headers: headers.authHeader(),
    }
  );
  //return instance.get(`institute/class-rooms/${gradeId}/${page}/${limit}`);
};

const createClassRooms = (body) => {
  return axios.post(`${base.apiUrl}institute/class-room`, body, {
    headers: headers.authHeader(),
  });

  //return instance.post("institute/class-room", body);
};

const updateClassRoom = (id, body) => {
  return axios.put(`${base.apiUrl}institute/class-room/${id}`, body, {
    headers: headers.authHeader(),
  });

  //return instance.put(`institute/class-room/${id}`, body);
};

const deleteClassRoom = (id) => {
  return axios.delete(`${base.apiUrl}institute/class-room/${id}`, {
    headers: headers.authHeader(),
  });

  //return instance.delete(`institute/class-room/${id}`);
};
export {
  getClassRoom,
  getClassRooms,
  createClassRooms,
  updateClassRoom,
  deleteClassRoom,
  search,
};
