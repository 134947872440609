import { XCircleIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";

export default function FailedAlert({ show, message, close }) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none z-40 fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 "
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-center">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm  rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 bg-red-100">
              <div className="p-4 ">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5 rtl:pl-2 ltr:pr-2">
                    <button onClick={() => close()}>
                      <XCircleIcon className="h-6 w-6 text-red-500" />
                    </button>
                  </div>
                  <div className="ml-3 w-0 flex-1">
                    <p className="mt-1 text-sm text-center font-medium text-red-500">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
