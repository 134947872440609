import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getAttendances = (classRoomId, date, page, limit) => {
  return axios.get(
    `${base.apiUrl}attendance/history/${classRoomId}/${page}/${limit}`,
    {
      params: { date },
      headers: headers.authHeader(),
    }
  );
};

export { getAttendances };
