import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getPoints = (instituteId) => {
  return axios.get(`${base.apiUrl}points/config?instituteId=${instituteId}`, {
    headers: headers.authHeader(),
  });
};

const createPoint = (instituteId, body) => {
  return axios.post(`${base.apiUrl}points/config/${instituteId}`, body, {
    headers: headers.authHeader(),
  });
};

const updatePoint = (id, body) => {
  return axios.put(`${base.apiUrl}points/config/${id}`, body, {
    headers: headers.authHeader(),
  });
};

export { getPoints, createPoint, updatePoint };
