import React, { useState, useEffect } from "react";
import {
  UserIcon,
  UserGroupIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { logout } from "../../slices/authSlice";
import ButtonLoadingSpinner from "../common/ButtonLoadingSpinner";
import * as homeServices from "../../services/home.services";

export default function Statistic() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getInshights();
    // eslint-disable-next-line
  }, []);

  const getInshights = async (page) => {
    setIsLoading(true);
    try {
      const response = await homeServices.getInstituteInsights();
      if (response.status === 200) {
        setStats(response.data.counts);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <h3 className="text-xl font-semibold leading-6 text-gray-900">
        {t("Statistic")}
      </h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-2 border shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-amber-500 p-3">
              <UserIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ltr:ml-16 rtl:mr-16 truncate text-md font-medium text-gray-500">
              {t("Teachers")}
            </p>
          </dt>
          <dd className="ltr:ml-16 rtl:mr-16 flex items-baseline pb-6 sm:pb-7">
            {isLoading && <ButtonLoadingSpinner />}
            {!isLoading && (
              <p className="text-2xl font-semibold text-gray-900">
                {stats.teachersCount}
              </p>
            )}
            <p className="ltr:ml-2 rtl:mr-2 flex items-baseline text-sm font-semibold"></p>
          </dd>
        </div>
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-2 border shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-amber-500 p-3">
              <AcademicCapIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <p className="ltr:ml-16 rtl:mr-16 truncate text-md font-medium text-gray-500">
              {t("ClassRooms")}
            </p>
          </dt>
          <dd className="ltr:ml-16 rtl:mr-16 flex items-baseline pb-6 sm:pb-7">
            {isLoading && <ButtonLoadingSpinner />}
            {!isLoading && (
              <p className="text-2xl font-semibold text-gray-900">
                {stats.classRoomsCount}
              </p>
            )}
            <p className="ltr:ml-2 rtl:mr-2 flex items-baseline text-sm font-semibold"></p>
          </dd>
        </div>
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-2 border shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-amber-500 p-3">
              <UserGroupIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <p className="ltr:ml-16 rtl:mr-16 truncate text-md font-medium text-gray-500">
              {t("Students")}
            </p>
          </dt>
          <dd className="ltr:ml-16 rtl:mr-16 flex items-baseline pb-6 sm:pb-7">
            {isLoading && <ButtonLoadingSpinner />}
            {!isLoading && (
              <p className="text-2xl font-semibold text-gray-900">
                {stats.studentsCount}
              </p>
            )}
            <p className="ltr:ml-2 rtl:mr-2 flex items-baseline text-sm font-semibold"></p>
          </dd>
        </div>
      </dl>
    </div>
  );
}
