import SideBar from "./SideBar";
import Header from "./Header";
import Body from "./Body";
import whatsapp from "../../assets/whatsapp.png";
// import Container from "./Container";
// import Breadcrumb from "../Breadcrumb";

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-slate-50">
      <SideBar />
      <div className="flex flex-1 flex-col rtl:lg:pr-64 ltr:lg:pl-64">
        <Header />
        <Body>{children}</Body>
      </div>
      <a
        href="https://wa.me/966502550542"
        className="fixed w-[60px] h-[60px] bg-[#0C9] text-white text-center shadow-[2px_2px_3px_#999] z-40 rounded-[50px] rtl:left-10 ltr:right-10 bottom-10"
      >
        <img className="block w-6/12 mt-[25%] mx-auto" src={whatsapp} alt="" />
      </a>
    </div>
  );
};

export default Layout;
