import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getGrade = (id) => {
  return axios.get(`${base.apiUrl}institute/grade/${id}`, {
    headers: headers.authHeader(),
  });

  //return instance.get(`institute/grade/${id}`);
};

const getGrades = (page, limit) => {
  return axios.get(
    `${base.apiUrl}institute/grades/${localStorage.getItem(
      "instituteId"
    )}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );

  // return instance.get(
  //   `institute/grades/${localStorage.getItem("instituteId")}/${page}/${limit}`
  // );
};

const createGradesWithClassrooms = (body) => {
  return axios.post(`${base.apiUrl}institute/grades`, body, {
    headers: headers.authHeader(),
  });

  //return instance.post("institute/grades", body);
};

const updateGrade = (id, body) => {
  return axios.put(`${base.apiUrl}institute/grade/${id}`, body, {
    headers: headers.authHeader(),
  });
  // return instance.put(`institute/grade/${id}`, body);
};

const deleteGrade = (id, body) => {
  return axios.delete(`${base.apiUrl}institute/grade/${id}`, {
    headers: headers.authHeader(),
  });
  //return instance.delete(`institute/grade/${id}`);
};

export {
  getGrade,
  getGrades,
  createGradesWithClassrooms,
  updateGrade,
  deleteGrade,
};
