import React, { Fragment, useState, useEffect } from "react";
import SideBar from "../../components/root/SideBar";
import Header from "../../components/root/Header";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearError } from "../../slices/errorSlice";
import { logout } from "../../slices/authSlice";
import * as studentServices from "../../services/student.services";
import Select from "react-tailwindcss-select";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
  PlusIcon,
  TrashIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import { Switch, Combobox } from "@headlessui/react";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";

export default function CreateClassRoomStudents() {
  let { gradeId } = useParams();
  let { classRoomId } = useParams();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParentsLoading, setSearchParentsLoading] = useState(true);
  const [parents, setParents] = useState([]);
  const [selectedParent, setSelectedParent] = useState({
    selected: [],
  });
  const { error } = useSelector((state) => state.error);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Grades"), href: "/grades/list", current: false },
    {
      name: t("GradeDetails"),
      href: `/grade/details/${gradeId}`,
      current: false,
    },
    {
      name: t("ClassRoomDetails"),
      href: `/class-room/details/${gradeId}/${classRoomId}`,
      current: true,
    },
  ];

  useEffect(() => {
    getParents();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (inputs) => {
    //console.log(inputs);
    studentServices
      .createStudents(inputs)
      .then((response) => {
        if (response.status === 201)
          navigate(`/class-room/details/${gradeId}/${classRoomId}`);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Create students.js:39 ~ handleSubmit ~ error",
          error
        );
      });
  };

  const handleCancel = () => {
    navigate(`/class-room/details/${gradeId}/${classRoomId}`);
  };

  const handleParentChange = (value, studentIdx) => {
    console.log(value);
    console.log(studentIdx);
    selectedParent.selected[studentIdx] = value;
    setSelectedParent({ selected: selectedParent.selected });
  };

  const getParents = async () => {
    setSearchParentsLoading(true);
  };

  const searchParents = async (key) => {
    if (key !== "") {
      let result = await studentServices.searchParents(key, 1, 20);
      setParents(result.data.parents.rows);
    } else {
      let result = await studentServices.searchParents("p", 1, 20);
      setParents(result.data.parents.rows);
    }
  };

  const ValidationSchema = Yup.object().shape({
    students: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().required(t("Required")),
          midName: Yup.string().required(t("Required")),
          phoneNumber: Yup.string().required(t("Required")),
          email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
          password: Yup.string()
            .min(3, t("PasswordMin3"))
            .required(t("Required")),
          gender: Yup.string().required(t("Required")),
          birthDate: Yup.string().required(t("Required")),
          student: Yup.object().shape({
            classRoomId: Yup.string().required(t("Required")),
            studentParent: Yup.object().shape({
              userId: Yup.string().required(t("Required")),
            }),
          }),
        })
      )
      .required(t("Required"))
      .min(1, t("OneRecordMin")),
  });

  return (
    <Layout>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                {window.localStorage.getItem("i18nextLng") === "ar" ? (
                  <ChevronLeftIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <a
                  href={page.href}
                  className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="min-w-full divide-y divide-gray-300">
                <div className="divide-y divide-gray-200 bg-white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <Formik
            initialValues={{
              students: [
                {
                  code: "006",
                  firstName: "",
                  midName: "",
                  email: "",
                  phoneNumber: "",
                  password: "",
                  gender: "male",
                  birthDate: new Date(),
                  student: {
                    classRoomId: classRoomId,
                    studentParent: {
                      userId: "",
                    },
                  },
                },
              ],
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={ValidationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              touched,
              errors,
              values,
              setTouched,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form>
                <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {t("AddNewStudents")}
                  </h3>
                  <div className="mt-2 flex sm:mt-0 sm:ml-4">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                      onClick={handleCancel}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
                <FieldArray
                  name="students"
                  render={(studentsArrayHelpers) => (
                    <>
                      <div>
                        {values.students && (
                          <div>
                            {values.students.map((student, studentIdx) => (
                              <div
                                key={studentIdx.toString()}
                                className="border border-gray-200 rounded-lg shadow-lg px-4 mb-10 bg-white"
                              >
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor={
                                        "students-" + studentIdx + "-firstName"
                                      }
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("FirstName")}
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        name={
                                          "students[" +
                                          studentIdx +
                                          "].firstName"
                                        }
                                        id={
                                          "students-" +
                                          studentIdx +
                                          "-firstName"
                                        }
                                        type="text"
                                        autoComplete="firstName"
                                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                          errors.students &&
                                          errors.students[studentIdx]?.firstName
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                        }`}
                                      />
                                      {touched.students &&
                                        touched.students[studentIdx]
                                          ?.firstName &&
                                        errors.students &&
                                        errors.students[studentIdx]
                                          ?.firstName && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={
                                              "students-" +
                                              studentIdx +
                                              "-firstName-error"
                                            }
                                          >
                                            {
                                              errors.students[studentIdx]
                                                .firstName
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor={
                                        "students-" + studentIdx + "-midName"
                                      }
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("MidName")}
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        name={
                                          "students[" + studentIdx + "].midName"
                                        }
                                        id={
                                          "students-" + studentIdx + "-midName"
                                        }
                                        type="text"
                                        autoComplete="midName"
                                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                          errors.students &&
                                          errors.students[studentIdx]?.midName
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                        }`}
                                      />
                                      {touched.students &&
                                        touched.students[studentIdx]?.midName &&
                                        errors.students &&
                                        errors.students[studentIdx]
                                          ?.midName && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={
                                              "students-" +
                                              studentIdx +
                                              "-midName-error"
                                            }
                                          >
                                            {
                                              errors.students[studentIdx]
                                                .midName
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor={
                                        "students-" +
                                        studentIdx +
                                        "-phoneNumber"
                                      }
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("PhoneNumber")}
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        name={
                                          "students[" +
                                          studentIdx +
                                          "].phoneNumber"
                                        }
                                        id={
                                          "students-" +
                                          studentIdx +
                                          "-phoneNumber"
                                        }
                                        type="text"
                                        autoComplete="phoneNumber"
                                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                          errors.students &&
                                          errors.students[studentIdx]
                                            ?.phoneNumber
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                        }`}
                                      />
                                      {touched.students &&
                                        touched.students[studentIdx]
                                          ?.phoneNumber &&
                                        errors.students &&
                                        errors.students[studentIdx]
                                          ?.phoneNumber && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={
                                              "students-" +
                                              studentIdx +
                                              "-phoneNumber-error"
                                            }
                                          >
                                            {
                                              errors.students[studentIdx]
                                                .phoneNumber
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor={
                                        "students-" + studentIdx + "-email"
                                      }
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("Email")}
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        name={
                                          "students[" + studentIdx + "].email"
                                        }
                                        id={"students-" + studentIdx + "-email"}
                                        type="text"
                                        autoComplete="email"
                                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                          errors.students &&
                                          errors.students[studentIdx]?.email
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                        }`}
                                      />
                                      {touched.students &&
                                        touched.students[studentIdx]?.email &&
                                        errors.students &&
                                        errors.students[studentIdx]?.email && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={
                                              "students-" +
                                              studentIdx +
                                              "-email-error"
                                            }
                                          >
                                            {errors.students[studentIdx].email}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor={
                                        "students-" + studentIdx + "-password"
                                      }
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("Password")}
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        name={
                                          "students[" +
                                          studentIdx +
                                          "].password"
                                        }
                                        id={
                                          "students-" + studentIdx + "-password"
                                        }
                                        type="password"
                                        autoComplete="password"
                                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                          errors.students &&
                                          errors.students[studentIdx]?.password
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                        }`}
                                      />
                                      {touched.students &&
                                        touched.students[studentIdx]
                                          ?.password &&
                                        errors.students &&
                                        errors.students[studentIdx]
                                          ?.password && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={
                                              "students-" +
                                              studentIdx +
                                              "-password-error"
                                            }
                                          >
                                            {
                                              errors.students[studentIdx]
                                                .password
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="birthDate"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("BirthDate")}
                                    </label>
                                    <div className="mt-1">
                                      <CustomDatePicker
                                        selected={
                                          values.students[studentIdx].birthDate
                                            ? new Date(
                                                values.students[
                                                  studentIdx
                                                ].birthDate
                                              )
                                            : new Date()
                                        }
                                        onChange={(newValue) => {
                                          setTouched({
                                            ...touched,
                                            birthDate: true,
                                          });
                                          setFieldValue(
                                            "students[" +
                                              studentIdx +
                                              "].birthDate",
                                            newValue
                                              ? newValue.toISOString()
                                              : new Date().toISOString
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="password"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      {t("Gender")}
                                    </label>
                                    <div className="mt-1">
                                      <Switch
                                        checked={
                                          values.students[studentIdx].gender ===
                                          "male"
                                        }
                                        onChange={(newValue) => {
                                          if (newValue) {
                                            setTouched({
                                              ...touched,
                                              gender: true,
                                            });
                                            setFieldValue(
                                              "students[" +
                                                studentIdx +
                                                "].gender",
                                              "male"
                                            );
                                          } else {
                                            setTouched({
                                              ...touched,
                                              gender: true,
                                            });
                                            setFieldValue(
                                              "students[" +
                                                studentIdx +
                                                "].gender",
                                              "female"
                                            );
                                          }
                                        }}
                                        className="bg-gray-200 relative inline-flex flex-shrink-0 h-8 w-60 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary shadow-sm"
                                      >
                                        <span
                                          className={`
                                              ${
                                                values.students[studentIdx]
                                                  .gender === "male"
                                                  ? "rtl:-translate-x-28 ltr:translate-x-28"
                                                  : "translate-x-0"
                                              }
                                              pointer-events-none relative inline-block h-8 w-32 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                                        >
                                          <span
                                            className={`
                                                ${
                                                  values.students[studentIdx]
                                                    .gender === "male"
                                                    ? "opacity-0 ease-out duration-100"
                                                    : "opacity-100 ease-in duration-200"
                                                }
                                                absolute inset-0 h-full w-full bg-primary text-white text-sm flex items-center justify-center transition-opacity rounded-2xl`}
                                            aria-hidden="true"
                                          >
                                            {t("Female")}
                                          </span>
                                          <span
                                            className={`
                                                ${
                                                  values.students[studentIdx]
                                                    .gender === "male"
                                                    ? "opacity-100 ease-in duration-200"
                                                    : "opacity-0 ease-out duration-100"
                                                }
                                                absolute inset-0 h-full w-full bg-gray-400 text-white text-sm flex items-center justify-center transition-opacity rounded-2xl
                                              `}
                                            aria-hidden="true"
                                          >
                                            {t("Male")}
                                          </span>
                                        </span>
                                      </Switch>
                                    </div>
                                    {touched.students &&
                                      touched.students[studentIdx]?.gender &&
                                      errors.students &&
                                      errors.students[studentIdx]?.gender && (
                                        <p
                                          className="mt-1 px-4 text-sm text-red-600"
                                          id={
                                            "students-" +
                                            studentIdx +
                                            "-gender-error"
                                          }
                                        >
                                          {errors.students[studentIdx].gender}
                                        </p>
                                      )}
                                  </div>
                                </div>
                                <div className="relative border border-gray-200 rounded-lg shadow-sm px-4 py-8 my-8">
                                  <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block bg-white px-1 text-xl font-semibold text-gray-900">
                                    {t("Parents")}
                                  </h2>
                                  <div className="relative grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                    <div className="sm:col-span-12">
                                      <Combobox
                                        as="div"
                                        name={`students[${studentIdx}].student.studentParent.userId`}
                                        value={
                                          selectedParent.selected[studentIdx] ||
                                          null
                                        }
                                        onChange={(value) => {
                                          handleParentChange(value, studentIdx);

                                          setFieldValue(
                                            "students[" +
                                              studentIdx +
                                              "].student.studentParent.userId",
                                            value.userId
                                          );
                                        }}
                                      >
                                        <Combobox.Label className="block text-sm font-medium text-gray-700">
                                          {t("SelectedParent")}
                                        </Combobox.Label>
                                        <div className="relative mt-1">
                                          <Combobox.Input
                                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-amber-500 focus:outline-none focus:ring-1 focus:ring-amber-500 sm:text-sm"
                                            onChange={(event) =>
                                              searchParents(event.target.value)
                                            }
                                            displayValue={(parent) =>
                                              parent &&
                                              parent?.user.firstName.toUpperCase() +
                                                " " +
                                                parent?.user.midName.toUpperCase() +
                                                " - @" +
                                                parent?.user.email
                                            }
                                          />
                                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                            <ChevronUpDownIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </Combobox.Button>

                                          {parents.length > 0 && (
                                            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                              {parents.map((parent) => (
                                                <Combobox.Option
                                                  key={parent.id}
                                                  value={parent}
                                                  className={({ active }) =>
                                                    classNames(
                                                      "relative cursor-default select-none py-2 pl-3 pr-9",
                                                      active
                                                        ? "bg-amber-100 text-amber"
                                                        : "text-amber-300"
                                                    )
                                                  }
                                                >
                                                  {({ active, selected }) => (
                                                    <>
                                                      <div className="flex items-center">
                                                        <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                                                          <span className="font-medium leading-none text-white">
                                                            {parent.user.firstName
                                                              .substring(0, 1)
                                                              .toUpperCase() +
                                                              parent.user.midName
                                                                .substring(0, 1)
                                                                .toUpperCase()}
                                                          </span>
                                                        </span>
                                                        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                                          <span
                                                            className={classNames(
                                                              "ml-3 truncate",
                                                              selected &&
                                                                "font-semibold"
                                                            )}
                                                          >
                                                            {parent.user.firstName.toUpperCase() +
                                                              " " +
                                                              parent.user.midName.toUpperCase()}
                                                          </span>
                                                          <br />
                                                          <span
                                                            className={classNames(
                                                              "ml-3 truncate text-sm text-gray-400",
                                                              selected &&
                                                                "font-semibold"
                                                            )}
                                                          >
                                                            {parent.user.email +
                                                              " - " +
                                                              parent.user
                                                                .phoneNumber}
                                                          </span>
                                                        </div>
                                                      </div>

                                                      {selected && (
                                                        <span
                                                          className={classNames(
                                                            "absolute inset-y-0 right-0 flex items-center pr-4",
                                                            active
                                                              ? "text-amber"
                                                              : "text-amber-300"
                                                          )}
                                                        >
                                                          <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      )}
                                                    </>
                                                  )}
                                                </Combobox.Option>
                                              ))}
                                            </Combobox.Options>
                                          )}
                                        </div>
                                      </Combobox>
                                    </div>
                                  </div>
                                  {errors.students &&
                                    errors.students[studentIdx]?.student
                                      ?.studentParent?.userId && (
                                      <p
                                        className="mt-1 px-4 text-sm text-red-600"
                                        id={
                                          "students-" +
                                          studentIdx +
                                          "-student-studentParent-userId-error"
                                        }
                                      >
                                        {
                                          errors.students[studentIdx].student
                                            .studentParent.userId
                                        }
                                      </p>
                                    )}
                                </div>
                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    className="my-4 inline-flex items-center justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    onClick={() =>
                                      studentsArrayHelpers.remove(studentIdx)
                                    }
                                  >
                                    <TrashIcon
                                      className="ml-2 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    {t("Remove")}
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 my-4 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          onClick={() =>
                            studentsArrayHelpers.push({
                              code: "006",
                              firstName: "",
                              midName: "",
                              email: "",
                              phoneNumber: "",
                              password: "",
                              gender: "male",
                              birthDate: new Date(),
                              student: {
                                classRoomId: classRoomId,
                                studentParent: {
                                  userId: "",
                                },
                              },
                            })
                          }
                        >
                          <PlusIcon
                            className="ml-2 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          {t("AddNewStudent")}
                        </button>
                      </div>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}
