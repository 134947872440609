import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field } from "formik";
import { logout } from "../../slices/authSlice";
import * as studentService from "../../services/student.services";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export default function CreateParentModal({ open, close, setAddedParent }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    midName: Yup.string().required(t("Required")),
    phoneNumber: Yup.string().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
  });

  const create = async (inputs) => {
    studentService
      .createParents(inputs)
      .then((response) => {
        setAddedParent(response.data.user.userId);
        close();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                      <UserGroupIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {t("CreateParent")}
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: "",
                      midName: "",
                      email: "",
                      phoneNumber: "",
                      password: "",
                      role: "STUDENT_PARENT",
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={ValidationSchema}
                    onSubmit={(values) => create(values)}
                  >
                    {({ touched, errors, values }) => (
                      <Form>
                        <div className="text-right mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="firstName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              {t("FirstName")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                autoComplete="firstName"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                  errors.firstName && errors.firstName
                                    ? "border-2 border-red-700 focus:ring-secondary"
                                    : "focus:border-secondary focus:ring-secondary"
                                }`}
                              />
                              {touched.firstName && errors.firstName && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"firstName-error"}
                                >
                                  {errors.firstName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="midName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              {t("LastName")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="midName"
                                id="midName"
                                autoComplete="midName"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                  errors.midName && errors.midName
                                    ? "border-2 border-red-700 focus:ring-secondary"
                                    : "focus:border-secondary focus:ring-secondary"
                                }`}
                              />
                              {touched.midName && errors.midName && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"midName-error"}
                                >
                                  {errors.midName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="phoneNumber"
                              className="block text-sm font-medium text-gray-700"
                            >
                              {t("PhoneNumber")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                autoComplete="phoneNumber"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                  errors.phoneNumber && errors.phoneNumber
                                    ? "border-2 border-red-700 focus:ring-secondary"
                                    : "focus:border-secondary focus:ring-secondary"
                                }`}
                              />
                              {touched.phoneNumber && errors.phoneNumber && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"phoneNumber-error"}
                                >
                                  {errors.phoneNumber}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              {t("Email")}
                            </label>
                            <div className="mt-1">
                              <Field
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                  errors.email && errors.email
                                    ? "border-2 border-red-700 focus:ring-secondary"
                                    : "focus:border-secondary focus:ring-secondary"
                                }`}
                              />
                              {touched.email && errors.email && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"email-error"}
                                >
                                  {errors.email}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              {t("Password")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="password"
                                name="password"
                                id="password"
                                autoComplete="password"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm focus:border-secondary focus:ring-secondary`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
                          >
                            {t("Save")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="mt-2 sm:mt-2">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                      onClick={close}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
