import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getYear, getMonth } from "date-fns";

export default function RoundsCustomDatePicker({
  selected,
  onChange,
  required,
}) {
  const [domLoaded, setDomLoaded] = useState(false);

  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const years = range(currentYear, currentYear + 70, +1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && (
        <div>
          <DatePicker
            showTimeSelect={false}
            className={`${required} block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm focus:border-secondary focus:ring-secondary`}
            calendarClassName="border-none rounded-lg shadow-md"
            selected={selected}
            onChange={onChange}
            dateFormat="MMMM d, yyyy"
            renderCustomHeader={({ date, changeYear, changeMonth }) => (
              <div className="m-2 flex justify-center">
                <select
                  className="block w-full rounded-md border-gray-300 py-2 ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  className="block w-full rounded-md border-gray-300 py-2 ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
        </div>
      )}
    </>
  );
}
