import React, { Fragment } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";
import * as subjectService from "../../services/subject.services";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";

export default function CreateSubjects() {
  let { classRoomId } = useParams();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const pages = [
    {
      name: t("Subjects"),
      href: `/subjects/${classRoomId}`,
      current: false,
    },
    { name: t("AddSubjects"), href: "#", current: true },
  ];

  const handleSubmit = (inputs) => {
    subjectService
      .createSubjects(inputs)
      .then((response) => {
        if (response.status === 201) navigate(`/subjects/${classRoomId}`);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Create subjects.js:39 ~ handleSubmit ~ error",
          error
        );
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <Formik
              initialValues={{
                subjects: [
                  {
                    classRoomId: classRoomId,
                    name: "",
                  },
                ],
              }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ touched, errors, values, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-xl font-semibold text-gray-900">
                      {t("AddNewGrade")}
                    </h3>
                    <div className="mt-2 flex sm:mt-0 ltr:sm:ml-4 rtl:sm:mr-4">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        onClick={handleCancel}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                  <FieldArray
                    name="subjects"
                    render={(subjectsArrayHelpers) => (
                      <>
                        <div>
                          {values.subjects && (
                            <div>
                              {values.subjects.map((subject, subjectIdx) => (
                                <div
                                  key={subjectIdx.toString()}
                                  className="mt-2 border rounded-lg shadow-lg px-4 py-8 bg-white"
                                >
                                  <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                    <div className="sm:col-span-12">
                                      <label
                                        htmlFor={
                                          "subject-" + subjectIdx + "-name"
                                        }
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("SubjectName")}
                                      </label>

                                      <div className="mt-1">
                                        <Field
                                          autoComplete="subject-name"
                                          name={
                                            "subjects[" + subjectIdx + "].name"
                                          }
                                          type="text"
                                          id={
                                            "subjects-" + subjectIdx + "-name"
                                          }
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${
                                            errors.subjects &&
                                            errors.subjects[subjectIdx]?.name
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                          }`}
                                        />
                                        {errors.grades &&
                                          errors.subjects[subjectIdx]
                                            ?.arabicName && (
                                            <p
                                              className="mt-1 px-4 text-sm text-red-600"
                                              id={
                                                "subjects-" +
                                                subjectIdx +
                                                "-name-error"
                                              }
                                            >
                                              {errors.grades[subjectIdx].name}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex justify-end">
                                    {subjectIdx > 0 && (
                                      <button
                                        type="button"
                                        className="my-4 inline-flex items-center justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                        onClick={() =>
                                          subjectsArrayHelpers.remove(
                                            subjectIdx
                                          )
                                        }
                                      >
                                        <TrashIcon
                                          className="ml-1 mr-1 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        {t("Remove")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end m-12">
                          <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-dark bg-gray-shade py-2 px-4 my-4 text-sm font-medium leading-4 text-dark shadow-sm hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() =>
                              subjectsArrayHelpers.push({
                                classRoomId: classRoomId,
                                name: "",
                              })
                            }
                          >
                            <PlusIcon className="h-5 w-5 mx-2"></PlusIcon>
                            <span>{t("AddSubjects")}</span>
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
}
