import {
  BellIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { logout } from "../../slices/authSlice";
import { useTranslation } from "react-i18next";
import SearchModal from "./Search";
import { useState } from "react";

export default function Header() {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSearch, setShowSearch] = useState(false);

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.log("🚀 ~ file: Login.js:39 ~ handleSubmit ~ error", error);
      });
  };

  const handleLanguage = () => {
    if (window.localStorage.getItem("i18nextLng") === "ar") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ar");
    }
  };

  return (
    <>
      <SearchModal open={showSearch} close={() => setShowSearch(false)} />
      <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
        <div className="flex flex-1 justify-between px-4">
          <div className="flex flex-1">
            <form className="flex w-full md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                {t("Search")}
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <button
                  type="button"
                  onClick={() => setShowSearch(true)}
                  className=" absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center"
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 "
                    aria-hidden="true"
                  />
                </button>
              </div>
            </form>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <button
              type="button"
              onClick={handleLanguage}
              className="rounded-lg bg-white pl-3 pr-3 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-secondary focus:ring-offset-1"
            >
              {window.localStorage.getItem("i18nextLng") === "ar" ? "EN" : "ع"}
            </button>

            <button
              type="button"
              onClick={handleLogout}
              className="mx-4 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
            >
              <span className="sr-only">{"Notifications"}</span>
              <ArrowRightOnRectangleIcon
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
