import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import {
  ArrowUpOnSquareStackIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { Formik, Form } from "formik";
import { logout } from "../../slices/authSlice";
import * as studentServices from "../../services/student.services";
import * as teacherServices from "../../services/teacher.services";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../common/LoadingSpinner";
import ButtonLoadingSpinner from "../common/ButtonLoadingSpinner";
import { useLocation } from "react-router-dom";

export default function UploadSheet({ open, close, classRoomId, success }) {
  // eslint-disable-next-line
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const validationSchema = Yup.object().shape({
    sheet: Yup.mixed()
      .required("Required")
      .test("fileFormat", "Un-supported format, Excel only", (value) => {
        return (
          value &&
          [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ].includes(value.type)
        );
      }),
  });

  const handleSubmit = (inputs) => {
    setUploadLoading(true);
    if (pathname === "/teachers/create") {
      teacherServices
        .UploadSheet(inputs)
        .then((response) => {
          console.log(response);
          setUploadLoading(false);
          success(true);
          close();
        })
        .catch((error) => {
          console.log(error.response);
          setUploadLoading(false);
          success(false);
          close();
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    } else {
      studentServices
        .UploadSheet(classRoomId, inputs)
        .then((response) => {
          console.log(response);
          setUploadLoading(false);
          success(true);
          close();
        })
        .catch((error) => {
          console.log(error.response);
          setUploadLoading(false);
          success(false);
          close();
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                      <ArrowUpOnSquareStackIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {pathname === "/teachers/create"
                          ? t("UploadAndAddNewTeachers")
                          : t("UploadAndAddNewStudents")}
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <div className="mx-auto max-w-lg">
                    <h2 className="rtl:text-right ltr:text-left text-base font-semibold leading-6 text-gray-900">
                      {pathname === "/teachers/create"
                        ? t("FirstDownloadTeachersSheetTemplate")
                        : t("FirstDownloadSheetTemplate")}
                    </h2>
                    <p className="rtl:text-right ltr:text-left mt-2.5 text-lg text-amber-800 font-bold">
                      1- {t("FirstStep")}
                    </p>
                    <ul>
                      <li>
                        <div className="rtl:text-right ltr:text-left group relative flex items-start space-x-3 py-4">
                          <div className="flex-shrink-0">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg">
                              <ArrowDownTrayIcon
                                className="h-6 w-6 text-primary"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div className="text-sm font-medium text-gray-500">
                              {/**https://api.b6lapp.com/institute/add/students/download/sheet/1 */}
                              {pathname === "/teachers/create" ? (
                                <a
                                  href={`https://api.b6lapp.com/institute/add/teachers/download/sheet/${localStorage.getItem(
                                    "instituteId"
                                  )}`}
                                >
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  {t("Downlad")}
                                </a>
                              ) : (
                                <a
                                  href={`https://api.b6lapp.com/institute/add/students/download/sheet/${classRoomId}`}
                                >
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  {t("Downlad")}
                                </a>
                              )}
                            </div>
                            <p className="text-sm">
                              {pathname === "/teachers/create"
                                ? t("NewTeachersSheet")
                                : t("NewStudentsSheet")}
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center">
                            {downloadLoading === false ? (
                              <button className="block w-full text-sm  mr-4 py-2 px-4 rounded-md border-0 font-semibold bg-amber-100 text-amber-700 hover:bg-amber-200">
                                {t("Download")}
                              </button>
                            ) : (
                              <LoadingSpinner />
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="flex flex-col">
                      <p className="rtl:text-right ltr:text-left mt-1 text-lg text-amber-800 font-bold">
                        2- {t("SecondStep")}
                      </p>
                      <ul className="mt-1">
                        <li>
                          <div className="rtl:text-right ltr:text-left items-start text-sm space-x-3 py-2 px-2">
                            {pathname === "/teachers/create"
                              ? t("SaveUploadedTeacherFile")
                              : t("SaveUploadedFile")}{" "}
                            <span className="text-amber-700">
                              {t("SaveFile")}
                            </span>{" "}
                            {t("ReUpload")}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      sheet: null,
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                  >
                    {({
                      touched,
                      errors,
                      setFieldValue,
                      setTouched,
                      values,
                    }) => (
                      <Form>
                        <label className="block mx-auto max-w-lg">
                          <input
                            id="sheet"
                            name="sheet"
                            type="file"
                            className={`block w-full text-sm  file:mr-2 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-xs file:font-semibold ${
                              errors.sheet
                                ? `text-red-500 file:bg-red-100 file:text-red-700 hover:file:bg-red-200`
                                : `text-amber-500 file:bg-amber-100 file:text-amber-700 hover:file:bg-amber-200`
                            }`}
                            onChange={(event) => {
                              setTouched({
                                ...touched,
                                sheet: true,
                              });
                              setFieldValue("sheet", event.target.files[0]);
                            }}
                          />
                        </label>
                        {errors.sheet && (
                          <p
                            className="block mx-auto max-w-lg mt-1 px-4 text-sm text-red-600"
                            id={"sheet-error"}
                          >
                            {errors.sheet}
                          </p>
                        )}

                        <div className="mt-5 sm:mt-6">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                          >
                            {uploadLoading === false ? (
                              t("Continue")
                            ) : (
                              <ButtonLoadingSpinner />
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="mt-2 sm:mt-2">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-stone-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-700"
                      onClick={close}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
