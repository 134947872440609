import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import arabic from "./locals/ar";
import english from "./locals/en";
const resources = {
  ar: {
    translation: arabic,
  },
  en: {
    translation: english,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: window.localStorage.getItem("i18nextLng"),
    fallbackLng: "ar",
    debug: true,
    // Allowed languages
    supportedLngs: ["ar", "en"],
  });

export default i18n;
