import React, { useEffect, useState } from "react";
import Layout from "../../components/root/Layout";
import * as pointsServices from "../../services/points.services";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {
    PencilSquareIcon
} from "@heroicons/react/20/solid";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";

const PointsConfig = () => {
    const [config, setConfig] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);

    const { t } = useTranslation();

    const user = JSON.parse(localStorage.getItem("user"));

    const pointsSchema = Yup.object().shape({
        budget: Yup.string(),
        budgetDays: Yup.string(),
        totalKids: Yup.string(),
        amountPerPoint: Yup.string(),
        pointsExpireAfter: Yup.string(),
    });


    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = async () => {
        await pointsServices
            .getPoints(localStorage.getItem("instituteId"))
            .then((response) => {
                console.log(
                    "🚀 ~ awaitpointsServices.getPoints ~ response:",
                    response.data.data
                );
                if (response.status === 200) {
                    setConfig(response.data.data);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setConfig(
                    {
                        id: 0,
                        budget: 0,
                        budgetDays: "",
                        totalKids: 0,
                        amountsPerPoints: 0,
                        pointsPerAmounts: 0,
                        pointsExpireAfter: null,
                        availablePoints: 0,
                        exchangedPoints: 0,
                        totalPoints: 0,
                    })
                setIsLoading(false);
            });
    };

    const handleSubmit = async (values, setSubmitting) => {
        setSubmitting(true);
        if (config.id !== 0) {
            await pointsServices
                .updatePoint(config.id, values)
                .then((response) => {
                    console.log("🚀 ~ .then ~ response:", response);
                    setSubmitting(false);
                    setSendSuccess(true);
                    setTimeout(() => {
                        // After 3 seconds set the show value to false
                        setSendSuccess(false);
                    }, 5000);
                })
                .catch((error) => {
                    console.log("🚀 ~ handleSubmit ~ error:", error);
                    setSubmitting(false);
                    setSendFailed(true);
                    setTimeout(() => {
                        // After 3 seconds set the show value to false
                        setSendFailed(false);
                    }, 5000);
                });
        } else {
            await pointsServices
                .createPoint(localStorage.getItem("instituteId"), values)
                .then((response) => {
                    setSubmitting(false);
                    setSendSuccess(true);
                    getConfig();
                    setTimeout(() => {
                        // After 3 seconds set the show value to false
                        setSendSuccess(false);
                    }, 5000);
                    console.log(
                        "🚀 ~ awaitpointsServices.createPoint ~ response:",
                        response
                    );
                })
                .catch((error) => {
                    setSubmitting(false);
                    setSendFailed(true);
                    setTimeout(() => {
                        // After 3 seconds set the show value to false
                        setSendFailed(false);
                    }, 5000);
                    console.log("🚀 ~ .then ~ error:", error);
                });
        }
    };

    return (
        <Layout>
            <SuccessAlert show={sendSuccess} message={t("SavedSuccessfully")} />
            <FailedAlert show={sendFailed} message={t("SomethingWentWroing")} />

            <div className="pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-xl font-semibold text-gray-900">
                    {t("PointsConfigs")}
                </h3>

                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-dark bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dark focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        onClick={() => { }}
                    >
                        <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
            {!isLoading && config && (
                <div className="bg-white border border-gray-200 rounded-lg shadow-lg">
                    <div className="p-5">
                        <div className="flex justify-between mb-1">
                            <span className="text-base font-extrabold text-green-400 dark:text-white">{config.availablePoints ?? 0}</span>
                            <span className="text-sm font-extrabold text-red-600 dark:text-white">{config.exchangedPoints ?? 0}</span>
                        </div>
                        <div className={`flex w-full bg-gray-200  h-6`}>
                            <div className={`bg-green-400 h-6`} style={{ width: config.totalPoints > 0 ? String(config.availablePoints * 100 / config.totalPoints) + '%' : 0 + '%' }} >
                                <center>
                                    <p className="text-white">
                                        {config.totalPoints > 0 ? ((config.availablePoints * 100) / config.totalPoints) : 0}%
                                    </p>
                                </center>
                            </div>
                            <div className={` bg-red-600  h-6 dark:bg-red-600`} style={{ width: String(config.exchangedPoints ?? 0 * 100 / config.totalPoints) + '%' }}>
                                <center>
                                    <p className="text-white">
                                        {config.exchangedPoints ?? 0 * 100 / config.totalPoints}%
                                    </p>
                                </center>
                            </div>
                        </div>
                    </div>
                    <dl>
                        <div className=" px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                {t("budget")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {config.budget}
                            </dd>

                            <dt className="text-sm font-medium text-gray-500">
                                {t("budgetDays")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {config.budgetDays}
                            </dd>
                        </div>
                        <div className=" px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500"> {t("totalKids")}</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {config.totalKids}
                            </dd>

                            <dt className="text-sm font-medium text-gray-500">
                                {t("amountsPerPoints")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {`${config.amountsPerPoints} ${t("sar")}`} /  {`${config.pointsPerAmounts} ${t("point")}`}
                            </dd>
                        </div>
                    </dl>
                </div>
            )
            }
            {
                !isLoading && config ? (
                    <Formik
                        initialValues={{
                            budget: config.budget ?? "",
                            budgetDays: config.budgetDays ?? "",
                            totalKids: config.totalKids ?? "",
                            amountsPerPoints: config.amountsPerPoints ?? "",
                            pointsPerAmounts: config.pointsPerAmounts ?? "",
                            pointsExpireAfter: config.pointsExpireAfter ?? ""
                        }}
                        validateOnBlur={true}
                        validateOnChange={true}
                        validationSchema={pointsSchema}
                        onSubmit={(values, { setSubmitting }) =>
                            handleSubmit(values, setSubmitting)
                        }
                    >
                        {({
                            touched,
                            errors,
                            values,
                            setTouched,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <div className="border border-gray-200 rounded-lg shadow-lg p-10 bg-white mt-6">
                                <Form className="space-y-6">
                                    <div className="grid sm:grid-cols-1 grid-cols-1 gap-3">
                                        <div>
                                            <label
                                                htmlFor="budget"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t("budget")}
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    type="text"
                                                    id="budget"
                                                    name="budget"
                                                    className={`block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm ${errors.budget
                                                        ? "border-red-800  focus:border-red-800"
                                                        : " focus:border-secondary focus:ring-secondary"
                                                        }`}
                                                />

                                                {errors.budget && (
                                                    <p
                                                        className="mt-1 px-4 text-sm text-red-600"
                                                        id={"budget-error"}
                                                    >
                                                        {errors.budget}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="totalKids"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t("totalStudents")}
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    type="text"
                                                    id="totalKids"
                                                    name="totalKids"
                                                    className={`block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm ${errors.totalKids
                                                        ? "border-red-800  focus:border-red-800"
                                                        : " focus:border-secondary focus:ring-secondary"
                                                        }`}
                                                />

                                                {errors.totalKids && (
                                                    <p
                                                        className="mt-1 px-4 text-sm text-red-600"
                                                        id={"totalKids-error"}
                                                    >
                                                        {errors.totalKids}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="budgetDays"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t("budgetDays")}
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    type="text"
                                                    id="budgetDays"
                                                    name="budgetDays"
                                                    className={`block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm ${errors.budgetDays
                                                        ? "border-red-800  focus:border-red-800"
                                                        : " focus:border-secondary focus:ring-secondary"
                                                        }`}
                                                />

                                                {errors.budgetDays && (
                                                    <p
                                                        className="mt-1 px-4 text-sm text-red-600"
                                                        id={"budgetDays-error"}
                                                    >
                                                        {errors.budgetDays}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                            <div className="sm:col-span-4">

                                                <Field
                                                    type="text"
                                                    id="amountsPerPoints"
                                                    name="amountsPerPoints"
                                                    className={`block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm ${errors.amountPerPoint
                                                        ? "border-red-800  focus:border-red-800"
                                                        : " focus:border-secondary focus:ring-secondary"
                                                        }`}
                                                />

                                                {errors.amountsPerPoints && (
                                                    <p
                                                        className="mt-1 px-4 text-sm text-red-600"
                                                        id={"amountsPerPoints-error"}
                                                    >
                                                        {errors.amountsPerPoints}
                                                    </p>
                                                )}
                                            </div>
                                            <span className="text-sm font-medium text-gray-700 leading-10">
                                                {t("sar")}
                                            </span>
                                            <span
                                                className="text-sm font-medium text-gray-700  leading-10"
                                            >
                                                /
                                            </span>
                                            <div className="sm:col-span-4">
                                                <Field
                                                    type="text"
                                                    id="pointsPerAmounts"
                                                    name="pointsPerAmounts"
                                                    className={`block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm ${errors.pointsExpireAfter
                                                        ? "border-red-800  focus:border-red-800"
                                                        : " focus:border-secondary focus:ring-secondary"
                                                        }`}
                                                />

                                                {errors.pointsPerAmounts && (
                                                    <p
                                                        className="mt-1 px-4 text-sm text-red-600"
                                                        id={"pointsPerAmounts-error"}
                                                    >
                                                        {errors.pointsPerAmounts}
                                                    </p>
                                                )}
                                            </div>
                                            <span
                                                className=" text-sm font-medium text-gray-700 leading-10"
                                            >
                                                {t("point")}
                                            </span>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="amountPerPoint"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t("pointsExpireAfter")}
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    type="text"
                                                    id="pointsExpireAfter"
                                                    name="pointsExpireAfter"
                                                    className={`block w-full rounded-md border border-gray-300 shadow-sm sm:text-sm ${errors.amountPerPoint
                                                        ? "border-red-800  focus:border-red-800"
                                                        : " focus:border-secondary focus:ring-secondary"
                                                        }`}
                                                />

                                                {errors.pointsExpireAfter && (
                                                    <p
                                                        className="mt-1 px-4 text-sm text-red-600"
                                                        id={"pointsExpireAfter-error"}
                                                    >
                                                        {errors.pointsExpireAfter}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                                        >
                                            {!isSubmitting ? t("Save") : <ButtonLoadingSpinner />}
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                ) : (
                    <LoadingSpinner />
                )
            }
        </Layout >
    );
};

export default PointsConfig;
