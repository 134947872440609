import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

// stats/students/17/104398

const getRoundStatistics = (classRoomId, roundId, groups) => {
  return axios.get(`${base.apiUrl}stats/students/${classRoomId}/${roundId}?groups=${groups}`, {
    headers: headers.authHeader(),
  });
};


const getPointsStatistics = (classRoomId, groups) => {
  return axios.get(`${base.apiUrl}stats/students/${classRoomId}?groups=${groups}`, {
    headers: headers.authHeader(),
  });
};

export { getRoundStatistics, getPointsStatistics };
