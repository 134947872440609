import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getAd = (id) => {
    return axios.get(`${base.apiUrl}institute-ads/${id}`, {
        headers: headers.authHeader(),
    });
};

const getAds = (page, limit) => {
    return axios.get(`${base.apiUrl}institute-ads/${page}/${limit}?instituteId=${localStorage.getItem("instituteId")}`, {
        headers: headers.authHeader(),
    });
};

const createAds = (body) => {
    let formData = new FormData();

    formData.append(`title`, body.title);
    formData.append(`description`, body.description);
    formData.append(`expirAt`, body.expirAt);
    formData.append(`url`, body.url);


    for (let index = 0; index < body.images.length; index++) {
        formData.append(`image`, body.images[index].file);
    }

    for (let index = 0; index < body.classRooms.length; index++) {
        formData.append(`classRooms[${index}][classRoomId]`, body.classRooms[index].classRoomId);
    }

    let ad = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${base.apiUrl}institute-ads/${localStorage.getItem("instituteId")}`,
        headers: headers.authHeaderWithMultipart(),
        data: formData
    };

    return axios.request(ad);
};

const updateAds = (id, body) => {
    //deleteAttachments

    let formData = new FormData();

    formData.append(`title`, body.title);
    formData.append(`description`, body.description);
    formData.append(`expirAt`, body.expirAt);
    formData.append(`url`, body.url);
    body.deleteAttachments.length > 0 && formData.append(`deleteAttachments`, body.deleteAttachments.join(','));


    for (let index = 0; index < body.attachments.length; index++) {
        formData.append(`image`, body.attachments[index].file);
    }

    for (let index = 0; index < body.classRooms.length; index++) {
        formData.append(`classRooms[${index}][classRoomId]`, body.classRooms[index].classRoomId);
    }

    let ad = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${base.apiUrl}institute-ads/${id}`,
        headers: headers.authHeaderWithMultipart(),
        data: formData
    };

    return axios.request(ad);
};

const deleteAd = (id, body) => {
    return axios.delete(`${base.apiUrl}institute-ads/${id}`, {
        headers: headers.authHeader(),
    });
};



export {
    getAd,
    getAds,
    createAds,
    updateAds,
    deleteAd,
};
