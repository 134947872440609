import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as AdsServices from "../../services/ads.services";
import {
  ChevronRightIcon,
  HomeIcon,
  ChevronLeftIcon,
  TrashIcon,
  PlusIcon,
  Square3Stack3DIcon
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import * as teacherServices from "../../services/teacher.services";
import Select from "react-tailwindcss-select";

export default function EditAd() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClassRooms, setSelectedClassRooms] = useState({
    selected: [],
  });
  const [classRooms, setClassRooms] = useState([]);

  const [Ad, setAdData] = useState([]);

  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    AdsServices
      .getAd(id)
      .then((response) => {
        console.log("🚀 ~ .then ~ response.data.ad:", response.data.ad)
        setAdData(response.data.ad);
        const selectedOptions = response.data.ad.classRooms.map(
          (data) => ({
            label: data.classRoom.arabicName,
            value: data.classRoomId,
          })
        );
        setSelectedClassRooms({ selected: selectedOptions });

        setIsLoading(false);
      })
      .catch((error) => { });
  }, [id]);


  useEffect(() => {
    getClassRooms();
    // eslint-disable-next-line
  }, []);

  const getClassRooms = async () => {
    setIsLoading(true);
    try {
      const response = await teacherServices.getClassRooms();
      if (response.status === 200) {
        const options = response.data.grades.map((grade) => ({
          label: grade.arabicName,
          options: grade.classrooms.map((classRoom) => ({
            value: classRoom.id,
            label: classRoom.arabicName,
          })),
        }));

        setClassRooms(options);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handleChange = (value) => {
    if (value != null) {
      if (selectedClassRooms.selected !== undefined) {
        if (selectedClassRooms.selected.length > value.length) {
          selectedClassRooms.selected = value;
        } else {
          if (value.length > 0)
            selectedClassRooms.selected.push(value[value.length - 1]);
          else selectedClassRooms.selected.push(...value);
        }
      } else selectedClassRooms.selected = value;
    } else selectedClassRooms.selected = [];
    setSelectedClassRooms({ selected: selectedClassRooms.selected });
  };


  const update = (inputs) => {
    console.log("🚀 ~ update ~ inputs:", inputs)
    AdsServices
      .updateAds(id, inputs)
      .then((response) => {
        navigate(`/ads/list`);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Required")),
    description: Yup.string().required(t("Required")),
    url: Yup.string().required(t("Required")),
    expirAt: Yup.string().required(t("Required")),
  });

  return (
    <Layout>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              {window.localStorage.getItem("i18nextLng") === "ar" ? (
                <ChevronLeftIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              )}
              <a
                href="#"
                className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current="page"
              >
                {t("EditAd")}
              </a>
            </div>
          </li>
        </ol>
      </nav>
      {!isLoading && (
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 ">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <Formik
                initialValues={{
                  title: Ad.title ?? "",
                  description: Ad.description ?? "",
                  url: Ad.url ?? "",
                  attachments: Ad.attachments?.map(attachment => ({ file: null, url: attachment.path, id: attachment.id })) ?? [],
                  expirAt: Ad.expirAt ?? format(new Date(), "MMMM d, y"),
                  deleteAttachments: [],
                  classRooms: []
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={(values) => update(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                      <h3 className="text-xl font-semibold text-gray-900">
                        {t("EditAd")}
                      </h3>
                    </div>
                    <div className="shadow  sm:rounded-md bg-white">
                      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                          <FieldArray name="attachments">
                            {({ push, remove }) => (
                              <>
                                {values.attachments.map((attachment, index) => (
                                  <div key={index} className="relative group">
                                    <input
                                      id={`attachments.${index}.file`}
                                      name={`attachments.${index}.file`}
                                      type="file"
                                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                      onChange={(event) => {
                                        const file = event.target.files[0];
                                        if (file) {
                                          const url = URL.createObjectURL(file);
                                          setTouched({
                                            ...touched,
                                            attachments: true,
                                          });
                                          setFieldValue(`attachments.${index}.file`, file);
                                          setFieldValue(`attachments.${index}.url`, url);
                                        }
                                      }}
                                    />
                                    {attachment.url ? (
                                      <img src={attachment.url.startsWith('blob:') ? attachment.url : `${apiUrl}institute-ads-image/${attachment.url}`} alt="Preview" className="h-[200px] w-full object-cover rounded-md" />
                                    ) : (
                                      <div className="h-[200px] w-full rounded-md bg-stone-100">
                                        <center>
                                          <Square3Stack3DIcon className="pt-[50px] w-32 h-32 text-yellow-400" />
                                        </center>
                                      </div>
                                    )}
                                    <button
                                      type="button"
                                      onClick={() => {
                                        attachment.id && setFieldValue(`deleteAttachments`, [...values.deleteAttachments, attachment.id]);
                                        remove(index)
                                      }}
                                      className="absolute top-2 right-2 rounded-full bg-red-600 p-1 text-white shadow-sm group-hover:opacity-100 opacity-0 transition-opacity"
                                    >
                                      <TrashIcon className="h-5 w-5" />
                                    </button>
                                  </div>
                                ))}
                                <div className="flex justify-center items-center h-[200px] w-full rounded-md bg-stone-100 cursor-pointer" onClick={() => push({ file: null, url: null })}>
                                  <PlusIcon className="w-12 h-12 text-gray-400" />
                                </div>
                              </>
                            )}
                          </FieldArray>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="col-span-12">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                              {t("adTitle")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="title"
                                id="title"
                                className={`block w-full rounded-md shadow-sm focus:border-secondary sm:text-sm ${errors.title ? "border-2 border-red-500 focus:ring-secondary" : "focus:border-secondary border-gray-400 focus:ring-secondary"}`}
                              />
                              {errors.title && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="title-error">
                                  {errors.title}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-span-12">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                              {t("adDescription")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="description"
                                id="description"
                                as="textarea"
                                rows="3"
                                className={`block w-full rounded-md shadow-sm focus:border-secondary sm:text-sm ${errors.description ? "border-2 border-red-500 focus:ring-secondary" : "focus:border-secondary border-gray-400 focus:ring-secondary"}`}
                              />
                              {errors.description && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="description-error">
                                  {errors.description}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-span-12">
                            <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                              {t("url")}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="url"
                                id="url"
                                className={`block w-full rounded-md shadow-sm focus:border-secondary sm:text-sm ${errors.url ? "border-2 border-red-500 focus:ring-secondary" : "focus:border-secondary border-gray-400 focus:ring-secondary"}`}
                              />
                              {errors.url && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="url-error">
                                  {errors.url}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="sm:col-span-12">
                            {!isLoading && (
                              <>
                                <p className="block text-sm font-medium text-gray-700">
                                  {t("Classes")}
                                </p>
                                <Select
                                  value={selectedClassRooms.selected}
                                  onChange={(value) => {
                                    handleChange(value);
                                    if (value !== null) {
                                      values.classRooms = value.map((classRoom) => ({
                                        classRoomId: classRoom.value,
                                      }));
                                    }
                                  }}
                                  options={classRooms}
                                  isMultiple={true}
                                  formatGroupLabel={(data) => (
                                    <div className={`py-2 text-xs flex items-center justify-between`}>
                                      <span className="font-bold">{data.label}</span>
                                      <span className="h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                        {data.options.length}
                                      </span>
                                    </div>
                                  )}
                                  formatOptionLabel={(data) => (
                                    <li
                                      className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                        ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                        : `bg-amber-100 text-amber-600`
                                        }`}
                                    >
                                      {data.label}
                                    </li>
                                  )}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                          onClick={handleCancel}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
