import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as teacherServices from "../../services/teacher.services";
import Select from "react-tailwindcss-select";
import {
  HomeIcon,
  PlusIcon,
  ArrowUpTrayIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import PagesHeaderNav from "../../components/common/PagesHeaderNav";
import FailedAlert from "../../components/common/FailedAlert";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import SuccessAlert from "../../components/common/SuccessAlert";
import UploadSheet from "../../components/common/UploadSheet";

export default function CreateTeachers() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClassRooms, setSelectedClassRooms] = useState({
    selected: [],
  });
  const [classRooms, setClassRooms] = useState([]);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [uploadSheet, setUploadSheet] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Teachers"), href: "/teachers/list", current: false },
    { name: t("AddNewTeachers"), href: "#", current: true },
  ];

  const handleChange = (value, teacherIdx) => {
    if (value != null) {
      if (selectedClassRooms.selected[teacherIdx] !== undefined) {
        if (selectedClassRooms.selected[teacherIdx].length > 0) {
          for (
            let index = 0;
            index < selectedClassRooms.selected.length;
            index++
          ) {
            if (index === teacherIdx) {
              if (
                selectedClassRooms.selected[teacherIdx].length > value.length
              ) {
                //Delete
                selectedClassRooms.selected[teacherIdx] = value;
              } else {
                //Add
                if (value.length > 0)
                  selectedClassRooms.selected[index].push(
                    value[value.length - 1]
                  );
                else selectedClassRooms.selected[index].push(...value);
              }
            }
          }
        } else selectedClassRooms.selected[teacherIdx] = value;
      } else selectedClassRooms.selected[teacherIdx] = value;
    } else selectedClassRooms.selected[teacherIdx] = [];

    setSelectedClassRooms({ selected: selectedClassRooms.selected });
    console.log("selectedClassRooms:", selectedClassRooms.selected);
  };

  useEffect(() => {
    getClassRooms();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (inputs) => {
    setIsLoading(true);
    teacherServices
      .createTeachers(inputs)
      .then((response) => {
        if (response.status === 201) navigate("/teachers/list");
        else {
          setIsLoading(false);
          setError("ThereIsEmailOrPhoneAlreadyTakenOrSomethingWentWroing");
          setShowError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError("ThereIsEmailOrPhoneAlreadyTakenOrSomethingWentWroing");
        setShowError(true);
      });
  };

  const handleCancel = () => {
    navigate("/teachers/list");
  };

  const getClassRooms = async () => {
    setIsLoading(true);
    try {
      const response = await teacherServices.getClassRooms();
      if (response.status === 200) {
        const options = response.data.grades.map((grade) => ({
          label: grade.arabicName,
          options: grade.classrooms.map((classRoom) => ({
            value: classRoom.id,
            label: classRoom.arabicName,
          })),
        }));

        setClassRooms(options);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const ValidationSchema = Yup.object().shape({
    teachers: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().required(t("Required")),
          midName: Yup.string().required(t("Required")),
          phoneNumber: Yup.string().required(t("Required")),
          password: Yup.string().min(3, "PasswordMin3").required(t("Required")),
        })
      )
      .required(t("Required"))
      .min(1, t("OneRecordMin")),
  });

  const uploadingSuccess = async (success) => {
    if (success) {
      setUploadSuccess(true);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setUploadSuccess(false);
        navigate("/teachers/list");
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    } else {
      setUploadFailed(true);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setUploadFailed(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  };

  // const handleSelectClassRoom = (value) => {
  //   setSelectedClassRooms([...selectedClassRooms, { classRoomId: value }]);
  // };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      <UploadSheet
        open={uploadSheet}
        close={() => setUploadSheet(false)}
        instituteId={localStorage.getItem("instituteId")}
        success={uploadingSuccess}
      />
      <SuccessAlert
        show={uploadSuccess}
        message={t("UploadTeachersCompleted")}
      />
      <FailedAlert show={uploadFailed} message={t("UploadTeachersFailed")} />
      <FailedAlert
        show={showError}
        message={t(error)}
        close={handleCloseError}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <PagesHeaderNav page={page} />
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="mx-auto max-w-12xl px-2 sm:px-3 md:px-4">
            <Formik
              initialValues={{
                teachers: [
                  {
                    code: "005",
                    firstName: "",
                    midName: "",
                    email: "",
                    phoneNumber: "",
                    password: "",
                    teacher: {
                      instituteId: localStorage.getItem("instituteId"),
                      assignedClassRooms: [],
                    },
                  },
                ],
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={ValidationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form>
                  <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-xl font-semibold text-gray-900">
                      {t("AddNewTeachers")}
                    </h3>
                    <div className="mt-2 flex sm:mt-0 sm:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        onClick={handleCancel}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                      >
                        {isLoading === false ? (
                          t("Save")
                        ) : (
                          <ButtonLoadingSpinner />
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={() => setUploadSheet(true)}
                        className="ltr:ml-2 rtl:mr-2 inline-flex items-center justify-center rounded-md border border-secondary bg-white px-4 py-2 ltr:mr-2 rtl:ml-2 text-sm font-medium text-secondary shadow-sm hover:text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
                      >
                        <ArrowUpTrayIcon
                          className="ml-2 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t("UploadExcel")}
                      </button>
                    </div>
                  </div>
                  <div className="border border-gray-200 rounded-lg shadow-lg px-4 mb-10 bg-white">
                    <FieldArray
                      name="teachers"
                      render={(teachersArrayHelpers) => (
                        <>
                          <div>
                            {values.teachers && (
                              <div>
                                {values.teachers.map((grade, teacherIdx) => (
                                  <div key={teacherIdx}>
                                    <label>
                                      {teacherIdx > 0 && (
                                        <button
                                          onClick={() =>
                                            teachersArrayHelpers.remove(
                                              teacherIdx
                                            )
                                          }
                                        >
                                          <MinusCircleIcon className="w-6 h-6 text-red-500" />
                                        </button>
                                      )}
                                    </label>
                                    <div className="mt-1 mb-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                      <div className="sm:col-span-2">
                                        <label
                                          htmlFor={
                                            "teachers-" +
                                            teacherIdx +
                                            "-firstName"
                                          }
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          {t("FirstName")}
                                        </label>
                                        <div className="mt-1">
                                          <Field
                                            name={
                                              "teachers[" +
                                              teacherIdx +
                                              "].firstName"
                                            }
                                            id={
                                              "teachers-" +
                                              teacherIdx +
                                              "-firstName"
                                            }
                                            type="text"
                                            autoComplete="firstName"
                                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.teachers &&
                                              errors.teachers[teacherIdx]
                                                ?.firstName
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                          />
                                          {touched.teachers &&
                                            touched.teachers[teacherIdx]
                                              ?.firstName &&
                                            errors.teachers &&
                                            errors.teachers[teacherIdx]
                                              ?.firstName && (
                                              <p
                                                className="mt-1 px-4 text-sm text-red-600"
                                                id={
                                                  "teachers-" +
                                                  teacherIdx +
                                                  "-firstName-error"
                                                }
                                              >
                                                {
                                                  errors.teachers[teacherIdx]
                                                    .firstName
                                                }
                                              </p>
                                            )}
                                        </div>
                                      </div>

                                      <div className="sm:col-span-2">
                                        <label
                                          htmlFor={
                                            "teachers-" +
                                            teacherIdx +
                                            "-midName"
                                          }
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          {t("LastName")}
                                        </label>
                                        <div className="mt-1">
                                          <Field
                                            name={
                                              "teachers[" +
                                              teacherIdx +
                                              "].midName"
                                            }
                                            id={
                                              "teachers-" +
                                              teacherIdx +
                                              "-midName"
                                            }
                                            type="text"
                                            autoComplete="midName"
                                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.teachers &&
                                              errors.teachers[teacherIdx]
                                                ?.midName
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                          />
                                          {touched.teachers &&
                                            touched.teachers[teacherIdx]
                                              ?.midName &&
                                            errors.teachers &&
                                            errors.teachers[teacherIdx]
                                              ?.midName && (
                                              <p
                                                className="mt-1 px-4 text-sm text-red-600"
                                                id={
                                                  "teachers-" +
                                                  teacherIdx +
                                                  "-midName-error"
                                                }
                                              >
                                                {
                                                  errors.teachers[teacherIdx]
                                                    .midName
                                                }
                                              </p>
                                            )}
                                        </div>
                                      </div>

                                      <div className="sm:col-span-2">
                                        <label
                                          htmlFor={
                                            "teachers-" +
                                            teacherIdx +
                                            "-phoneNumber"
                                          }
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          {t("PhoneNumber")}
                                        </label>
                                        <div className="mt-1">
                                          <Field
                                            name={
                                              "teachers[" +
                                              teacherIdx +
                                              "].phoneNumber"
                                            }
                                            id={
                                              "teachers-" +
                                              teacherIdx +
                                              "-phoneNumber"
                                            }
                                            type="text"
                                            autoComplete="phoneNumber"
                                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.teachers &&
                                              errors.teachers[teacherIdx]
                                                ?.phoneNumber
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                          />
                                          {touched.teachers &&
                                            touched.teachers[teacherIdx]
                                              ?.phoneNumber &&
                                            errors.teachers &&
                                            errors.teachers[teacherIdx]
                                              ?.phoneNumber && (
                                              <p
                                                className="mt-1 px-4 text-sm text-red-600"
                                                id={
                                                  "teachers-" +
                                                  teacherIdx +
                                                  "-phoneNumber-error"
                                                }
                                              >
                                                {
                                                  errors.teachers[teacherIdx]
                                                    .phoneNumber
                                                }
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <label
                                          htmlFor={
                                            "teachers-" + teacherIdx + "-email"
                                          }
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          {t("Email")}
                                        </label>
                                        <div className="mt-1">
                                          <Field
                                            name={
                                              "teachers[" +
                                              teacherIdx +
                                              "].email"
                                            }
                                            id={
                                              "teachers-" +
                                              teacherIdx +
                                              "-email"
                                            }
                                            type="text"
                                            autoComplete="email"
                                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.teachers &&
                                              errors.teachers[teacherIdx]?.email
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                          />
                                          {touched.teachers &&
                                            touched.teachers[teacherIdx]
                                              ?.email &&
                                            errors.teachers &&
                                            errors.teachers[teacherIdx]
                                              ?.email && (
                                              <p
                                                className="mt-1 px-4 text-sm text-red-600"
                                                id={
                                                  "teachers-" +
                                                  teacherIdx +
                                                  "-email-error"
                                                }
                                              >
                                                {
                                                  errors.teachers[teacherIdx]
                                                    .email
                                                }
                                              </p>
                                            )}
                                        </div>
                                      </div>

                                      <div className="sm:col-span-2">
                                        <label
                                          htmlFor={
                                            "teachers-" +
                                            teacherIdx +
                                            "-password"
                                          }
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          {t("Password")}
                                        </label>
                                        <div className="mt-1">
                                          <Field
                                            name={
                                              "teachers[" +
                                              teacherIdx +
                                              "].password"
                                            }
                                            id={
                                              "teachers-" +
                                              teacherIdx +
                                              "-password"
                                            }
                                            type="password"
                                            autoComplete="password"
                                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.teachers &&
                                              errors.teachers[teacherIdx]
                                                ?.password
                                              ? "border-2 border-red-700 focus:ring-secondary"
                                              : "focus:border-secondary focus:ring-secondary"
                                              }`}
                                          />
                                          {touched.teachers &&
                                            touched.teachers[teacherIdx]
                                              ?.password &&
                                            errors.teachers &&
                                            errors.teachers[teacherIdx]
                                              ?.password && (
                                              <p
                                                className="mt-1 px-4 text-sm text-red-600"
                                                id={
                                                  "teachers-" +
                                                  teacherIdx +
                                                  "-password-error"
                                                }
                                              >
                                                {
                                                  errors.teachers[teacherIdx]
                                                    .password
                                                }
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2 ">
                                        {!isLoading && (
                                          <>
                                            <p className="block text-sm font-medium text-gray-700  ">
                                              {t("Classes")}
                                            </p>
                                            <Select
                                              value={
                                                selectedClassRooms.selected[
                                                teacherIdx
                                                ]
                                              }
                                              onChange={(value) => {
                                                //Show selections in the input field
                                                handleChange(value, teacherIdx);
                                                if (value !== null) {
                                                  //Add values to inputs
                                                  values.teachers[
                                                    teacherIdx
                                                  ].teacher = {
                                                    instituteId:
                                                      localStorage.getItem(
                                                        "instituteId"
                                                      ),
                                                    assignedClassRooms:
                                                      value.map(
                                                        (classRoom) => ({
                                                          classRoomId:
                                                            classRoom.value,
                                                        })
                                                      ),
                                                  };
                                                }
                                              }}
                                              options={classRooms}
                                              isMultiple={true}
                                              formatGroupLabel={(data) => (
                                                <div
                                                  className={`py-2 text-xs flex items-center justify-between`}
                                                >
                                                  <span className="font-bold ">
                                                    {data.label}
                                                  </span>
                                                  <span className="h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                                    {data.options.length}
                                                  </span>
                                                </div>
                                              )}
                                              formatOptionLabel={(data) => (
                                                <li
                                                  className={` block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                                    ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                                    : `bg-amber-100 text-amber-600`
                                                    }`}
                                                >
                                                  {data.label}
                                                </li>
                                              )}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="relative border border-gray-200 rounded-lg shadow-sm px-4 py-8 my-8">
                                    <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block bg-white px-1 text-xl font-semibold text-gray-900">
                                      {t("CLassRooms")}
                                    </h2>
                                    <div className="relative grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                      <div className="sm:col-span-12">
                                        {!isLoading && (
                                          <>
                                            <p className="block text-sm font-medium text-gray-700">
                                              {t("ClassRooms")}
                                            </p>
                                            <Select
                                              value={
                                                selectedClassRooms.selected[
                                                  teacherIdx
                                                ]
                                              }
                                              onChange={(value) => {
                                                //Show selections in the input field
                                                handleChange(value, teacherIdx);
                                                if (value !== null) {
                                                  //Add values to inputs
                                                  values.teachers[
                                                    teacherIdx
                                                  ].teacher = {
                                                    instituteId:
                                                      localStorage.getItem(
                                                        "instituteId"
                                                      ),
                                                    assignedClassRooms:
                                                      value.map(
                                                        (classRoom) => ({
                                                          classRoomId:
                                                            classRoom.value,
                                                        })
                                                      ),
                                                  };
                                                }
                                              }}
                                              options={classRooms}
                                              isMultiple={true}
                                              formatGroupLabel={(data) => (
                                                <div
                                                  className={`py-2 text-xs flex items-center justify-between`}
                                                >
                                                  <span className="font-bold ">
                                                    {data.label}
                                                  </span>
                                                  <span className="bg-gray-200 h-5 h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                                    {data.options.length}
                                                  </span>
                                                </div>
                                              )}
                                              formatOptionLabel={(data) => (
                                                <li
                                                  className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                                    !data.isSelected
                                                      ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                                      : `bg-amber-100 text-amber-600`
                                                  }`}
                                                >
                                                  {data.label}
                                                </li>
                                              )}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div> */}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 my-4 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                              onClick={() =>
                                teachersArrayHelpers.push({
                                  code: "005",
                                  firstName: "",
                                  midName: "",
                                  email: "",
                                  phoneNumber: "",
                                  password: "",
                                  teacher: {
                                    instituteId:
                                      localStorage.getItem("instituteId"),
                                    assignedClassRooms: [],
                                  },
                                })
                              }
                            >
                              <PlusIcon
                                className="ml-2 mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              {t("AddNewTeacher")}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
}
