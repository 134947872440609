import { useTranslation } from "react-i18next";

export default function ReportHeader({ reportTitle, doPrint }) {
  const { t } = useTranslation();

  return (
    <div className="pt-5 pr-10 pl-10 border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {reportTitle}
      </h3>
      <div className="mt-3 flex sm:ml-4 sm:mt-0">
        <button
          type="button"
          onClick={() => doPrint()}
          className="ml-3 inline-flex items-center rounded-md bg-amber-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
        >
          {t("Print")}
        </button>
        <button
          type="button"
          className="ml-3 inline-flex items-center rounded-md bg-amber-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
        >
          {t("Download")}
        </button>
      </div>
    </div>
  );
}
