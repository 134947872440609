import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const token = localStorage.getItem("token");

const getSchedule = (classRoomId) => {
  return axios.get(`${base.apiUrl}schedule/class-room/${classRoomId}`, {
    headers: headers.authHeader(),
  });
};

const createSchedule = (classRoomId, body) => {
  let formData = new FormData();
  formData.append("image", body.image);

  return axios.post(`${base.apiUrl}schedule/${classRoomId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateSchedule = (id, body) => {
  let formData = new FormData();
  formData.append("image", body.image);

  return axios.put(`${base.apiUrl}schedule/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export { getSchedule, createSchedule, updateSchedule };
