import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

// const instance = axios.create({
//   baseURL: base.apiUrl,
//   timeout: 1000,
//   headers: headers.authHeader(),
// });

const getTeachers = (page, limit) => {
  return axios.get(
    `${base.apiUrl}institute/teachers/${localStorage.getItem(
      "instituteId"
    )}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getTeacher = (id, page, limit) => {
  return axios.get(`${base.apiUrl}institute/teacher/${id}`, {
    headers: headers.authHeader(),
  });

  // return instance.get(`institute/teacher/${id}`);
};
const getClassRooms = () => {
  return axios.get(
    `${base.apiUrl}institute/teacher/${localStorage.getItem(
      "instituteId"
    )}/assigning-class-rooms`,
    {
      headers: headers.authHeader(),
    }
  );

  // return instance.get(
  //   `institute/teacher/${localStorage.getItem(
  //     "instituteId"
  //   )}/assigning-class-rooms`
  // );
};

const createTeachers = (body) => {
  return axios.post(`${base.apiUrl}institute/teachers`, body, {
    headers: headers.authHeader(),
  });

  // return instance.post(`institute/teachers`, body);
};

const updateTeacher = (id, body) => {
  return axios.put(`${base.apiUrl}institute/teacher/${id}`, body, {
    headers: headers.authHeader(),
  });

  // return instance.put(`institute/teacher/${id}`, body);
};

const deleteTeacher = (id) => {
  return axios.delete(`${base.apiUrl}institute/teacher/${id}`, {
    headers: headers.authHeader(),
  });

  // return instance.delete(`institute/teacher/${id}`);
};

const UploadSheet = (body) => {
  let data = new FormData();
  data.append("sheet", body.sheet);

  return axios({
    method: "post",
    url: `${
      base.apiUrl
    }institute/add/teachers/read/sheet/${localStorage.getItem("instituteId")}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const DeleteAssignedClassRoom = (teacherId, classRoomId) => {
  console.log(
    "🚀 ~ file: teacher.services.js:87 ~ DeleteAssignedClassRoom ~ teacherId, classRoomId:",
    teacherId,
    classRoomId
  );
  return axios.delete(
    `${base.apiUrl}institute/remove-assigned-class-rooms/${teacherId}/${classRoomId}`,
    {
      headers: headers.authHeader(),
    }
  );
};

export {
  getTeachers,
  getTeacher,
  createTeachers,
  updateTeacher,
  deleteTeacher,
  getClassRooms,
  UploadSheet,
  DeleteAssignedClassRoom,
};
