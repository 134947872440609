import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  PlusIcon,
  PencilIcon,
  DocumentIcon,
  TrashIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as teacherServices from "../../services/teacher.services";
import DeleteItemAlert from "../../components/common/DeleteItemAlert";
import SuccessAlert from "../../components/common/SuccessAlert";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import FailedAlert from "../../components/common/FailedAlert";
import EditClassRoomsModal from "../../components/teacher/EditClassRoomsModal";
import LoadingOver from "../../components/common/LoadingOver";

export default function TeachersList() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletedItem, setDeletedItem] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [editClassRooms, setEditClassRooms] = useState(false);
  const [editSupervisedClassRooms, setEditSupervisedClassRooms] =
    useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [editFailed, setEditFailed] = useState(false);
  const [deleteClassRoomAlert, setDeleteClassRoomAlert] = useState(false);
  const [deletedClassRoom, setDeletedClassRoom] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teacherId, setTeacherId] = useState(null);

  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getItems(1);
    // eslint-disable-next-line
  }, []);

  const getItems = async (page) => {
    setIsLoading(true);
    try {
      const response = await teacherServices.getTeachers(page, limit);
      if (response.status === 200) {
        console.log("🚀 ~ file: List.js:54 ~ getItems ~ response:", response);
        setRows(response.data.teachers.rows);
        setCount(response.data.teachers.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await teacherServices.getTeachers(page, limit);
      if (response.status === 200) {
        setRows(response.data.teachers.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const alertDelete = async (id) => {
    setDeletedItem(id);
    setDeleteAlert(true);
  };

  const deleteItem = async () => {
    setIsLoading(true);
    setDeleteAlert(false);
    try {
      const response = await teacherServices.deleteTeacher(deletedItem);
      if (response.status === 200) {
        getItems(1);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const updateClassRooms = async (supervised) => {
    setLoading(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      if (supervised) setEditSupervisedClassRooms(true);
      else setEditClassRooms(true);
      setLoading(false);
    }, 1000);
  };

  const classRoomsUpdateSuccess = async (success) => {
    if (success) {
      getItems(1);
      setEditSuccess(true);
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setEditSuccess(false);
      }, 5000);
    } else {
      setEditFailed(true);
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setEditFailed(false);
      }, 5000);
    }
  };

  const alertDeleteClassRoom = async (teacherID, id) => {
    console.log("teacherID, id:", teacherID, id);
    setTeacherId(teacherID);
    setDeletedClassRoom(id);
    setDeleteClassRoomAlert(true);
  };

  const deleteClassRoom = async () => {
    setLoading(true);
    setDeleteClassRoomAlert(false);
    try {
      const response = await teacherServices.DeleteAssignedClassRoom(
        teacherId,
        deletedClassRoom
      );
      console.log("response: ", response);
      if (response.status === 200) {
        setEditSuccess(true);
        getItems(1);
        setLoading(false);
      }
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setEditSuccess(false);
      }, 5000);
    } catch (error) {
      console.log("🚀 ~ file: List.js:154 ~ deleteClassRoom ~ error:", error);
      setLoading(false);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("UserName")}
        </div>
      ),
      selector: (row) => (
        <div
          className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 hover:cursor-pointer"
          onClick={() => navigate(`/teacher/details/${row.id}`)}
        >
          {row.user.firstName + " " + row.user.midName}
          <br />
          <p className="text-sm text-gray-400">{row.user.code}</p>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("Email")}
        </div>
      ),
      selector: (row) => (
        <div
          onClick={() => navigate(`/teacher/details/${row.id}`)}
          className="hover:cursor-pointer"
        >
          {row.user.email}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("PhoneNumber")}
        </div>
      ),
      selector: (row) => (
        <div
          onClick={() => navigate(`/teacher/details/${row.id}`)}
          className="hover:cursor-pointer"
        >
          {row.user.phoneNumber}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("SupervisedCLassRooms")}
        </div>
      ),
      selector: (row) =>
        row.assignedClassRooms.filter(
          (classRoom) => classRoom.supervised === true
        ).length > 0 ? (
          <div key={"classes-" + row.id}>
            <button
              className="inline-flex hover:bg-gray-300 bg-gray-100 text-gray-500 px-2.5 py-1 rounded-full"
              onClick={() => {
                setSelectedRow(row.id);
                updateClassRooms(true);
              }}
            >
              <PencilIcon className="w-4 h-4 text-gray-500" />
            </button>
            {row.assignedClassRooms.map(
              (item) =>
                item.supervised && (
                  <label key={item.id}>
                    <span className="inline-flex mx-1 items-center text-xs font-medium">
                      <dd className="  bg-amber-100  text-amber-500 px-2.5 py-1 rounded-full">
                        {item.classRoom.instituteGrade.arabicName} -{" "}
                        {item.classRoom.arabicName}
                      </dd>
                    </span>
                    <button
                      onClick={() =>
                        alertDeleteClassRoom(item.teacherId, item.classRoomId)
                      }
                    >
                      <MinusCircleIcon className=" w-4 h-4 text-red-500" />
                    </button>
                  </label>
                )
            )}
          </div>
        ) : (
          <center>
            <button
              className="inline-flex hover:bg-gray-300 bg-gray-100 text-gray-500 px-2.5 py-1 rounded-full"
              onClick={() => {
                setSelectedRow(row.id);
                updateClassRooms(true);
              }}
            >
              <PencilIcon className="w-4 h-4 text-gray-500" />
            </button>

            <span className="px-1 inline-flex items-center text-xs font-medium">
              <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                {t("NotAssignedyet")}
              </dd>
            </span>
          </center>
        ),
      sortable: true,
      width: "500px",
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("AssignedCLassRooms")}
        </div>
      ),
      selector: (row) =>
        row.assignedClassRooms.length > 0 ? (
          <div key={"classes-" + row.id}>
            <button
              className="inline-flex hover:bg-gray-300 bg-gray-100 text-gray-500 px-2.5 py-1 rounded-full"
              onClick={() => {
                setSelectedRow(row.id);
                updateClassRooms(false);
              }}
            >
              <PencilIcon className="w-4 h-4 text-gray-500" />
            </button>
            {row.assignedClassRooms.map((item) => (
              <label key={item.id}>
                <span className="inline-flex mx-1 items-center text-xs font-medium">
                  <dd className="  bg-amber-100  text-amber-500 px-2.5 py-1 rounded-full">
                    {item.classRoom.instituteGrade.arabicName} -{" "}
                    {item.classRoom.arabicName}
                  </dd>
                </span>
                <button
                  onClick={() =>
                    alertDeleteClassRoom(item.teacherId, item.classRoomId)
                  }
                >
                  <MinusCircleIcon className=" w-4 h-4 text-red-500" />
                </button>
              </label>
            ))}
          </div>
        ) : (
          <center>
            <button
              className="inline-flex hover:bg-gray-300 bg-gray-100 text-gray-500 px-2.5 py-1 rounded-full"
              onClick={() => {
                setSelectedRow(row.id);
                updateClassRooms(false);
              }}
            >
              <PencilIcon className="w-4 h-4 text-gray-500" />
            </button>

            <span className="px-1 inline-flex items-center text-xs font-medium">
              <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                {t("NotAssignedyet")}
              </dd>
            </span>
          </center>
        ),
      sortable: true,
      width: "500px",
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      cellExport: (row) => row.null,
      selector: (row) => (
        <div key={row.id}>
          <button
            className="inline-flex m-1 items-center  p-3 justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() => navigate(`/teacher/edit/${row.id}`)}
          >
            <span>
              <PencilIcon
                className="h-5 w-5"
                stroke="currentColor"
                aria-hidden="true"
              ></PencilIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3  justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() => navigate(`/teacher/details/${row.id}`)}
          >
            <span>
              <DocumentIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></DocumentIcon>
            </span>
          </button>
          <button
            className="inline-flex m-1 items-center p-3 justify-center bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-red-500"
            onClick={() => alertDelete(row.id)}
          >
            <span>
              <TrashIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></TrashIcon>
            </span>
          </button>
        </div>
      ),
      sortable: true,
      width: "190px",
    },
  ];

  return (
    <>
      {loading && <LoadingOver />}
      <SuccessAlert show={editSuccess} message={t("changesSaved")} />
      <FailedAlert show={editFailed} message={t("changesNotSaved")} />
      <EditClassRoomsModal
        open={editClassRooms}
        close={() => setEditClassRooms(false)}
        id={selectedRow}
        success={classRoomsUpdateSuccess}
        supervised={false}
      />

      <EditClassRoomsModal
        open={editSupervisedClassRooms}
        close={() => setEditSupervisedClassRooms(false)}
        id={selectedRow}
        success={classRoomsUpdateSuccess}
        supervised={true}
      />
      <DeleteItemAlert
        show={deleteAlert}
        confirm={deleteItem}
        cancel={() => setDeleteAlert(false)}
      />
      <DeleteItemAlert
        show={deleteClassRoomAlert}
        confirm={deleteClassRoom}
        cancel={() => setDeleteClassRoomAlert(false)}
      />
      <Layout>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("Teachers")}
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => navigate("/teachers/create")}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:w-auto"
            >
              <PlusIcon className="ml-2 mr-2 h-4 w-4" aria-hidden="true" />
              {t("AddNewTeachers")}
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white">
                    {/* Grades List Table */}
                    {/* <DataTableExtensions {...tableData}> */}
                    <DataTable
                      columns={columns}
                      data={rows}
                      progressPending={isLoading}
                      Header
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationTotalRows={count}
                      onChangeRowsPerPage={getNextItems}
                      onChangePage={getItems}
                      noDataComponent={<NoData />}
                      progressComponent={<LoadingData />}
                    />
                    {/* </DataTableExtensions> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
