import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const token = localStorage.getItem("token");

const login = (body) => {
  return axios.post(`${base.apiUrl}auth/token`, body);
};

const register = (body) => {
  return axios.post(`${base.apiUrl}auth/register`, body);
};

const completeRegister = (body) => {
  return axios.post(`${base.apiUrl}institute`, body, {
    headers: headers.authHeader(),
  });
};

const updateInstitute = (body) => {
  return axios.put(`${base.apiUrl}institute/${localStorage.getItem(
    "instituteId"
  )}`, body, {
    headers: headers.authHeader(),
  });
};

const requestResetPassword = (email) => {
  return axios.get(
    `${base.apiUrl}auth/request-forget-password-confirmation-token/email/${email}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const resetPassword = (token, body) => {
  return axios.post(`${base.apiUrl}auth/reset-password`, body, {
    headers: headers.resetPasswordHeader(token),
  });
};

const changePassword = (body) => {
  return axios.post(`${base.apiUrl}auth/confirm-email`, body, {
    headers: headers.authHeader(),
  });
};

const confirmEmail = (body) => {
  return axios.post(`${base.apiUrl}auth/confirm-email`, body, {
    headers: headers.authHeader(),
  });
};

const getInfo = () => {
  return axios.get(`${base.apiUrl}auth/info`, {
    headers: headers.authHeader(),
  });
};

const updateInfo = (body) => {
  console.log("🚀 ~ file: auth.services.js:53 ~ updateInfo ~ body:", body);
  let formData = new FormData();
  formData.append("avatar", body.avatar);
  formData.append("firstName", body.firstName);
  formData.append("midName", body.midName);
  formData.append("phoneNumber", body.phoneNumber);
  formData.append("email", body.email);

  return axios.put(`${base.apiUrl}auth/update-info`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("instituteId");
};

const authServices = {
  login,
  register,
  completeRegister,
  updateInstitute,
  requestResetPassword,
  resetPassword,
  changePassword,
  confirmEmail,
  getInfo,
  updateInfo,
  logout,
};

export default authServices;
