import SideBar from "./SideBar";
import Header from "./Header";
// import Container from "./Container";
// import Breadcrumb from "../Breadcrumb";

const LayoutMiddle = ({ children }) => {
  return (
    <div className="min-h-screen bg-slate-50">
      <SideBar />
      <div className="flex flex-1 flex-col rtl:lg:pr-64 ltr:lg:pl-64">
        <Header />
        <main className="flex-1">
          <div className="py-6">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LayoutMiddle;
