import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as groupServices from "../../services/group.services";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import Layout from "../../components/root/Layout";
import { DocumentIcon } from "@heroicons/react/20/solid";
import GroupStudentsModal from "../../components/teacher/GroupStudentsModal";
import { HomeIcon } from "@heroicons/react/20/solid";
import PagesHeaderNav from "../../components/common/PagesHeaderNav";
import { useLocation } from "react-router-dom";

const SubjectsGroup = () => {
  const [group, setGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const { t, i18n } = useTranslation();

  const { id } = useParams();

  // Get the location object using the useLocation hook
  const location = useLocation();

  // Extract query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter
  const teacherId = queryParams.get("teacherId");

  const showModal = (id) => {
    setOpen(true);
    setGroupId(id);
  };

  const pages = [
    { name: t("Teachers"), href: "/teachers/list", current: false },
    {
      name: t("TeacherDetails"),
      href: `/teacher/details/${teacherId}`,
      current: true,
    },
    { name: t("subjectsGroup"), href: "#", current: true },
  ];

  useEffect(() => {
    getGroup(1);
    // eslint-disable-next-line
  }, []);

  const getGroup = async (page) => {
    await groupServices
      .getSubjectsGroup(id, page, limit)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setGroup(response.data.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ awaitgroupServices.getGroup ~ error:", error);
        setIsLoading(false);
      });
  };

  const getNextItems = async (page, limit) => {
    setIsLoading(true);
    await groupServices
      .getSubjectsGroup(id, page, limit)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setGroup(response.data.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ awaitgroupServices.getGroup ~ error:", error);
        setIsLoading(false);
      });
  };

  const columns = [
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("groupName")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {row.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      cellExport: (row) => row.null,
      selector: (row) => (
        <div key={row.id}>
          <button
            type="button"
            className="inline-flex m-1 items-center p-3  justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            onClick={() => showModal(row.id)}
          >
            <span>
              <DocumentIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></DocumentIcon>
            </span>
          </button>
        </div>
      ),
      sortable: true,
      width: "190px",
    },
  ];

  return (
    <Layout>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <PagesHeaderNav page={page} />
            </li>
          ))}
        </ol>
      </nav>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="min-w-full divide-y divide-gray-300">
                <div className="divide-y divide-gray-200 bg-white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {groupId !== null && (
        <GroupStudentsModal
          open={open}
          setOpen={setOpen}
          groupId={groupId}
          setGroupId={setGroupId}
        />
      )}
      <h2 className=" mt-2 ltr:left-4 rtl:right-4 bg-white px-1 text-xl font-semibold text-gray-900">
        {t("subjectGroup")}
      </h2>
      <div className="bg-white  border border-gray-200 rounded-lg shadow-lg px-4 py-8 my-8">
        {group && (
          <DataTable
            columns={columns}
            data={group}
            progressPending={isLoading}
            Header
            pagination
            paginationServer
            highlightOnHover
            paginationTotalRows={count}
            onChangeRowsPerPage={getNextItems}
            onChangePage={getGroup}
            noDataComponent={<NoData />}
            progressComponent={<LoadingData />}
          />
        )}
      </div>
    </Layout>
  );
};

export default SubjectsGroup;
