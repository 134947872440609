import React, { Fragment, useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";
import * as GiftCardservice from "../../services/giftcard.services";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HomeIcon,
  TrashIcon,
  PencilIcon,
  GiftIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FailedAlert from "../../components/common/FailedAlert";
import { format } from "date-fns";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import GiftCardPreviewImage from "../../components/giftcards/GiftCardPreviewImage";

export default function CreateGiftCards() {
  const { t } = useTranslation();
  const [createFailed, setCreateFailed] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([""]); // Initialize with an empty string for each card

  let navigate = useNavigate();
  const pages = [
    {
      name: t("GiftCards"),
      href: `/giftCards/`,
      current: false,
    },
    { name: t("AddGiftCards"), href: "#", current: true },
  ];

  const ValidationSchema = Yup.object().shape({
    cards: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required(t("Required")),
          type: Yup.string().required(t("Required")),
          quantity: Yup.string().required(t("Required")),
          expireAt: Yup.string().required(t("Required")),
          points: Yup.string().required(t("Required")),
          exchangeInstruction: Yup.string().required(t("Required")),
        })
      )
      .required(t("Required"))
      .min(1, t("OneRecordMin")),
  });

  const handleSubmit = (inputs) => {
    const sanitizedInputs = {
      cards: inputs.cards.map((card) => {
        if (card.type === "EXCHANGE") {
          const { codes, ...rest } = card;
          return rest;
        }
        return card;
      }),
    };
    GiftCardservice
      .createGiftCards(sanitizedInputs)
      .then((response) => {
        if (response.status === 201) navigate(`/giftCards/list`);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Create GiftCards.js:39 ~ handleSubmit ~ error",
          error
        );
        setCreateFailed(true);
        const timeId = setTimeout(() => {
          setCreateFailed(false);
        }, 5000);
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleTypeChange = (event, cardIdx, setFieldValue) => {
    const newSelectedTypes = [...selectedTypes];
    newSelectedTypes[cardIdx] = event.target.value;
    setSelectedTypes(newSelectedTypes);
    setFieldValue(`cards[${cardIdx}].type`, event.target.value);
    setFieldValue(`cards[${cardIdx}].quantity`, 1); // Reset quantity to 1 when type changes
  };

  const incrementQuantity = (cardIdx, setFieldValue, currentQuantity) => {
    setFieldValue(`cards[${cardIdx}].quantity`, currentQuantity + 1);
  };

  return (
    <>
      <FailedAlert show={createFailed} message={t("createFailed")} />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <Formik
              initialValues={{
                cards: [
                  {
                    instituteId: localStorage.getItem("instituteId"),
                    title: "",
                    type: "",
                    quantity: 1,
                    expireAt: format(new Date(), "MMMM d, y"),
                    points: 1,
                    exchangeInstruction: "",
                    currency: "SAR",
                    image: null,
                    codes: [{ code: "" }],
                  },
                ],
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={ValidationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ touched, setTouched, errors, values, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                    <h3 className="text-xl font-semibold text-gray-900">{t("AddNewGiftCard")}</h3>
                    <div className="mt-2 flex sm:mt-0 ltr:sm:ml-4 rtl:sm:mr-4">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        onClick={handleCancel}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                  <FieldArray
                    name="cards"
                    render={(GiftCardsArrayHelpers) => (
                      <>
                        <div>
                          {values.cards && (
                            <div>
                              {values.cards.map((card, cardIdx) => (
                                <div
                                  key={cardIdx.toString()}
                                  className="mt-2 border rounded-lg shadow-lg px-4 py-8 bg-white"
                                >
                                  <div className="flex justify-center min-w-full">
                                    <div className="relative">
                                      <button
                                        type="button"
                                        className="absolute mt-40 ml-2 mr-2 rounded-full bg-amber-400 p-1 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                                      >
                                        <label htmlFor={"cards-" + cardIdx + "-.image"}>
                                          <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                        </label>
                                      </button>
                                      {card.image ? (
                                        <GiftCardPreviewImage file={card.image} />
                                      ) : (
                                        <div className="h-[200px] w-[420px] rounded-md bg-stone-100">
                                          <center>
                                            <GiftIcon className="pt-[50px] w-32 h-32 text-yellow-400" />
                                          </center>
                                        </div>
                                      )}
                                      <input
                                        id={"cards-" + cardIdx + "-.image"}
                                        name={"cards[" + cardIdx + "].image"}
                                        type="file"
                                        className="sr-only"
                                        onChange={(event) => {
                                          setTouched({ ...touched, image: true });
                                          setFieldValue("cards[" + cardIdx + "].image", event.target.files[0]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor={"cards-" + cardIdx + "-type"}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("type")}
                                      </label>
                                      <div className="mt-1">
                                        <Field
                                          as="select"
                                          id={"cards-" + cardIdx + "-type"}
                                          name={"cards[" + cardIdx + "].type"}
                                          className={`block w-full border-1 rounded-md shadow-sm focus:border-secondary ${errors.period
                                            ? "border-1 border-red-800  focus:border-red-800"
                                            : "border border-gray-300 focus:border-secondary focus:ring-secondary"
                                            }`}
                                          onChange={(event) => handleTypeChange(event, cardIdx, setFieldValue)}
                                        >
                                          <option value="">{t("Select")}</option>
                                          <option value="REDEEM">{t("RedeemCard")}</option>
                                          <option value="EXCHANGE">{t("ExchangeCard")}</option>
                                        </Field>
                                        {errors.cards && errors.cards[cardIdx]?.type && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={"cards-" + cardIdx + "-type-error"}
                                          >
                                            {errors.cards[cardIdx].type}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor={"cards-" + cardIdx + "-title"}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("title")}
                                      </label>

                                      <div className="mt-1">
                                        <Field
                                          autoComplete="title"
                                          name={"cards[" + cardIdx + "].title"}
                                          type="text"
                                          id={"cards-" + cardIdx + "-title"}
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.cards && errors.cards[cardIdx]?.title
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                            }`}
                                        />
                                        {errors.cards && errors.cards[cardIdx]?.title && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={"cards-" + cardIdx + "-title-error"}
                                          >
                                            {errors.cards[cardIdx].title}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor={"cards-" + cardIdx + "-quantity"}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("quantity")}
                                      </label>
                                      <div className="mt-1">
                                        <Field
                                          autoComplete="quantity "
                                          name={"cards[" + cardIdx + "].quantity"}
                                          type="text"
                                          id={"cards-" + cardIdx + "-quantity"}
                                          disabled
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.cards && errors.cards[cardIdx]?.quantity
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                            }`}
                                        />
                                        {errors.cards && errors.cards[cardIdx]?.quantity && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={"cards-" + cardIdx + "-quantity -error"}
                                          >
                                            {errors.cards[cardIdx].quantity}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor={"cards-" + cardIdx + "-points"}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("points")}
                                      </label>

                                      <div className="mt-1">
                                        <Field
                                          autoComplete="points"
                                          name={"cards[" + cardIdx + "].points"}
                                          type="text"
                                          id={"cards-" + cardIdx + "-points"}
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.cards && errors.cards[cardIdx]?.points
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                            }`}
                                        />
                                        {errors.cards && errors.cards[cardIdx]?.points && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={"cards-" + cardIdx + "-points-error"}
                                          >
                                            {errors.cards[cardIdx].points}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor={"cards-" + cardIdx + "-expireAt"}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("expireAt")}
                                      </label>

                                      <div className="mt-1">
                                        <CustomDatePicker
                                          selected={
                                            values.cards[cardIdx].expireAt
                                              ? new Date(values.cards[cardIdx].expireAt)
                                              : new Date()
                                          }
                                          onChange={(newValue) => {
                                            setTouched({ ...touched, expireAt: true });
                                            setFieldValue(
                                              "cards[" + cardIdx + "].expireAt",
                                              newValue ? newValue.toISOString() : new Date().toISOString
                                            );
                                          }}
                                        />
                                        {errors.cards && errors.cards[cardIdx]?.expireAt && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={"cards-" + cardIdx + "-expireAt-error"}
                                          >
                                            {errors.cards[cardIdx].expireAt}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sm:col-span-12">
                                      <label
                                        htmlFor={"cards-" + cardIdx + "-exchangeInstruction"}
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        {t("exchangeInstruction")}
                                      </label>

                                      <div className="mt-1">
                                        <Field
                                          autoComplete="exchangeInstruction"
                                          as="textarea"
                                          rows="2"
                                          name={"cards[" + cardIdx + "].exchangeInstruction"}
                                          type="text"
                                          id={"cards-" + cardIdx + "-exchangeInstruction"}
                                          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.cards && errors.cards[cardIdx]?.exchangeInstruction
                                            ? "border-2 border-red-700 focus:ring-secondary"
                                            : "focus:border-secondary focus:ring-secondary"
                                            }`}
                                        />
                                        {errors.cards && errors.cards[cardIdx]?.exchangeInstruction && (
                                          <p
                                            className="mt-1 px-4 text-sm text-red-600"
                                            id={"cards-" + cardIdx + "-exchangeInstruction-error"}
                                          >
                                            {errors.cards[cardIdx].exchangeInstruction}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    {selectedTypes[cardIdx] === "REDEEM" && (
                                      <div className="sm:col-span-12">
                                        <FieldArray
                                          name={`cards[${cardIdx}].codes`}
                                          render={(CodesArrayHelpers) => (
                                            <div>
                                              <label className="block text-sm font-medium text-gray-700">
                                                {t("Codes")}
                                              </label>
                                              {card.codes && card.codes.length > 0 ? (
                                                card.codes.map((code, codeIdx) => (
                                                  <div key={codeIdx} className="flex items-center mt-1">
                                                    <Field
                                                      name={`cards[${cardIdx}].codes[${codeIdx}].code`}
                                                      placeholder={t("Code")}
                                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm"
                                                    />
                                                    <button
                                                      type="button"
                                                      className="ml-2 inline-flex items-center justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                                      onClick={() => {
                                                        CodesArrayHelpers.remove(codeIdx);
                                                        setFieldValue(
                                                          `cards[${cardIdx}].quantity`,
                                                          values.cards[cardIdx].quantity - 1
                                                        );
                                                      }}
                                                    >
                                                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                  </div>
                                                ))
                                              ) : (
                                                <div>{t("NoCodes")}</div>
                                              )}
                                              <button
                                                type="button"
                                                className="mt-2 inline-flex items-center justify-center rounded-md border border-dark bg-gray-shade py-2 px-4 text-sm font-medium leading-4 text-dark shadow-sm hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                                onClick={() => {
                                                  CodesArrayHelpers.push({ code: "" });
                                                  incrementQuantity(cardIdx, setFieldValue, values.cards[cardIdx].quantity);
                                                }}
                                              >
                                                <PlusIcon className="h-5 w-5 mx-2" />
                                                <span>{t("AddCode")}</span>
                                              </button>
                                            </div>
                                          )}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex justify-end">
                                    {cardIdx > 0 && (
                                      <button
                                        type="button"
                                        className="my-4 inline-flex items-center justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                        onClick={() => GiftCardsArrayHelpers.remove(cardIdx)}
                                      >
                                        <TrashIcon className="ml-1 mr-1 h-5 w-5" aria-hidden="true" />
                                        {t("Remove")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end m-12">
                          <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-dark bg-gray-shade py-2 px-4 my-4 text-sm font-medium leading-4 text-dark shadow-sm hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() =>
                              GiftCardsArrayHelpers.push({
                                instituteId: localStorage.getItem("instituteId"),
                                title: "",
                                type: "",
                                quantity: 1,
                                expireAt: new Date(),
                                points: 1,
                                exchangeInstruction: "",
                                currency: "SAR",
                                image: null,
                                codes: [{ code: "" }],
                              })
                            }
                          >
                            <PlusIcon className="h-5 w-5 mx-2"></PlusIcon>
                            <span>{t("AddGiftCards")}</span>
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
}
