import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as giftCardService from "../../services/giftcard.services";
import {
  ChevronRightIcon,
  HomeIcon,
  ChevronLeftIcon,
  PencilIcon,
  TrashIcon,
  PlusIcon,
  GiftIcon
} from "@heroicons/react/20/solid";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import GiftCardPreviewImage from "../../components/giftcards/GiftCardPreviewImage";
import { format } from "date-fns";

export default function EditGiftCard() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [giftCard, setGiftCard] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    {
      name: t("GiftCards"),
      href: `/giftCards/`,
      current: false,
    },
    { name: t("EditGiftCards"), href: "#", current: true },
  ];

  useEffect(() => {
    giftCardService.getGiftCard(id)
      .then((response) => {
        console.log("🚀 ~ .then ~ data:", response.data.data);
        setGiftCard(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        setIsLoading(false);
      });
  }, [id]);

  const update = (inputs) => {
    giftCardService
      .updateGiftCards(id, inputs)
      .then((response) => {
        console.log(response);
        navigate(`/giftCards/list`);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Required")),
    type: Yup.string().required(t("Required")),
    quantity: Yup.string().required(t("Required")),
    expireAt: Yup.string().required(t("Required")),
    points: Yup.string().required(t("Required")),
    exchangeInstruction: Yup.string().required(t("Required")),
    codes: Yup.array().of(
      Yup.object().shape({
        code: Yup.string().required(t("Required")),
      })
    )
  });

  return (
    <Layout>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                {window.localStorage.getItem("i18nextLng") === "ar" ? (
                  <ChevronLeftIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                )}
                <a href={page.href} className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current={page.current ? "page" : undefined}>
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="min-w-full divide-y divide-gray-300">
                <div className="divide-y divide-gray-200 bg-white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isLoading && (
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <Formik
                initialValues={{
                  instituteId: localStorage.getItem("instituteId"),
                  title: giftCard.title ?? "",
                  type: giftCard.type ?? "",
                  quantity: giftCard.quantity ?? 1,
                  expireAt: format(new Date(giftCard.expireAt ?? new Date()), "MMMM d, y"),
                  points: giftCard.points ?? 1,
                  exchangeInstruction: giftCard.exchangeInstruction ?? "",
                  currency: "SAR",
                  image: null,
                  codes: giftCard.codes ?? [{ code: "" }],
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={(values) => update(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                      <h3 className="text-xl font-semibold text-gray-900">{t("EditGiftCard")}</h3>
                    </div>
                    <div className="shadow sm:overflow-hidden sm:rounded-md bg-white">
                      <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <div className="flex justify-center min-w-full">
                          <div className="relative">
                            <button
                              type="button"
                              className="absolute mt-40 ml-2 mr-2 rounded-full bg-amber-400 p-1 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                            >
                              <label htmlFor="image">
                                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                              </label>
                            </button>
                            {values.image ? (
                              <GiftCardPreviewImage file={values.image} />
                            ) : giftCard.image ? (
                              <img
                                className="h-[200px] w-[420px] rounded-md bg-stone-100"
                                src={`${apiUrl}/gift-card-image/${giftCard.image}`}
                                alt=""
                              />
                            ) : (
                              <div className="h-[200px] w-[420px] rounded-md bg-stone-100">
                                <center>
                                  <GiftIcon className="pt-[50px] w-32 h-32 text-yellow-400" />
                                </center>
                              </div>
                            )}
                            <input
                              id="image"
                              name="image"
                              type="file"
                              className="sr-only"
                              onChange={(event) => {
                                setTouched({
                                  ...touched,
                                  image: true,
                                });
                                setFieldValue("image", event.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="sm:col-span-6">
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                              {t("type")}
                            </label>
                            <div className="mt-1">
                              <Field
                                as="select"
                                id="type"
                                name="type"
                                disabled
                                className={`block w-full border-1 rounded-md shadow-sm focus:border-secondary ${errors.type ? "border-1 border-red-800  focus:border-red-800" : "border border-gray-300 focus:border-secondary focus:ring-secondary"
                                  }`}
                              >
                                <option value="">{t("Select")}</option>
                                <option value="REDEEM">{t("RedeemCard")}</option>
                                <option value="EXCHANGE">{t("ExchangeCard")}</option>
                              </Field>
                              {errors.type && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="type-error">
                                  {errors.type}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                              {t("title")}
                            </label>
                            <div className="mt-1">
                              <Field
                                autoComplete="title"
                                name="title"
                                type="text"
                                id="title"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.title ? "border-2 border-red-700 focus:ring-secondary" : "focus:border-secondary focus:ring-secondary"
                                  }`}
                              />
                              {errors.title && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="title-error">
                                  {errors.title}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                              {t("quantity")}
                            </label>
                            <div className="mt-1">
                              <Field
                                autoComplete="quantity "
                                name="quantity"
                                type="text"
                                id="quantity"
                                disabled
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.quantity ? "border-2 border-red-700 focus:ring-secondary" : "focus:border-secondary focus:ring-secondary"
                                  }`}
                              />
                              {errors.quantity && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="quantity-error">
                                  {errors.quantity}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="points" className="block text-sm font-medium text-gray-700">
                              {t("points")}
                            </label>
                            <div className="mt-1">
                              <Field
                                autoComplete="points"
                                name="points"
                                type="text"
                                id="points"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.points ? "border-2 border-red-700 focus:ring-secondary" : "focus:border-secondary focus:ring-secondary"
                                  }`}
                              />
                              {errors.points && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="points-error">
                                  {errors.points}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="expireAt" className="block text-sm font-medium text-gray-700">
                              {t("expireAt")}
                            </label>
                            <div className="mt-1">
                              <CustomDatePicker
                                selected={values.expireAt ? new Date(values.expireAt) : new Date()}
                                onChange={(newValue) => {
                                  setTouched({
                                    ...touched,
                                    expireAt: true,
                                  });
                                  setFieldValue("expireAt", newValue ? newValue.toISOString() : new Date().toISOString());
                                }}
                              />
                              {errors.expireAt && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="expireAt-error">
                                  {errors.expireAt}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-12">
                            <label htmlFor="exchangeInstruction" className="block text-sm font-medium text-gray-700">
                              {t("exchangeInstruction")}
                            </label>
                            <div className="mt-1">
                              <Field
                                autoComplete="exchangeInstruction"
                                as="textarea"
                                rows="2"
                                name="exchangeInstruction"
                                type="text"
                                id="exchangeInstruction"
                                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.exchangeInstruction ? "border-2 border-red-700 focus:ring-secondary" : "focus:border-secondary focus:ring-secondary"
                                  }`}
                              />
                              {errors.exchangeInstruction && (
                                <p className="mt-1 px-4 text-sm text-red-600" id="exchangeInstruction-error">
                                  {errors.exchangeInstruction}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-12">
                            <FieldArray
                              name="codes"
                              render={(arrayHelpers) => (
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">{t("Codes")}</label>
                                  {values.codes && values.codes.length > 0 ? (
                                    values.codes.map((code, index) => (
                                      <div key={index} className="flex items-center mt-2">
                                        <Field
                                          name={`codes[${index}].code`}
                                          placeholder={t("Code")}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary sm:text-sm"
                                        />
                                        <button
                                          type="button"
                                          className="ml-2 inline-flex items-center justify-center rounded-md border border-gray-300 bg-red-600 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            setFieldValue("quantity", values.quantity - 1);
                                          }}
                                        >
                                          <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                      </div>
                                    ))
                                  ) : (
                                    <div>{t("NoCodes")}</div>
                                  )}
                                  <button
                                    type="button"
                                    className="mt-2 inline-flex items-center justify-center rounded-md border border-dark bg-gray-shade py-2 px-4 text-sm font-medium leading-4 text-dark shadow-sm hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                    onClick={() => {
                                      arrayHelpers.push({ code: "" });
                                      setFieldValue("quantity", values.quantity + 1);
                                    }}
                                  >
                                    <PlusIcon className="h-5 w-5 mx-2" />
                                    <span>{t("AddCode")}</span>
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                          onClick={handleCancel}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
