import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as scheduleService from "../../services/schedule.services";
import { Formik, Form } from "formik";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import LoadingData from "../../components/common/LoadingData";
import { PencilIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import SchedulePreviewImage from "../../components/SchedulePreview";
import SuccessAlert from "../../components/common/SuccessAlert";
import UserAvatar from "../../assets/avatar.png";

export default function EditSchedule() {
  let { classRoomId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();
  // let navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    scheduleService
      .getSchedule(classRoomId)
      .then((response) => {
        console.log("🚀 ~ file: Edit.js:33 ~ .then ~ response:", response);

        setData(response.data.result);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const update = (inputs) => {
    if (inputs.id === 0) {
      scheduleService
        .createSchedule(classRoomId, inputs)
        .then((response) => {
          console.log("🚀 ~ file: Create.js:52 ~ .then ~ response:", response);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        })
        .catch((error) => {
          setError(true);
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    } else {
      scheduleService
        .updateSchedule(inputs.id, inputs)
        .then((response) => {
          console.log("🚀 ~ file: Edit.js:44 ~ .then ~ response:", response);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        })
        .catch((error) => {
          setError(true);
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    }
  };

  return (
    <>
      <SuccessAlert show={success} message={t("DataUpdated")} />
      <Layout>
        {isLoading && <LoadingData />}
        {!isLoading && (
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <Formik
                initialValues={{
                  id: data?.id ?? 0,
                  classRoomId: classRoomId,
                  image: null,
                }}
                onSubmit={(values) => update(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="pb-4 flex ltr:float-right rtl:float-left p-6">
                      <div className="mt-2 flex sm:mt-0 sm:ml-4">
                        <button
                          type="submit"
                          className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {t("SaveChanges")}
                        </button>
                      </div>
                    </div>

                    <div className="border border-gray-200 rounded-lg shadow-lg p-10 bg-white">
                      <div className="flex justify-center min-w-full">
                        {/* image */}
                        <div className="relative">
                          <button
                            type="button"
                            className="absolute bottom-0 -ml-10 rounded-md bg-amber-400 p-1 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                          >
                            <label htmlFor="image">
                              <PencilIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </label>
                          </button>
                          {values.image ? (
                            <SchedulePreviewImage file={values.image} />
                          ) : (
                            <>
                              {data?.image && (
                                <img
                                  className="h-[500px] w-full rounded-md"
                                  src={apiUrl + "schedule/" + data?.image}
                                  alt=""
                                />
                              )}
                              {data?.image === undefined && (
                                <>
                                  <ArrowUpTrayIcon
                                    className="h-[100px] w-[100px] rounded-md"
                                    src={UserAvatar}
                                    alt=""
                                  />
                                </>
                              )}
                            </>
                          )}

                          <input
                            id="image"
                            name="image"
                            type="file"
                            className="sr-only"
                            onChange={(event) => {
                              setTouched({
                                ...touched,
                                thumbnail: true,
                              });
                              setFieldValue("image", event.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
