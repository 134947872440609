import React, { useEffect, useState } from "react";
import ReportLayout from "../../../components/root/ReportLayout";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoadingData from "../../../components/common/LoadingData";
import reportServices from "../../../services/report.services";
import FailedAlert from "../../../components/common/FailedAlert";
export default function Students() {
  let { gradeId } = useParams();
  let { classRoomId } = useParams();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    reportServices
      .getStudents(gradeId, classRoomId)
      .then((response) => {
        setData(response.data.report);
        console.log(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoading(false);
      });
    //eslint-disable-next-line
  }, []);

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      <FailedAlert
        show={showError}
        message={t("SomethingWentWroing")}
        close={handleCloseError}
      />
      <ReportLayout title={t("Students")}>
        {isLoading && <LoadingData />}
        {!isLoading && (
          <div className="mt-5 pt-5 pb-5 pl-5 pr-5 bg-white  rounded-lg shadow-lg ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    {t("FirstName")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                  >
                    {t("LastName")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                  >
                    {t("Email")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                  >
                    {t("PhoneNumber")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                  >
                    {t("ClassRoom")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.map((student, studentIdx) => (
                  <tr
                    key={student.id}
                    className={studentIdx % 2 === 0 ? undefined : "bg-gray-50"}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {student.firstName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {student.midName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {student.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {student.phoneNumber}
                    </td>
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                      {student.student.classRoom !== null ? (
                        <span className="px-1 inline-flex items-center text-xs font-medium">
                          <dd className="bg-gray-100 text-amber-700 px-2.5 mx-2 py-1 rounded-full">
                            {
                              student.student.classRoom.instituteGrade
                                .arabicName
                            }
                          </dd>
                          <dd className="bg-amber-100 text-amber-600 px-2.5 py-1 rounded-full">
                            {student.student.classRoom.arabicName}
                          </dd>
                        </span>
                      ) : (
                        <span className="px-1 inline-flex  text-xs font-medium">
                          <dd className="bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                            {t("NotAssignedyet")}
                          </dd>
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </ReportLayout>
    </>
  );
}
