import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getExchangePointsDetails = (id) => {
    return axios.get(`${base.apiUrl}/exchange-points/${id}`, {
        headers: headers.authHeader(),
    });
};

const getExchangePointsList = (page, limit) => {
    return axios.get(`${base.apiUrl}exchange-points/${page}/${limit}?instituteId=${localStorage.getItem("instituteId")}`, {
        headers: headers.authHeader(),
    });
};


export {
    getExchangePointsDetails,
    getExchangePointsList,
};
