import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { completeRegister, logout } from "../../slices/authSlice";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../components/root/AuthLayout";
import FailedAlert from "../../components/common/FailedAlert";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";

export default function CompleteRegister() {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (inputs) => {
    setIsLoading(true);
    dispatch(completeRegister(inputs))
      .then((response) => {
        console.log(
          "🚀 ~ file: CompleteRegister.js:30 ~ .then ~ response:",
          response
        );
        if (response.payload.status === 201) navigate("/mode");
        else {
          setShowError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: CompleteRegister.js:38 ~ handleSubmit ~ error:",
          error
        );
        setIsLoading(false);
        setShowError(true);
      });
  };

  const CompleteRegisterValidationSchema = Yup.object().shape({
    arabicName: Yup.string().required(t("Required")),
    englishName: Yup.string().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
    phoneNumber: Yup.string().required(t("Required")),
    address: Yup.string().required(t("Required")),
  });

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.log("🚀 ~ file: Login.js:39 ~ handleSubmit ~ error", error);
      });
  };

  return (
    <>
      <LanguageFloatingButton />
      {error && (
        <FailedAlert
          show={showError}
          message={t(error.message)}
          close={handleCloseError}
        />
      )}
      <AuthLayout title={t("SchoolInformation")}>
        <Formik
          initialValues={{
            arabicName: "",
            englishName: "",
            email: "",
            phoneNumber: "",
            address: "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={CompleteRegisterValidationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className="space-y-6">
              <div>
                <label
                  htmlFor="arabicName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("ArabicName")}
                </label>
                <div className="mt-1">
                  <Field
                    name="arabicName"
                    id="arabicName"
                    type="text"
                    autoComplete="arabicName"
                    required
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${touched.arabicName && errors.arabicName
                      ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                      : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="englishName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("EnglishName")}
                </label>
                <div className="mt-1">
                  <Field
                    name="englishName"
                    id="englishName"
                    type="text"
                    autoComplete="englishName"
                    required
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${touched.englishName && errors.englishName
                      ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                      : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Email")}
                </label>
                <div className="mt-1">
                  <Field
                    name="email"
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${touched.email && errors.email
                      ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                      : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("PhoneNumber")}
                </label>
                <div className="mt-1">
                  <Field
                    name="phoneNumber"
                    id="phoneNumber"
                    type="text"
                    required
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${touched.phoneNumber && errors.phoneNumber
                      ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                      : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Address")}
                </label>
                <div className="mt-1">
                  <Field
                    name="address"
                    id="address"
                    type="text"
                    required
                    className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm ${touched.address && errors.address
                      ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                      : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <button
                    onClick={() => handleLogout()}
                    className="font-medium text-gray-900"
                  >
                    {t("Logout")}
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                >
                  {isLoading === false ? (
                    t("CompleteRegister")
                  ) : (
                    <ButtonLoadingSpinner />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </>
  );
}
