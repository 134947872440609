import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../assets/logo.svg";
import cover from "../../assets/cover.png";
import app from "../../assets/app-store.png";
import google from "../../assets/google-play.png";

import {
  EnvelopeIcon,
  LockClosedIcon,
  SunIcon,
  AcademicCapIcon,
  CheckBadgeIcon,
  ChartBarIcon,
  ArrowPathIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/20/solid";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../slices/authSlice";
import { clearError } from "../../slices/errorSlice";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";
import { useTranslation } from "react-i18next";
import ButtonLoadingSpinner from "../../components/common/ButtonLoadingSpinner";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";

const navigation = {
  solutions: [
    { name: "Marketing", href: "#" },
    { name: "Analytics", href: "#" },
    { name: "Commerce", href: "#" },
    { name: "Insights", href: "#" },
  ],
  support: [
    { name: "Pricing", href: "#" },
    { name: "Documentation", href: "#" },
    { name: "Guides", href: "#" },
    { name: "API Status", href: "#" },
  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Press", href: "#" },
    { name: "Partners", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "YouTube",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

const features = [
  {
    name: "Push to deploy",
    description:
      "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "SSL certificates",
    description:
      "Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.",
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "Simple queues",
    description:
      "Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.",
    href: "#",
    icon: ArrowPathIcon,
  },
];

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isCompleteRegister } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.error);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleSubmit = (inputs) => {
    setIsLoading(true);
    dispatch(login({ ...inputs, isQR: false }))
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response.payload.status === 200) {
          if (response.payload.user.role !== "USER") {
            if (response.payload.data.institute !== null) {
              navigate("/");
            } else {
              navigate("/complete-register");
            }
          } else {
            setIsLoading(false);
            setShowError(true);
          }
        } else {
          setIsLoading(false);
          setShowError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("🚀 ~ file: Login.js:39 ~ handleSubmit ~ error", error);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const LoginValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
    password: Yup.string().min(3, t("PasswordMin3")).required(t("Required")),
  });

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      {error && (
        <FailedAlert
          show={showError}
          message={t(error.message)}
          close={handleCloseError}
        />
      )}
      <LanguageFloatingButton />
      <div className="h-screen w-full bg-gradient-to-br from-gray-shade to-secondary pt-16">
        <div className="flex justify-center">
          <div className="flex-1 flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="mx-auto h-28 w-auto" src={logo} alt="" />
              </div>
              <div className="mt-8">
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={LoginValidationSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ touched, errors, isSubmitting }) => (
                    <Form className="space-y-6">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("Email")}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <EnvelopeIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <Field
                            name="email"
                            id="email"
                            type="email"
                            autoComplete="email"
                            placeholder="you@example.com"
                            className={`block w-full rounded-md border border-transparent placeholder-gray-500 pl-10 shadow-sm sm:text-sm ${
                              touched.email && errors.email
                                ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                                : "border border-transparent focus:border-secondary focus:ring-secondary"
                            }`}
                          />
                        </div>
                      </div>
                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("Password")}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <LockClosedIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            placeholder="****"
                            className={`block w-full rounded-md border border-transparent placeholder-gray-500 pl-10 shadow-sm sm:text-sm ${
                              touched.password && errors.password
                                ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                                : "border border-transparent focus:border-secondary focus:ring-secondary"
                            }`}
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-secondary"
                          />
                          <label
                            htmlFor="remember-me"
                            className="ltr:ml-2 rtl:mr-2 block text-sm text-gray-900"
                          >
                            {t("RememberMe")}
                          </label>
                        </div>

                        <div className="text-sm">
                          <Link
                            to="/request-reset-password"
                            className="font-medium text-gray-900"
                          >
                            {t("ForgetPassword")}
                          </Link>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {isLoading === false ? (
                            t("Login")
                          ) : (
                            <ButtonLoadingSpinner />
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p className="mt-6 text-sm text-gray-900">
                  {t("DontHaveAccountYet")}
                  <Link
                    to="/register"
                    className="rtl:pr-2 ltr:pl-2 font-medium text-yellow-700 hover:text-secondary"
                  >
                    {t("CreateNewAccount")}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="flex items-center">
            <div className="py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <h2 className="text-3xl font-bold tracking-tight text-gray-100 drop-shadow-md">
                {t("IndexHeroHeader")}
              </h2>
              <p className="mt-2 text-sm text-gray-100 drop-shadow-md">
                {t("IndexHeroBody")}
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Everything you need to deploy your app
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Quis tellus eget adipiscing convallis sit sit eget aliquet quis.
              Suspendisse eget egestas a elementum pulvinar et feugiat blandit
              at. In mi viverra elit nunc.
            </p>
          </div>
          <div className="text-center">
            <center>
              <img className="h-2/6 w-2/6 " src={cover} alt="" />
            </center>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <feature.icon
                      className="h-5 w-5 flex-none text-amber-400"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                    <p className="mt-6">
                      <a
                        href={feature.href}
                        className="text-sm font-semibold leading-6 text-amber-400"
                      >
                        Learn more <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* <div className="bg-gradient-to-bl from-gray-500 to-white h-96 flex justify-center items-center">
        <div>
          <h1 className="text-5xl text-gray-800 text-center leading-relaxed tracking-wide px-96">
            Discover what all the Who's champion is about!
          </h1>
          <p className="text-sm text-black text-center leading-loose">
            Our app is available on any mobile device! Download now to get
            started.
          </p>
          <div className="flex justify-center items-center space-x-2 my-4">
            <div className="w-48 h-14 p-3 bg-black flex items-center rounded-lg">
              <img className="h-8 w-auto" src={google} alt="" />
              <span className="text-white ml-3">
                <p className="text-xs">GET IT ON</p>
                <h3 className="text-xl text-bold">Google play</h3>
              </span>
            </div>
            <div className="w-52 h-14 p-3 bg-black flex items-center rounded-lg">
              <img className="h-8 w-auto" src={app} alt="" />
              <span className="text-white ml-3">
                <p className="text-xs">DOWNLOAD ON THE</p>
                <h3 className="text-xl text-bold">App Store</h3>
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <footer className="bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>

        <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
          <div className="border-t border-white/10 pt-8 lg:flex lg:items-center lg:justify-between">
            <div>
              <h3 className="text-sm font-semibold leading-6 text-white">
                Subscribe to our newsletter
              </h3>
              <p className="mt-2 text-sm leading-6 text-gray-300">
                The latest news, articles, and resources, sent to your inbox
                weekly.
              </p>
            </div>
            <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-secondary sm:w-56 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-md bg-secondary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
          <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex ">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="px-2 text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
              &copy; 2023 WHOS CHAMPION, All rights reserved. By
              <a className="text-white" href="https://www.gbt.ae/">
                {" "}
                Grand Block Technologies
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
