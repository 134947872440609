import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as teacherServices from "../../services/teacher.services";
import Select from "react-tailwindcss-select";
import { HomeIcon, CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Formik, Form, Field } from "formik";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import PagesHeaderNav from "../../components/common/PagesHeaderNav";
import * as Yup from "yup";
import LoadingData from "../../components/common/LoadingData";
import SuccessAlert from "../../components/common/SuccessAlert";
import FailedAlert from "../../components/common/FailedAlert";
import { Switch } from "@headlessui/react";

export default function EditTeacher() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedClassRooms, setSelectedClassRooms] = useState({
    selected: [],
  });
  const [selectedSupervisedClassRooms, setSelectedSupervisedClassRooms] = useState({
    selected: [],
  });
  const [classRooms, setClassRooms] = useState([]);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  let { id } = useParams();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Teachers"), href: "/teachers/list", current: false },
    { name: t("EditTeacherDetails"), href: "#", current: true },
  ];

  useEffect(() => {
    setIsLoading(true);
    teacherServices
      .getTeacher(id)
      .then((response) => {
        console.log(response.data.teacher);
        setData({ ...response.data.teacher, supervisedClassRooms: response.data.teacher.assignedClassRooms.filter((data) => data.supervised === true) });

        setIsLoading(false);
        const selectedOptions = response.data.teacher.assignedClassRooms.filter((data) => data.supervised === false).map(
          (data) => ({
            label: data.classRoom.arabicName,
            value: data.classRoomId,
          })
        );
        setSelectedClassRooms({ selected: selectedOptions });

        const selectedSupervisedOptions = response.data.teacher.assignedClassRooms.filter((data) => data.supervised === true).map(
          (data) => ({
            label: data.classRoom.arabicName,
            value: data.classRoomId,
          })
        );
        setSelectedSupervisedClassRooms({ selected: selectedSupervisedOptions });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id]);

  const update = (inputs) => {
    inputs.teacher.assignedClassRooms = [...inputs.teacher.assignedClassRooms, ...inputs.teacher.supervisedClassRooms]
    teacherServices
      .updateTeacher(id, inputs)
      .then((response) => {
        console.log(response);
        navigate("/teachers/list");
      })
      .catch((error) => {
        setIsLoading(false);
        setError("ThereIsEmailOrPhoneAlreadyTakenOrSomethingWentWroing");
        setShowError(true);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const getClassRooms = async () => {
    setIsLoading(true);
    try {
      const response = await teacherServices.getClassRooms();
      if (response.status === 200) {
        const options = response.data.grades.map((grade) => ({
          label: grade.arabicName,
          options: grade.classrooms.map((classRoom) => ({
            value: classRoom.id,
            label: classRoom.arabicName,
          })),
        }));

        setClassRooms(options);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    midName: Yup.string().required(t("Required")),
    phoneNumber: Yup.string().required(t("Required")),
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
    password: Yup.string().min(3, "PasswordMin3"),
  });

  const handleChange = (value) => {
    setSelectedClassRooms({ selected: value });
  };

  const handleSupervisedChange = (value) => {
    setSelectedSupervisedClassRooms({ selected: value });
  };

  useEffect(() => {
    getClassRooms(1);
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    navigate(-1);
    // navigate("/teachers/list");
  };

  return (
    <>
      <FailedAlert
        show={showError}
        message={t(error)}
        close={() => setShowError(false)}
      />
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only" >{t("Home")}</span>
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <PagesHeaderNav page={page} />
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoading && data ? (
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <Formik
                initialValues={{
                  giftSupervisor: data.giftSupervisor,
                  firstName: data?.user?.firstName ?? "",
                  midName: data?.user?.midName ?? "",
                  email: data?.user?.email ?? "",
                  phoneNumber: data?.user?.phoneNumber ?? "",
                  password: "",
                  teacher: {
                    id: data?.id,
                    instituteId: localStorage.getItem("instituteId"),
                    assignedClassRooms: data.assignedClassRooms ?? [],
                    supervisedClassRooms: data.supervisedClassRooms ?? [],
                  },
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={ValidationSchema}
                onSubmit={(values) => update(values)}
              >
                {({ touched, errors, setFieldValue, setTouched, values }) => (
                  <Form>
                    <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                      <h3 className="text-xl font-semibold text-gray-900">
                        {t("EditTeacherDetails")}
                      </h3>
                      <div className="mt-2 flex sm:mt-0 sm:ml-4">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                          onClick={handleCancel}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                    <div className="border border-gray-200 rounded-lg shadow-lg px-4 bg-white">
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("FirstName")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              autoComplete="firstName"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.firstName && errors.firstName
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.firstName &&
                              touched.firstName &&
                              errors.firstName &&
                              errors.firstName && (
                                <p
                                  className="mt-1 px-4 text-sm text-red-600"
                                  id={"firstName-error"}
                                >
                                  {errors.firstName}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="midName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("LastName")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="midName"
                              id="midName"
                              autoComplete="midName"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.midName && errors.midName
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.midName && errors.midName && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"midName-error"}
                              >
                                {errors.midName}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="phoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("PhoneNumber")}
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              autoComplete="phoneNumber"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.phoneNumber && errors.phoneNumber
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.phoneNumber && errors.phoneNumber && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"phoneNUmber-error"}
                              >
                                {errors.phoneNumber}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("Email")}
                          </label>
                          <div className="mt-1">
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.email && errors.email
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.email && errors.email && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"email-error"}
                              >
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("Password")}
                            <span className="mx-2 font-bold">
                              - {t("optionalField")}
                            </span>
                          </label>
                          <div className="mt-1">
                            <Field
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="password"
                              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary  sm:text-sm ${errors.password && errors.password
                                ? "border-2 border-red-700 focus:ring-secondary"
                                : "focus:border-secondary focus:ring-secondary"
                                }`}
                            />
                            {touched.password && errors.password && (
                              <p
                                className="mt-1 px-4 text-sm text-red-600"
                                id={"password-error"}
                              >
                                {errors.password}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="giftSupervisor" className="block text-sm font-medium text-gray-700">
                            {t("GiftsSupervisor")}
                          </label>
                          <div className="mt-1">
                            <Switch
                              checked={values.giftSupervisor}
                              onChange={(newValue) => {
                                setTouched({
                                  ...touched,
                                  giftSupervisor: newValue,
                                });
                                setFieldValue("giftSupervisor", newValue);
                              }}
                              className="bg-gray-200 relative inline-flex flex-shrink-0 h-8 w-60 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary shadow-sm"
                            >
                              <span
                                className={`${values.giftSupervisor ? "translate-x-0" : "rtl:-translate-x-28 ltr:translate-x-28"
                                  } pointer-events-none relative inline-block h-8 w-32 rounded-full shadow transform ring-0 transition ease-in-out duration-200`}
                              >
                                <span
                                  className={`${values.giftSupervisor ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200"
                                    } absolute inset-0 h-full w-full bg-gray-600 text-white text-sm flex items-center justify-center transition-opacity rounded-2xl`}
                                  aria-hidden="true"
                                >
                                  <XMarkIcon className="h-5 w-5 mr-2" /> {t("GiftsSupervisor")}
                                </span>
                                <span
                                  className={`${values.giftSupervisor ? "opacity-100 ease-in duration-200 bg-yellow-500 text-white" : "opacity-0 ease-out duration-100"
                                    } absolute inset-0 h-full w-full text-sm flex items-center justify-center transition-opacity rounded-2xl`}
                                  aria-hidden="true"
                                >
                                  <CheckIcon className="h-5 w-5 mr-2" /> {t("GiftsSupervisor")}
                                </span>
                              </span>
                            </Switch>
                          </div>
                        </div>


                      </div>

                      <div className="relative border border-gray-200 rounded-lg shadow-sm px-4 py-8 my-8">
                        <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block bg-white px-1 text-xl font-semibold text-gray-900">
                          {t("AssignedCLassRooms")}
                        </h2>
                        <div className="relative grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="sm:col-span-12">
                            <>
                              <p className="block text-sm font-medium text-gray-700">
                                {t("ClassRooms")}
                              </p>
                              <Select
                                value={selectedClassRooms.selected}
                                onChange={(value) => {
                                  //Show selections in the input field
                                  handleChange(value);
                                  //Add values to inputs
                                  if (value !== null) {
                                    console.log(value);
                                    values.teacher.assignedClassRooms =
                                      value.map((classRoom) => ({
                                        teacherId: data.id,
                                        classRoomId: classRoom.value,
                                      }));
                                  } else {
                                    values.teacher.assignedClassRooms = [];
                                  }
                                }}
                                options={classRooms}
                                isMultiple={true}
                                formatGroupLabel={(data) => (
                                  <div
                                    className={`py-2 text-xs flex items-center justify-between`}
                                  >
                                    <span className="font-bold ">
                                      {data.label}
                                    </span>
                                    <span className="h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                      {data.options.length}
                                    </span>
                                  </div>
                                )}
                                formatOptionLabel={(data) => (
                                  <li
                                    className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                      ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                      : `bg-amber-100 text-amber-600`
                                      }`}
                                  >
                                    {data.label}
                                  </li>
                                )}
                              />
                            </>
                          </div>
                        </div>
                      </div>

                      <div className="relative border border-gray-200 rounded-lg shadow-sm px-4 py-8 my-8">
                        <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block bg-white px-1 text-xl font-semibold text-gray-900">
                          {t("SupervisedCLassRooms")}
                        </h2>
                        <div className="relative grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                          <div className="sm:col-span-12">
                            <>
                              <p className="block text-sm font-medium text-gray-700">
                                {t("ClassRooms")}
                              </p>
                              <Select
                                value={selectedSupervisedClassRooms.selected}
                                onChange={(value) => {
                                  //Show selections in the input field
                                  handleSupervisedChange(value);
                                  //Add values to inputs
                                  if (value !== null) {
                                    values.teacher.supervisedClassRooms =
                                      value.map((classRoom) => ({
                                        teacherId: data.id,
                                        classRoomId: classRoom.value,
                                        supervised: true
                                      }));

                                    for (const item of selectedSupervisedClassRooms.selected) {
                                      let classroom = value.find(
                                        (s) => s.value === item.value
                                      );

                                      if (!classroom) {
                                        values.teacher.supervisedClassRooms.push({
                                          teacherId: data.id,
                                          classRoomId: item.value,
                                          supervised: false
                                        })
                                      }
                                    }
                                  } else {
                                    values.teacher.supervisedClassRooms.find((s) => s.supervised === true).supervised = false;
                                  }
                                }}
                                options={classRooms}
                                isMultiple={true}
                                formatGroupLabel={(data) => (
                                  <div
                                    className={`py-2 text-xs flex items-center justify-between`}
                                  >
                                    <span className="font-bold ">
                                      {data.label}
                                    </span>
                                    <span className="h-5 p-1.5 flex items-center justify-center rounded-full bg-primary">
                                      {data.options.length}
                                    </span>
                                  </div>
                                )}
                                formatOptionLabel={(data) => (
                                  <li
                                    className={`block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${!data.isSelected
                                      ? `text-gray hover:text-amber-600 hover:bg-amber-100`
                                      : `bg-amber-100 text-amber-600`
                                      }`}
                                  >
                                    {data.label}
                                  </li>
                                )}
                              />
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <LoadingData />
        )}
      </Layout>
    </>
  );
}
