import React from "react";
import DataTable from "react-data-table-component";
import {
  //   PencilIcon,
  DocumentIcon,
  //   TrashIcon,
  //   MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoData from "../common/NoData";
import LoadingData from "../common/LoadingData";

const ClassRoomsTable = ({ data, isLoading }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("classRoomName")}
        </div>
      ),
      selector: (row) => (
        <div
          type="button"
          className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 hover:cursor-pointer"
          //   onClick={"#"}
        >
          {i18n.language === "en"
            ? row.classRoom.englishName
            : row.classRoom.arabicName}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("superviser")}
        </div>
      ),
      selector: (row) => (
        <div
          type="button"
          // onClick={"#"}
          className="hover:cursor-pointer"
        >
          {row.supervised ? (
            <p className="text-green-500 bg-green-100 font-semibold p-1 px-2 border-none rounded-xl">
              {t("true")}
            </p>
          ) : (
            <p className="text-gray-700 bg-gray-400 font-semibold p-1 px-2 border rounded-xl">
              {t("false")}
            </p>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      cellExport: (row) => row.null,
      selector: (row) => (
        <div>
          <button
            type="button"
            className="inline-flex m-1 items-center p-3  justify-center  bg-gray-100 rounded-md shadow-sm text-gray-500 hover:text-amber-500"
            // onClick={"#"}
          >
            <span>
              <DocumentIcon
                className="h-5 w-5 "
                stroke="currentColor"
                aria-hidden="true"
              ></DocumentIcon>
            </span>
          </button>
        </div>
      ),
      sortable: true,
      width: "190px",
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        progressPending={isLoading}
        Header
        pagination
        paginationServer
        highlightOnHover
        // paginationTotalRows={count}
        // onChangeRowsPerPage={getNextItems}
        // onChangePage={getItems}
        noDataComponent={<NoData />}
        progressComponent={<LoadingData />}
      />
    </div>
  );
};

export default ClassRoomsTable;
