import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as gradeService from "../../services/grade.services";
import {
  ChevronRightIcon,
  HomeIcon,
  ChevronLeftIcon,
} from "@heroicons/react/20/solid";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";

export default function EditGrades() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [grade, setGradeData] = useState([]);

  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = [
    { name: t("Grades"), href: "/grades/list", current: false },
    { name: t("EditGrade"), href: "#", current: true },
  ];

  useEffect(() => {
    gradeService
      .getGrade(id)
      .then((response) => {
        setGradeData(response.data.grade);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, [id]);

  const update = (inputs) => {
    gradeService
      .updateGrade(id, inputs)
      .then((response) => {
        console.log(response);
        navigate("/grades/list");
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const handleCancel = () => {
    // navigate("/grades/list");
    navigate(-1);
  };

  const validationSchema = Yup.object().shape({
    arabicName: Yup.string().required(t("Required")),
  });

  return (
    <>
      <Layout>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {window.localStorage.getItem("i18nextLng") === "ar" ? (
                    <ChevronLeftIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <a
                    href={page.href}
                    className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isLoading && (
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 ">
              <div className="mt-5 md:col-span-2 md:mt-0">
                <Formik
                  initialValues={{
                    instituteId: grade.instituteId ?? "",
                    arabicName: grade.arabicName ?? "",
                    englishName: grade.englishName ?? "",
                    startDate: grade.startDate ?? "",
                    endDate: grade.endDate ?? "",
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={validationSchema}
                  onSubmit={(values) => update(values)}
                >
                  {({ touched, errors, setFieldValue, setTouched, values }) => (
                    <Form>
                      <div className="pb-4 sm:flex sm:items-center sm:justify-between">
                        <h3 className="text-xl font-semibold text-gray-900">
                          {t("EditGrade")}
                        </h3>
                      </div>
                      <div className="shadow sm:overflow-hidden sm:rounded-md bg-white">
                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                            <div className="col-span-12">
                              <label
                                htmlFor="arabic-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {t("ArabicName")}
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  name="arabicName"
                                  id="arabicName"
                                  className={`block w-full rounded-md shadow-sm focus:border-secondary  sm:text-sm ${
                                    errors.arabicName
                                      ? "border-2 border-red-500 focus:ring-secondary"
                                      : "focus:border-secondary border-gray-400 focus:ring-secondary"
                                  }`}
                                />
                                {errors.arabicName && (
                                  <p
                                    className="mt-1 px-4 text-sm text-red-600"
                                    id={"arabicName-error"}
                                  >
                                    {errors.arabicName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-span-12">
                              <label
                                htmlFor="english-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {t("EnglishName")}
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  name="englishName"
                                  id="englishName"
                                  autoComplete="english-name"
                                  className={`block w-full rounded-md shadow-sm focus:border-secondary  sm:text-sm ${
                                    errors.englishName
                                      ? "border-2 border-red-500 focus:ring-secondary"
                                      : "focus:border-secondary border-gray-400 focus:ring-secondary"
                                  }`}
                                />
                                {errors.englishName && (
                                  <p
                                    className="mt-1 px-4 text-sm text-red-600"
                                    id={"englishName-error"}
                                  >
                                    {errors.englishName}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                            onClick={handleCancel}
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            type="submit"
                            className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                          >
                            {t("Save")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
