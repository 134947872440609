import React, { Fragment, useRef } from "react";
import {
    HomeIcon,
    PencilSquareIcon,
    TrashIcon,
    PaperClipIcon,
    PrinterIcon,
} from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as ExchangePointServices from "../../services/exchangePoints.services";
import { logout } from "../../slices/authSlice";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import PagesHeaderNav from "../../components/common/PagesHeaderNav";
import { format } from "date-fns";

export default function ExchangePointsDetails() {
    let { id } = useParams();
    let { gradeId } = useParams();

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const pages = [
        { name: t("ExchangePoints"), href: `/exchange-points/list/${gradeId}`, current: false },
        { name: t("Details"), href: "#", current: true },
    ];

    useEffect(() => {
        ExchangePointServices
            .getExchangePointsDetails(id)
            .then((response) => {
                if (response.status === 200) {
                    setData(response.data);
                    setIsLoading(false);
                }
            })
            .catch((error) => { });
        // eslint-disable-next-line
    }, [id]);

    return (
        <>
            <Layout>
                <nav className="flex" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                        <li>
                            <div>
                                <a href="/" className="text-gray-400 hover:text-gray-500">
                                    <HomeIcon
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only" >{t("Home")}</span>
                                </a>
                            </div>
                        </li>
                        {pages.map((page) => (
                            <li key={page.name}>
                                <PagesHeaderNav page={page} />
                            </li>
                        ))}
                    </ol>
                </nav>
                <div className="mt-2 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <div className="min-w-full divide-y divide-gray-300">
                                    <div className="divide-y divide-gray-200 bg-white"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                        {!isLoading && (
                            <>
                                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                                    <div className="px-5 mt-5 border-t border-gray-200">
                                        <dl className="divide-y divide-gray-200">
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                                <h3 className="text-base font-semibold leading-6 text-gray-900">
                                                    {t("GiftDetails")}
                                                </h3>
                                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    <span className="flex-grow"></span>
                                                    <span className="ml-4 flex-shrink-0">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white font-medium text-primary hover:text-amber-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            {t("Details")}
                                                        </button>
                                                    </span>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="px-5 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("title")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.giftCard?.title}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("points")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.giftCard?.points}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("CreattionDate")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {format(
                                                        new Date(data.createdAt),
                                                        "MMMM d, y"
                                                    )}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                                            {t("StudentInformation")}
                                        </h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                            {t("PersonalDetails")}
                                        </p>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("FullName")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.user?.firstName + " " + data.user?.midName}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("Code")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.user?.code}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("IdentityNumber")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.user?.identityNumber ?? "-"}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("Email")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.user?.email}
                                                    {data.user?.emailConfirmed === true ? (
                                                        <span className="mx-2 inline-flex items-center text-xs font-medium bg-green-100  text-green-500 px-2.5 py-1 rounded-full">
                                                            {t("Confirmed")}
                                                        </span>
                                                    ) : (
                                                        <span className="mx-2 inline-flex items-center text-xs font-medium bg-red-100  text-red-500 px-2.5 py-1 rounded-full">
                                                            {t("NotConfirmedYet")}
                                                        </span>
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("PhoneNumber")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.user?.phoneNumber}
                                                    {data.user?.phoneNumberConfirmed === true ? (
                                                        <span className="mx-2 inline-flex items-center text-xs font-medium bg-green-100  text-green-500 px-2.5 py-1 rounded-full">
                                                            {t("Confirmed")}
                                                        </span>
                                                    ) : (
                                                        <span className="mx-2 inline-flex items-center text-xs font-medium bg-red-100  text-red-500 px-2.5 py-1 rounded-fullbg-red-100 rounded-full">
                                                            {t("NotConfirmedYet")}
                                                        </span>
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("Gender")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.user?.gender}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("RegistrationDate")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {format(new Date(data.user?.createdAt), "MMMM d, y")}
                                                </dd>
                                            </div>

                                        </dl>
                                    </div>
                                    <div className="px-5 mt-5 border-t border-gray-200">
                                        <dl className="divide-y divide-gray-200">
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                                <h3 className="text-base font-semibold leading-6 text-gray-900">
                                                    {t("ClassRoomDetails")}
                                                </h3>
                                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    <span className="flex-grow"></span>
                                                    <span className="ml-4 flex-shrink-0">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white font-medium text-primary hover:text-amber-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            {t("Details")}
                                                        </button>
                                                    </span>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="px-5 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("ClassName")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.classRoom?.arabicName}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("Grade")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {data.classRoom?.instituteGrade.arabicName}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("CreattionDate")}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {format(
                                                        new Date(data.classRoom?.createdAt),
                                                        "MMMM d, y"
                                                    )}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    );
}
