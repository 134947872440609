import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as studentServices from "../../services/student.services";
import { logout } from "../../slices/authSlice";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import NoData from "../common/NoData";
import LoadingData from "../common/LoadingData";

function Notes({ studentId }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getNotes(1);
    // eslint-disable-next-line
  }, []);

  const getNotes = async (page) => {
    setIsLoading(true);
    try {
      const response = await studentServices.getNotes(studentId, page, limit);
      if (response.status === 200) {
        setNotes(response.data.notes.rows);
        setCount(response.data.notes.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextNotes = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await studentServices.getNotes(studentId, page, limit);
      if (response.status === 200) {
        setNotes(response.data.notes.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const notesColumns = [
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900">
          {t("Description")}
        </div>
      ),
      selector: (row) => (
        <div className="text-left text-sm font-semibold text-gray-900">
          {row.description.slice(0, 60)}{" "}
          {row.description.length > 60 ? "..." : ""}
        </div>
      ),
      sortable: true,
      width: "550px",
    },
  ];

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <>
        <p className="px-10 mx-2">{data.description}</p>
      </>
    );
  };

  return (
    <div className="relative border border-gray-200 rounded-lg shadow-lg px-4 py-8 my-12 bg-white">
      <h2 className="absolute -top-3 ltr:left-4 rtl:right-4 -mt-px inline-block bg-transparent px-1 text-xl font-semibold text-gray-900">
        {t("Notes")}
      </h2>
      <DataTable
        columns={notesColumns}
        data={notes}
        Header
        pagination
        paginationServer
        highlightOnHover
        paginationTotalRows={count}
        onChangeRowsPerPage={getNextNotes}
        onChangePage={getNotes}
        expandableRows
        expandableRowsComponent={ExpandableRowComponent}
        noDataComponent={<NoData />}
        progressPending={isLoading}
        progressComponent={<LoadingData />}
      />
    </div>
  );
}

export default Notes;
