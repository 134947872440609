import { TableCellsIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function NoData() {
  const { t } = useTranslation();

  return (
    <div className="text-center pt-10 pb-10">
      <TableCellsIcon
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      />

      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {t("NoData")}
      </h3>
      <p className="mt-1 text-sm text-gray-500">{t("NoRecords")}</p>
    </div>
  );
}
