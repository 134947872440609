import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getGradesWithClasses = () => {
  return axios.get(
    `${base.apiUrl}reports/get-grades-classes/${localStorage.getItem(
      "instituteId"
    )}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getTeachers = () => {
  return axios.get(
    `${base.apiUrl}reports/get-teachers?instituteId=${localStorage.getItem(
      "instituteId"
    )}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getStudents = (gradeId, classRoomId) => {
  return axios.get(
    `${base.apiUrl}reports/get-students?instituteId=1${
      gradeId !== "0" ? `&gradeId=${gradeId}` : ""
    }${classRoomId !== "0" ? `&classRoomId=${classRoomId}` : ""}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getChampions = (gradeId, classRoomId) => {
  return axios.get(
    `${base.apiUrl}reports/get-champions?instituteId=${localStorage.getItem(
      "instituteId"
    )} ${gradeId !== "0" ? `&gradeId=${gradeId}` : ""} ${
      classRoomId !== "0" ? `&classRoomId=${classRoomId}` : ""
    }`,
    {
      headers: headers.authHeader(),
    }
  );
};

const reportServices = {
  getGradesWithClasses,
  getTeachers,
  getStudents,
  getChampions,
};

export default reportServices;
