import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getInstituteInsights = (id) => {
  return axios.get(
    `${base.apiUrl}institute/institute-insights/${localStorage.getItem(
      "instituteId"
    )}`,
    {
      headers: headers.authHeader(),
    }
  );
};

const getHowToUseLink = () => {
  return axios.get(`${base.apiUrl}config/instruction`, {
    headers: headers.authHeader(),
  });
};

export { getInstituteInsights, getHowToUseLink };
