import axios from "axios";
import * as base from "./base";
import * as headers from "../utils/request.header";

const getStudent = (id) => {
  return axios.get(`${base.apiUrl}institute/student/${id}`, {
    headers: headers.authHeader(),
  });

  // return instance.get(`institute/student/${id}`);
};

const getStudents = (classRoomId, page, limit) => {
  return axios.get(
    `${base.apiUrl}institute/students/${classRoomId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
  // return instance.get(`institute/students/${classRoomId}/${page}/${limit}`);
};

const createStudents = (body) => {
  return axios.post(`${base.apiUrl}institute/students`, body, {
    headers: headers.authHeader(),
  });

  // return instance.post(`institute/students`, body);
};

const createParents = (body) => {
  return axios.post(`${base.apiUrl}auth/register`, body, {
    headers: headers.authHeader(),
  });

  // return instance.post(`auth/register`, body);
};

const getClassRooms = () => {
  return axios.get(
    `${base.apiUrl}institute/teacher/${localStorage.getItem(
      "instituteId"
    )}/assigning-class-rooms`,
    {
      headers: headers.authHeader(),
    }
  );

  // return instance.get(`institute/teacher/${instituteId}/assigning-class-rooms`);
};

const updateStudent = (id, body) => {
  return axios.put(`${base.apiUrl}institute/student/${id}`, body, {
    headers: headers.authHeader(),
  });

  //return instance.put(`institute/student/${id}`, body);
};

const deleteStudent = (id) => {
  return axios.delete(`${base.apiUrl}institute/student/${id}`, {
    headers: headers.authHeader(),
  });

  // return instance.delete(`institute/student/${id}`);
};

const searchParents = (key, page, limit) => {
  return axios.get(
    `${base.apiUrl}institute/search/parents/${key}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );

  // return instance.get(`institute/search/parents/${key}/${page}/${limit}`);
};

const getNotes = (studentId, page, limit) => {
  return axios.get(
    `${base.apiUrl}institute/student-notes/${studentId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );

  // return instance.get(`institute/student-notes/${studentId}/${page}/${limit}`);
};

const UploadSheet = (classRoomId, body) => {
  let data = new FormData();
  data.append("sheet", body.sheet);

  return axios({
    method: "post",
    url: `${base.apiUrl}institute/add/students/read/sheet/${classRoomId}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export {
  getStudent,
  getStudents,
  createStudents,
  updateStudent,
  deleteStudent,
  searchParents,
  getClassRooms,
  getNotes,
  UploadSheet,
  createParents,
};
