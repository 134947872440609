import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  PencilIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import * as attendanceServices from "../../services/attendance.services";
import Layout from "../../components/root/Layout";
import { useTranslation } from "react-i18next";
import NoData from "../../components/common/NoData";
import LoadingData from "../../components/common/LoadingData";
import LoadingOver from "../../components/common/LoadingOver";
import { format } from "date-fns";
import CustomDatePicker from "../../components/common/CustomDatePicker";

export default function AttendanceList() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  let { classRoomId } = useParams();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const attendanceStatus = [
    {
      status: t("absent"),
      color: "bg-[#fc5c65]",
    },
    {
      status: t("attend"),
      color: "bg-[#26de81]",
    },
    {
      status: t("permitted"),
      color: "bg-[#fed330]",
    },
    {
      status: t("remote"),
      color: "bg-[#4b7bec]",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    getItems(1);
    // eslint-disable-next-line
  }, []);

  const getItems = async (page) => {
    setIsLoading(true);
    try {
      const response = await attendanceServices.getAttendances(
        classRoomId,
        format(selectedDate, "MM-dd-yyyy"),
        page,
        limit
      );
      console.log("🚀 ~ file: List.js:41 ~ getItems ~ response:", response);

      if (response.status === 200) {
        setRows(response.data.rows);
        setCount(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const getNextItems = async (limit, page) => {
    setIsLoading(true);
    try {
      const response = await attendanceServices.getAttendances(
        classRoomId,
        format(selectedDate, "MM-dd-yyyy"),
        page,
        limit
      );
      if (response.status === 200) {
        setRows(response.data.rows);
        setLimit(limit);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("UserName")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {row.student.user.firstName + " " + row.student.user.midName}
          <br />
          <p className="text-sm text-gray-400">{row.student.user.code}</p>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("Email")}
        </div>
      ),
      selector: (row) => row.student.user.email,
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("PhoneNumber")}
        </div>
      ),
      selector: (row) => row.student.user.phoneNumber,
      sortable: true,
    },
    {
      name: (
        <div className="ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
          {t("CreatedAt")}
        </div>
      ),
      selector: (row) => format(new Date(row.createdAt), "MM-dd-yyyy"),
      sortable: true,
    },
    {
      name: (
        <div className="text-left text-sm font-semibold text-gray-900"></div>
      ),
      cellExport: (row) => row.null,
      selector: (row) => (
        <>
          <div key={row.id} className="flex items-center">
            <span
              className={`inline-flex  p-3  ${
                row.status === "absent"
                  ? "bg-[#fc5c65]"
                  : row.status === "attend"
                  ? "bg-[#26de81]"
                  : row.status === "permitted"
                  ? "bg-[#fed330]"
                  : "bg-[#4b7bec]"
              } rounded-full shadow-sm`}
            ></span>
            <label className="inline-flex  p-3">{t(row.status)}</label>
          </div>
        </>
      ),
      sortable: true,
      width: "190px",
    },
  ];

  return (
    <>
      {loading && <LoadingOver />}
      <Layout>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("Attendance")}
            </h1>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mt-2">
          {!isLoading && (
            <div className="w-1/4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t("pickDate")}
              </label>
              <>
                <div className="mt-1">
                  <CustomDatePicker
                    selected={selectedDate}
                    onChange={(value) => {
                      setSelectedDate(new Date(value));
                      getItems(1);
                    }}
                  />
                </div>
              </>
            </div>
          )}
        </div>
        <div>
          <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
            {attendanceStatus.map((item) => (
              <li
                key={item.status}
                className="col-span-1 flex rounded-md shadow-sm"
              >
                <div
                  className={classNames(
                    item.color,
                    "flex w-16 flex-shrink-0 items-center justify-center ltr:rounded-l-md rtl:rounded-r-md text-sm font-medium text-white"
                  )}
                ></div>
                <div className="flex flex-1 items-center justify-between truncate ltr:rounded-r-md rtl:rounded-l-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <a
                      href={item.href}
                      className="font-medium text-gray-900 hover:text-gray-600"
                    >
                      {item.status}
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="divide-y divide-gray-200 bg-white">
                    {/* Grades List Table */}
                    {/* <DataTableExtensions {...tableData}> */}
                    <DataTable
                      columns={columns}
                      data={rows}
                      progressPending={isLoading}
                      Header
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationTotalRows={count}
                      onChangeRowsPerPage={getNextItems}
                      onChangePage={getItems}
                      noDataComponent={<NoData />}
                      progressComponent={<LoadingData />}
                    />
                    {/* </DataTableExtensions> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
