import React from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
function PagesHeaderNav({ page }) {
  return (
    <div className="flex items-center">
      {window.localStorage.getItem("i18nextLng") === "ar" ? (
        <ChevronLeftIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      ) : (
        <ChevronRightIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      <a
        href={page.href}
        className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
        aria-current={page.current ? "page" : undefined}
      >
        {page.name}
      </a>
    </div>
  );
}

export default PagesHeaderNav;
