import React, { Fragment } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../assets/logo.svg";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authServices from "../../services/auth.services";
import { clearError } from "../../slices/errorSlice";
import { setError } from "../../slices/errorSlice";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../components/root/AuthLayout";
import LanguageFloatingButton from "../../components/common/LanguageFloatingButton";

export default function RequestResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { error } = useSelector((state) => state.error);

  const handleSubmit = (email) => {
    authServices
      .requestResetPassword(email)
      .then((response) => {
        if (response.status === 200) {
          navigate(`/reset-password/${email}/${response.data.token}`);
        } else {
          dispatch(
            setError({ message: "Something went wrong, try again later" })
          );
        }
      })
      .catch((error) => {
        dispatch(setError({ message: error.response.data.message }));
      });
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("InvalidEmail")).required(t("Required")),
  });

  const handleCloseMessage = () => {
    dispatch(clearError());
  };

  return (
    <>
      <LanguageFloatingButton />
      <AuthLayout title={t("ResetPassword")}>
        <Formik
          initialValues={{ email: "" }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={ValidationSchema}
          onSubmit={(values) => handleSubmit(values.email)}
        >
          {({ touched, errors, isSubmitting }) => (
            <>
              <Form className="space-y-6">
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 items-center ltr:pl-3 rtl:pr-3">
                    <EnvelopeIcon
                      className="mt-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <Field
                    name="email"
                    id="email"
                    type="email"
                    autoComplete="email"
                    placeholder={t("Email")}
                    className={`ltr:pl-10 rtl:pr-10 block w-full rounded-md border border-transparent placeholder-gray-300 pl-10 shadow-sm sm:text-sm ${
                      touched.email && errors.email
                        ? "border border-red-700 focus:ring-red-800 focus:border-red-800"
                        : "border border-transparent focus:border-secondary focus:ring-secondary"
                    }`}
                  />
                  {errors.email && (
                    <p
                      className="mt-1 px-4 text-sm text-red-600"
                      id={"email-error"}
                    >
                      {errors.email}
                    </p>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                  >
                    {t("Continue")}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
        {error && (
          <div className="mt-6">
            <ErrorMessage message={error.message} close={handleCloseMessage} />
          </div>
        )}
      </AuthLayout>
    </>
  );
}
